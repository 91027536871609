import { useEffect, useState } from "react";
import { Card, Container, Row } from "react-bootstrap";
import { fetchDashboardSummary } from "../../api/adminApi";
import { NavLink } from "react-router-dom";

const DashboardHome = () => {
  const [dashboardSummary, setDashboardSummary] = useState();
  useEffect(() => {
    getDashboardSummary();
  }, []);
  async function getDashboardSummary() {
    let summary = await fetchDashboardSummary();
    setDashboardSummary(summary.data);
    console.log(summary);
  }
  return (
    <Container style={{ padding: "1rem" }} className="d-flex dash-home">
      <Card style={{ width: "12rem" }}>
        <Card.Body>
          <Card.Title>No Of Helper</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            <NavLink className={'text-muted'} to={'/admin/helper'}>
            {dashboardSummary?.taskerCount}
            </NavLink>
          </Card.Subtitle>
        </Card.Body>
      </Card>
      <Card style={{ width: "12rem", marginLeft: "1rem" }}>
        <Card.Body>
          <Card.Title>No Of Customer</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
          <NavLink className={'text-muted'} to={'/admin/customer'}>
            {dashboardSummary?.customerCount}
            </NavLink>
          </Card.Subtitle>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default DashboardHome;
