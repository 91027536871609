import { useState, useEffect } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { editCity, editPostalCode, editProvince, editSuperCity, getCityBySuperCityId, getPostalCodeByCityId, getProvinceByCountryId, getSuperCityByProvinceId } from "../../../api/locationApi";
import { START_LOADING, STOP_LOADING } from "../../../redux/Slice/loaderSlice";
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_CITY, CLEAR_POSTAL_CODES, CLEAR_PROVINCE, CLEAR_SUPER_CITY, LOAD_COUNTRY, UPDATE_CITY, UPDATE_POSTAL_CODE, UPDATE_PROVINCE, UPDATE_SUPERCITY } from "../../../redux/Slice/locationSlice";
import FormLabelStar from "../../core/FormLabelStar";

const intialForm = {
  name: "",
}

export default function UpdateLocationName({ isAdded, locationType, location }) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(intialForm);
  const dispatch = useDispatch();
  const [error, setError] = useState('');

  useEffect(() => {
    if (location) {
      setFormData({
        name: location.countryName || location.provinceName || location.superCityName || location.cityName || location.postalCode,
      })
    }
  }, [location])

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (locationType === 'Postal Code') {
      const inputText = value;
      const postalLength = location?.countryId === '2' ? 5 : 6;
      const alphanumericRegex = /^[a-zA-Z0-9]+$/;
      if (inputText.length > 0 && !alphanumericRegex.test(inputText)) {
        setError(() => 'Input must contain only alphanumeric characters.');
        return;
      } else if ((inputText.length > postalLength)) {
        setError(`Postal code must be exactly ${postalLength} letters.`);
      } else {
        setError('');
      }
      setFormData({
        ...formData,
        [name]: inputText.toUpperCase(),
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleReset = (e) => {
    setFormData(intialForm);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    dispatch(START_LOADING());
    let updatedLocation = {}
    switch (locationType) {
      case 'Province':
        updatedLocation = {
          "id": location.id,
          "newProvinceName": formData.name,
          "countryId": location.countryId
        }
        dispatch(CLEAR_PROVINCE())
        await editProvince(updatedLocation);
        let provinces = await getProvinceByCountryId(location.countryId);
        provinces = provinces.data.map((province) => { return { ...province, countryId: location.countryId, isChecked: false } })
        dispatch(UPDATE_PROVINCE(provinces));
        break;
      case 'Super City':
        updatedLocation = {
          "id": location.id,
          "newSupercityName": formData.name,
          "provinceId": location.provinceId
        }
        dispatch(CLEAR_SUPER_CITY())
        await editSuperCity(updatedLocation);
        let superCities = await getSuperCityByProvinceId(location.provinceId);
        superCities = superCities.data.map((superCity) => { return { ...superCity, isChecked: false } })
        dispatch(UPDATE_SUPERCITY(superCities));
        break;
      case 'City':
        updatedLocation = {
          "id": location.id,
          "newCityName": formData.name,
          "supercityId": location.superCityId
        }
        dispatch(CLEAR_CITY())
        await editCity(updatedLocation);
        let cities = await getCityBySuperCityId(location.superCityId);
        cities = cities.data.map((city) => { return { ...city, isChecked: false } })
        dispatch(UPDATE_CITY(cities));
        break;
      case 'Postal Code':
        updatedLocation = {
          "id": location.id,
          "newCodeName": formData.name,
          "cityId": location.cityId
        }
        dispatch(CLEAR_POSTAL_CODES());
        await editPostalCode(updatedLocation);
        let postalCodes = await getPostalCodeByCityId(location.cityId);
        const addIsActive = postalCodes.data.map(postalCode => {
          return { ...postalCode, isChecked: false }
        })
        dispatch(UPDATE_POSTAL_CODE(addIsActive));
        break;

      default:
        break;
    }
    console.log({ updatedLocation });


    isAdded(true);
    dispatch(STOP_LOADING());
  };
  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>{locationType} Name<FormLabelStar /></Form.Label>
        <Form.Control
          name="name"
          required
          value={formData.name}
          type="text"
          onChange={handleChange}
          placeholder={`Enter ${locationType} Name`}
          className={`form-control ${error ? 'is-invalid' : ''}`}
          maxLength={(locationType === 'Postal Code') ? 6 : 255}
        />
        {
          !error && <Form.Control.Feedback type="invalid">
            {`Please enter a ${locationType} name.`}
          </Form.Control.Feedback>
        }
        {error && <Form.Control.Feedback type="invalid">
          {error}
        </Form.Control.Feedback>
        }
      </Form.Group>
      <div className="d-flex justify-content-between m-2">
        <Button variant="primary" type="submit" size="sm">
          Save
        </Button>
        <Button variant="secondary" type="reset" size="sm"
          onClick={handleReset}
        >
          Reset
        </Button>
      </div>
    </Form>
  );
}
