// src/components/LoginPage.js
import { BrowserRouter, Outlet, useNavigate } from "react-router-dom";
import TopNav from "../../components/core/topNav";
import topNav from "../../components/core/topNav";
import { useEffect } from "react";

const HomePage = () => {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   navigate("/login");
  // }, [navigate]);
  return (
    <>
      <Outlet></Outlet>
    </>
  );
};

export default HomePage;
