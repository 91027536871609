import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "userData",
  initialState: {
    isAuthenticated: false,
    user: null,
  },
  reducers: {
    LOGIN_SUCCESS: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    LOGOUT: (state, action) => {
      state.isAuthenticated = false;
      state.user = null;
    },
  },
});

export const { LOGIN_SUCCESS, LOGOUT } = userSlice.actions;
export default userSlice;
