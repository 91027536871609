import { post } from "./api";

export const signIn = async (data) => {
  return await post("/auth/adminLogin", data);
};

export const forgotPassword = async (data) => {
  return await post("/admin/forgot-password", data);
};

export const resetPassword = async (data) => {
  return await post("/admin/reset-password", data);
};
