import { Button, Modal } from "react-bootstrap";

export default function ShowModal({
  body,
  show,
  handleClose,
  modalheading,
  classname,
  Submit,
  showFooter,
  extraData,
  titleClass
}) {
  return (
    <Modal show={show} dialogClassName={classname || ''} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className={titleClass || ''}>{modalheading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      {showFooter && <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="danger" onClick={Submit}>
          Yes
        </Button>
      </Modal.Footer>}
    </Modal>
  );
}
