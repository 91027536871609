import { useEffect, useState } from "react";

import {
  Button,
  Container,
  Form,
  Card,
  Row,
  Col,
  Stack,
  ListGroup,
} from "react-bootstrap";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LocationGridRow from "./LocationGridRow";
import {
  enableProvince,
  disableProvince,
  getSuperCityByProvinceId,
  getProvinceByCountryId,
  deleteProvinceById
} from "../../../api/locationApi";
import {
  LOAD_SUPERCITY,
  UPDATE_PROVINCE,
  CLEAR_PROVINCE,
  CHANGE_PROVINCE_IS_ACTIVE,
  FILTER_PROVINCE,
  LOAD_PROVINCE
} from "../../../redux/Slice/locationSlice";
import { START_LOADING, STOP_LOADING } from "../../../redux/Slice/loaderSlice";
import ShowModal from "../../core/Modal";
import { notifySuccess, notifyError, notifyInfo, notifyWarning } from '../../../api/toasterService';
import UpdateLocationName from "./UpdateLocationName";

export default function ProvinceCard(props) {
  const provinceList = useSelector((state) => state.location).filteredProvince;
  const totalProvinceList = useSelector((state) => state.location).totalProvince;
  const superCityList = useSelector((state) => state.location).filteredSuperCity;
  const dispatch = useDispatch();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [checkedItems, setCheckedItems] = useState({});
  const [searchText, setSearchText] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedLocationActive, setSelectedLocationActive] = useState(null);

  useEffect(() => {
    setSelectedLocationActive(null)
  }, [provinceList])
  async function fetchSuperCity(provinceId) {
    try {
      dispatch(START_LOADING());
      let superCities = await getSuperCityByProvinceId(provinceId);
      superCities = superCities.data.map((superCity) => { return { ...superCity, isChecked: false } })

      // let updatedSuperCityList = superCityList.filter(item => item.provinceId !== provinceId);
      // updatedSuperCityList = [...updatedSuperCityList, ...superCities]
      // console.log(province);
      dispatch(LOAD_SUPERCITY(superCities));
    } catch (error) {

    } finally {
      dispatch(STOP_LOADING());
    }
  }

  async function fetchProvince(countryId) {
    let provinces = await getProvinceByCountryId(countryId);
    provinces = provinces.data.map((province) => { return { ...province, countryId, isChecked: false } })
    dispatch(UPDATE_PROVINCE(provinces));
  }

  const populateSuperCity = (province) => {
    if (province.isActive) {
      setSelectedLocationActive(province)
      fetchSuperCity(province.id);
    }
    else
      notifyWarning('Please enable this province')
  };

  const handleCheckboxChange = (event, location) => {
    const { name, checked } = event.target;
    const updatedLocation = { ...location, isChecked: checked }

    const updatedFilteredProvince = provinceList.map(province => {
      if (province.id === updatedLocation.id) {
        return { ...province, ...updatedLocation };
      }
      return province;
    });
    const updatedTotalProvince = totalProvinceList.map(province => {
      if (province.id === updatedLocation.id) {
        return { ...province, ...updatedLocation };
      }
      return province;
    });
    dispatch(CHANGE_PROVINCE_IS_ACTIVE(({ updatedLocation, updatedFilteredProvince, updatedTotalProvince })));
  };

  const categorizedProvincesList = (provinces) => {
    return provinces.reduce((acc, province) => {
      const { countryId } = province;
      if (!acc[countryId]) {
        acc[countryId] = [];
      }
      acc[countryId].push(province);
      return acc;
    }, {});
  }

  const handleEnable = async (ev) => {
    dispatch(CLEAR_PROVINCE());
    setSearchText('')
    const categorizedProvinces = categorizedProvincesList(provinceList);
    const enableProvinceId = Object.keys(categorizedProvinces).map(async (key) => {
      const provinceQuery = categorizedProvinces[key].filter(province => province.isChecked)
        .reduce((provinceIds, province) => provinceIds + `provinceIds=${province.id}&`, '')
      dispatch(START_LOADING());
      if (provinceQuery) {
        await enableProvince(`countryId=${key}&${provinceQuery}`);
      }
      await fetchProvince(key)
      dispatch(STOP_LOADING());
      return {
        countryId: key,
        provinceQuery
      }
    })
  };

  const handleDisable = async (ev) => {
    dispatch(CLEAR_PROVINCE());
    setSearchText('')
    const categorizedProvinces = categorizedProvincesList(provinceList);
    const disableProvinceId = Object.keys(categorizedProvinces).map(async (key) => {
      const provinceQuery = categorizedProvinces[key].filter(province => province.isChecked)
        .reduce((provinceIds, province) => provinceIds + `provinceIds=${province.id}&`, '')
      dispatch(START_LOADING());
      if (provinceQuery) {
        await disableProvince(`countryId=${key}&${provinceQuery}`);
      }
      await fetchProvince(key)
      dispatch(STOP_LOADING());
      return {
        countryId: key,
        provinceQuery
      }
    })
  };

  const handleChange = (ev) => {
    const searchText = ev.target.value;
    setSearchText(searchText)
    const filteredProvinces = provinceList.filter(province =>
      province.provinceName.toLowerCase().includes(searchText.toLowerCase())
    );
    dispatch(FILTER_PROVINCE({ filteredProvinces, searchText }));
  };

  const handleDeleteProvince = (location) => {
    setSelectedLocation(location)
    setShowDeleteModal(true);
  };

  const handleUpdateProvince = (location) => {
    setSelectedLocation(location)
    setShowUpdateModal(true);
  };

  const deleteProvince = async () => {
    const deleteParams = `countryId=${selectedLocation.countryId}&provinceId=${selectedLocation.id}`
    console.log({ selectedLocation, deleteParams });
    await deleteProvinceById(deleteParams)
    dispatch(CLEAR_PROVINCE());
    const categorizedProvinces = categorizedProvincesList(provinceList);
    const disableProvinceId = Object.keys(categorizedProvinces).map(async (key) => {
      dispatch(START_LOADING());
      await fetchProvince(key)
      dispatch(STOP_LOADING());
      return {
        countryId: key
      }
    })
    setShowDeleteModal(false);
  };

  const handleAllCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const updatedLocation = {}

    const updatedFilteredProvince = provinceList.map(province => {
      return { ...province, isChecked: checked };
    });
    const updatedTotalProvince = totalProvinceList.map(province => {
      return { ...province, isChecked: checked };
    });
    dispatch(CHANGE_PROVINCE_IS_ACTIVE(({ updatedLocation, updatedFilteredProvince, updatedTotalProvince })));
  };

  return (
    <Card style={{}} className="mb-3">
      <Card.Header>{props.title}</Card.Header>
      <div
        className="d-flex justify-content-center align-items-center w-100"
        style={{ padding: "10px" }}
      >
        <div className="w-100 mb-3 d-flex justify-content-center align-items-center">
          <input
            type="text"
            value={searchText}
            onChange={(ev) => handleChange(ev)}
            placeholder={`Search ${props.title}`}
            className="w-100 form-control"
          />
        </div>
      </div>
      <Stack
        style={{ textAlign: "left", overflow: "overlay", height: "200px" }}
      >
        {
          (provinceList.length > 0) &&
          <Container style={{ marginBottom: "10px" }}>
            <Row>
              <Form.Group controlId="formBasicCheckbox" >
                <div className="d-flex align-items-center" style={{ marginRight: "5px" }}>
                  <Form.Check
                    type="checkbox"
                    name={'test'}
                    onChange={(ev) => handleAllCheckboxChange(ev)}
                    checked={(provinceList.filter(province => !province.isChecked).length === 0) ? true : false}
                  />
                  <span style={{ marginLeft: "10px" }}>Select All</span>
                </div>
              </Form.Group>
            </Row>
          </Container>
        }

        {provinceList?.map((location, index) => {
          // console.log(index, "index");
          return (
            <LocationGridRow
              setBorder={selectedLocationActive && selectedLocationActive.id == location.id ? true : false}
              location={location}
              populateLocation={(l) => populateSuperCity(l)}
              handleCheckboxChange={handleCheckboxChange}
              key={index}
              checked={checkedItems[location.id]}
              deleteLocation={handleDeleteProvince}
              updateLocation={handleUpdateProvince}
            ></LocationGridRow>
          );
        })}
      </Stack>
      <div className="d-flex justify-content-between m-2">
        <Button variant="primary" onClick={handleEnable}
          size="sm"
          disabled={!(provinceList.filter(province => province.isChecked).length > 0)}
        >
          Enable
        </Button>{" "}
        <Button variant="danger" onClick={handleDisable}
          size="sm"
          disabled={!(provinceList.filter(province => province.isChecked).length > 0)}
        >
          Disable
        </Button>
      </div>
      <ShowModal
        modalheading="Delete Province"
        show={showDeleteModal}
        classname={"add-service-modal"}
        body="Are you sure you want to delete this province"
        handleClose={() => setShowDeleteModal(false)}
        showFooter={true}
        Submit={deleteProvince}
      ></ShowModal>
      <ShowModal
        modalheading="Update Province"
        show={showUpdateModal}
        classname={"add-service-modal"}
        body={
          <UpdateLocationName
            locationType={'Province'}
            location={selectedLocation}
            isAdded={() => setShowUpdateModal(false)}
          ></UpdateLocationName>
        }
        handleClose={() => setShowUpdateModal(false)}
      ></ShowModal>
    </Card>
  );
}
