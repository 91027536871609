import { async } from "q";
import { get, post, remove, put } from "./api";
import { setHeader } from "./adminApi";


export const getCountryList = async () => {
  const headers = await setHeader();
  return await get("admin/getCountries", headers);
};

export const getProvinceByCountryId = async (countryId) => {
  const headers = await setHeader();
  return await get(`admin/getProvinces?countryId=${countryId}`, headers);
}

export const getSuperCityByProvinceId = async (provinceId) => {
  const headers = await setHeader();
  return await get(`admin/getSuperCities?provinceId=${provinceId}`, headers);
}

export const getCityBySuperCityId = async (superCityId) => {
  const headers = await setHeader();
  return await get(`admin/getCities?superCityId=${superCityId}`, headers);
}

export const getPostalCodeByCityId = async (cityId) => {
  const headers = await setHeader();
  return await get(`admin/getPostalCodes?cityId=${cityId}`, headers);
}

export const addNewCountry = async (data) => {
  const headers = await setHeader();
  return await post(`admin/addNewCountry`, data, headers);
}

export const addNewProvince = async (data) => {
  const headers = await setHeader();
  return await post(`admin/addNewProvince`, data, headers);
}

export const addNewSupercity = async (data) => {
  const headers = await setHeader();
  return await post(`admin/addNewSupercity`, data, headers);
}

export const addNewCities = async (data) => {
  const headers = await setHeader();
  return await post(`admin/addNewCity`, data, headers);
}

export const addNewPostalCodes = async (data) => {
  const headers = await setHeader();
  return await post(`admin/addNewPostalCode`, data, headers);
}

export const enableCountry = async (data) => {
  const headers = await setHeader();
  return await put(`admin/enableCountry?${data}`, {}, headers);
}

export const disableCountry = async (data) => {
  const headers = await setHeader();
  return await put(`admin/disableCountry?${data}`, {}, headers);
}

export const enableProvince = async (data) => {
  const headers = await setHeader();
  return await put(`admin/enableProvince?${data}`, {}, headers);
}

export const disableProvince = async (data) => {
  const headers = await setHeader();
  return await put(`admin/disableProvince?${data}`, {}, headers);
}

export const enableSuperCity = async (data) => {
  const headers = await setHeader();
  return await put(`admin/enableSupercity?${data}`, {}, headers);
}

export const disableSuperCity = async (data) => {
  const headers = await setHeader();
  return await put(`admin/disableSupercity?${data}`, {}, headers);
}
export const enableCity = async (data) => {
  const headers = await setHeader();
  return await put(`admin/enableCity?${data}`, {}, headers);
}

export const disableCity = async (data) => {
  const headers = await setHeader();
  return await put(`admin/disableCity?${data}`, {}, headers);
}

export const enablePostalCode = async (data) => {
  const headers = await setHeader();
  return await put(`admin/enablePostalCode`, data, headers);
}

export const disablePostalCode = async (data) => {
  const headers = await setHeader();
  return await put(`admin/disablePostalCode`, data, headers);
}

export const UploadPostalDataUrl = async (data) => {
  const headers = await setHeader();
  return await post(`admin/uploadPostalData`, data, {
    "Content-Type": "multipart/form-data",
    "Authorization": headers.Authorization
  });
}

export const deleteProvinceById = async (data) => {
  const headers = await setHeader();
  return await remove(`admin/deleteProvince?${data}`, headers);
}

export const deleteSupercityById = async (data) => {
  const headers = await setHeader();
  return await remove(`admin/deleteSupercity?${data}`, headers);
}

export const deleteCitiesById = async (data) => {
  const headers = await setHeader();
  return await remove(`admin/deleteCity?${data}`, headers);
}

export const deletePostalCodesById = async (data) => {
  const headers = await setHeader();
  return await remove(`admin/deletePostalCode?${data}`, headers);
}

export const editProvince = async (data) => {
  const headers = await setHeader();
  return await put(`admin/editProvince`, data, headers);
}

export const editSuperCity = async (data) => {
  const headers = await setHeader();
  return await put(`admin/editSupercity`, data, headers);
}

export const editCity = async (data) => {
  const headers = await setHeader();
  return await put(`admin/editCity`, data, headers);
}

export const editPostalCode = async (data) => {
  const headers = await setHeader();
  return await put(`admin/editPostalCode`, data, headers);
}
