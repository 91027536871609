import { Accordion, Button, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { START_LOADING, STOP_LOADING } from "../../redux/Slice/loaderSlice";
import { notifySuccess, notifyError, notifyInfo, notifyWarning } from '../../api/toasterService';
import { Avatar, Box, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { formatDate } from '../../utils/dateUtils';
import { formatPriceInUSD } from '../../utils/priceUtils';
import { useLocation, useNavigate } from "react-router-dom";
import * as React from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { saveAs } from 'file-saver';
import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import MyBreadCrumb from "../../components/core/MyBreadCrumb";
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { UPDATE_ROUTELIST } from "../../redux/Slice/adminSlice";

const InvertedIconButton = styled(IconButton)({
  color: 'white',
  backgroundColor: '#0b5ed7',
  width: '30px',
  height: '30px',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#0b5ed7',
  },
});

export default function PaymentDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const paymentDetails = location.state;
  console.log({ paymentDetails });
  if (!paymentDetails) {
    notifyError('Something went wrong')
    window.location.href = '/admin/payments';
  }
  const handlePDF = (url, type) => {
    window.open(url, '_blank');
    // if (type == 'view')
    // else {
    //   fetch(url, {
    //     headers: {
    //       'Access-Control-Allow-Origin': "*"
    //     }
    //   })
    //     .then((response) => response.blob())
    //     .then((blob) => {
    //       saveAs(blob, 'downloaded.pdf');
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       notifyError('Something went wrong')
    //     });

    // }
  };

  const routeList = [
    {
      href: '/admin/dashboard',
      title: (
        <>
          <span>Dashboard</span>
        </>
      ),
    },
    {
      href: '/admin/payments',
      title: (
        <>
          <span>Payments</span>
        </>
      ),
    },
    {
      title: 'Payment Details',
    },
  ]

  dispatch(UPDATE_ROUTELIST(routeList))


  return (
    <>
      {paymentDetails && (
        <div className="bg-white p-4 service-question" style={{ width: "50%", height: "500px" }}>
          <div className="mb-4">
            {/* <MyBreadCrumb routeList={routeList} title={'Payment Details'} /> */}
            <h3 className="text-center"> Payment Details</h3>
          </div>
          <hr></hr>
          <Box>
            <div class="row" >
              <div class="col">
                <Grid item xs={12} >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6">{`${paymentDetails.firstName} ${paymentDetails.lastName}`}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "end" }} >
                      {paymentDetails?.receiptPdf &&
                        <>
                          {/* <PictureAsPdfIcon sx={{ marginRight: "15px", cursor: "pointer" }}
                            onClick={() => handlePDF(paymentDetails?.receiptPdf, 'view')
                            }
                          /> */}
                          {/* <Tooltip title="Download File" arrow>
                            <InvertedIconButton color="primary"
                              size="medium"
                              onClick={() => handlePDF(paymentDetails?.receiptPdf, 'download')
                              }>
                              <DownloadOutlinedIcon />
                            </InvertedIconButton>
                          </Tooltip> */}
                          {/* <DownloadOutlinedIcon
                            style={{ cursor: "pointer", fontSize: "30px", border: "1px solid red" }}
                            onClick={() => handlePDF(paymentDetails?.receiptPdf, 'download')
                            }
                          /> */}
                        </>
                      }
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography gutterBottom>{`${paymentDetails.email}`}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "end" }}>
                      {/* <Typography gutterBottom>{`Type: ${paymentDetails.paymentType}`}</Typography> */}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography gutterBottom>{`${formatDate(paymentDetails.paymentInitiatedTime)}`}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "end" }}>
                      {/* <Typography gutterBottom>{`Status: ${paymentDetails.paymentStatus}`}</Typography> */}
                    </Grid>
                  </Grid>
                </Grid>
                <Typography
                  style={{ marginTop: "10px" }}
                >
                  Summary
                </Typography>
                <Box sx={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  backgroundColor: "aliceblue",
                  borderRadius: "5px",
                  marginTop: "10px"
                }}>
                  {(paymentDetails.paymentType == 'ONBOARDING') ?
                    (<List disablePadding>
                      <ListItem sx={{ py: 0, px: 0 }}>
                        <ListItemText primary={'On Boarding fees'} />
                        <Typography variant="body2">{formatPriceInUSD(paymentDetails.onboardingFee)}</Typography>
                      </ListItem>
                      <ListItem sx={{ py: 0, px: 0 }}>
                        <ListItemText primary={`Discount ${paymentDetails.coupon ? '(' + paymentDetails.coupon + ')' : ''}`} />
                        <Typography variant="body2">{`-${formatPriceInUSD(paymentDetails.discount)}`}</Typography>
                      </ListItem>
                      <ListItem sx={{ py: 0, px: 0 }}>
                        <ListItemText primary={`Taxes`} />
                        <Typography variant="body2">{formatPriceInUSD(paymentDetails.taxes)}</Typography>
                      </ListItem>
                      <ListItem sx={{ py: 0, px: 0 }}>
                        <ListItemText primary={
                          <Typography variant="body1" fontWeight="bold">
                            Amount charged
                          </Typography>
                        } />
                        <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                          {formatPriceInUSD(paymentDetails.chargeAmount)}
                        </Typography>
                      </ListItem>
                    </List>)
                    :
                    (<List disablePadding>
                      <ListItem sx={{ py: 0, px: 0 }}>
                        <ListItemText primary={'Service fees'} />
                        <Typography variant="body2">{formatPriceInUSD(paymentDetails.serviceAmount)}</Typography>
                      </ListItem>
                      <ListItem sx={{ py: 0, px: 0 }}>
                        <ListItemText primary={`Discount ${paymentDetails.coupon ? '(' + paymentDetails.coupon + ')' : ''}`} />
                        <Typography variant="body2">{`-${formatPriceInUSD(paymentDetails.discount)}`}</Typography>
                      </ListItem>
                      <ListItem sx={{ py: 0, px: 0 }}>
                        <ListItemText primary={`Trust & Support fee`} />
                        <Typography variant="body2">{formatPriceInUSD(paymentDetails.trustAndSupportFee)}</Typography>
                      </ListItem>
                      <ListItem sx={{ py: 0, px: 0 }}>
                        <ListItemText primary={`Platform fee`} />
                        <Typography variant="body2">{formatPriceInUSD(paymentDetails.platformFee)}</Typography>
                      </ListItem>
                      <ListItem sx={{ py: 0, px: 0 }}>
                        <ListItemText primary={`Taxes`} />
                        <Typography variant="body2">{formatPriceInUSD(paymentDetails.taxes)}</Typography>
                      </ListItem>

                      <ListItem sx={{ py: 0, px: 0 }}>
                        <ListItemText primary={
                          <Typography variant="body1" fontWeight="bold">
                            {paymentDetails.paymentStatus == 'HOLD' ?
                              'Hold Amount' : 'Amount charged'
                            }
                          </Typography>
                        } />
                        <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                          {formatPriceInUSD(paymentDetails.chargeAmount || paymentDetails.holdAmount)}
                        </Typography>
                      </ListItem>
                    </List>)
                  }
                </Box>
                {
                  <div class="row" style={{ textAlign: "center" }}>
                    <div class="col" style={{ height: "70px" }}>
                      <Typography style={{ marginTop: "10px" }}>Payment Status</Typography>
                      <h6
                        style={{ color: "#6d7886", fontSize: "12px", paddingLeft: "5px" }} >
                        <Box
                          component="span"
                          sx={(theme) => ({
                            backgroundColor:
                              paymentDetails.paymentStatus == 'SUCCESS'
                                ? '#4CAF50'
                                : paymentDetails.paymentStatus == 'PENDING'
                                  ? '#FFC107' :
                                  paymentDetails.paymentStatus == 'INITIATED'
                                    ? '#2196F3'
                                    : paymentDetails.paymentStatus == 'CANCELLED'
                                      ? '#FF5722'
                                      : paymentDetails.paymentStatus == 'REFUNDED'
                                        ? '#8BC34A'
                                        : paymentDetails.paymentStatus == 'HOLD'
                                          ? '#9E9E9E'
                                          : paymentDetails.paymentStatus == 'CHARGED'
                                            ? '#03A9F4'
                                            : paymentDetails.paymentStatus == 'INVOICED'
                                              ? '#9C27B0'
                                              : '#388e3c',
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '9ch',
                            p: '0.25rem',
                          })}
                        >
                          {paymentDetails.paymentStatus}
                        </Box>
                      </h6>
                    </div>
                    <div class="col" style={{ height: "70px" }}>
                      <Typography style={{ marginTop: "10px" }}>Payment Type</Typography>
                      <h6
                        style={{ color: "#6d7886", fontSize: "12px", paddingLeft: "5px" }} >
                        {`${paymentDetails?.paymentType}`}
                      </h6>
                    </div>

                    {
                      paymentDetails?.cardBrand && paymentDetails?.cardNumber &&
                      <div class="col" style={{ height: "70px", }}>
                        <Typography style={{ marginTop: "10px" }}>Payment Method</Typography>
                        <h6
                          style={{ color: "#6d7886", fontSize: "12px", paddingLeft: "5px" }} >
                          {paymentDetails?.cardBrand ? `${paymentDetails?.cardBrand.toUpperCase()} - ${paymentDetails?.cardNumber}` : 'NA'}
                        </h6>
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          </Box>
        </div >
      )
      }
    </>
  );
}
