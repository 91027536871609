import { useState, useEffect } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { addNewCountry, getCountryList } from "../../../api/locationApi";
import { START_LOADING, STOP_LOADING } from "../../../redux/Slice/loaderSlice";
import { useDispatch, useSelector } from "react-redux";
import { LOAD_COUNTRY } from "../../../redux/Slice/locationSlice";
import FormLabelStar from "../../core/FormLabelStar";
import { validationLength } from "../../../metaData/validationLength"

const intialForm = {
  name: "",
  id: "",
}

export default function AddNewCountry({ isAdded }) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(intialForm);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleReset = (e) => {
    setFormData(intialForm);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    dispatch(START_LOADING());
    try {
      await addNewCountry(formData);
      isAdded(true);
      let country = await getCountryList();
      if (country?.data) {
        const isCheckedAdded = country.data.map(country => {
          return { ...country, isChecked: false }
        })
        dispatch(LOAD_COUNTRY(isCheckedAdded));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(STOP_LOADING());
    }
  };
  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Country Name<FormLabelStar /></Form.Label>
        <Form.Control
          name="name"
          required
          value={formData.name}
          type="text"
          onChange={handleChange}
          placeholder="Enter Country Name"
          maxLength={validationLength.location.name.maxLength}
        />
        {formData.name.length > validationLength.location.name.maxLength - 1 && (
          <div className="province-name-validate">{validationLength.message(validationLength.location.name.maxLength)}</div>
        )}
        <Form.Control.Feedback type="invalid">
          Please enter a Country name.
        </Form.Control.Feedback>
        {/* <Form.Text className="text-muted">Enter the Category Name</Form.Text> */}
      </Form.Group>
      <div className="d-flex justify-content-between m-2">
        <Button variant="primary" type="submit" size="sm">
          Submit
        </Button>
        <Button variant="secondary" type="reset" size="sm"
          onClick={handleReset}
        >
          Reset
        </Button>
      </div>
    </Form>
  );
}
