import axios from "axios";
import { useDispatch } from "react-redux";
import { notifySuccess, notifyError, notifyInfo, notifyWarning } from "./toasterService";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v2`, // Replace with your API URL
  // timeout: 100000, // Set the timeout for requests
  headers: {
    "Content-Type": "application/json",
    // You can add other headers like authorization tokens here
  },
});

const axiosInstanceV1 = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1`,
  headers: {
    "Content-Type": "application/json",
  },
});

const commonToaster = async (response) => {
  try {
    let msg = response?.response?.data?.message;
    switch (response.response.status) {
      case 401:
        notifyError((msg ? msg : 'Unauthorized request'))
        window.location.href = '/';
        break;
      case 400:
        if (response?.response?.status == 400 && (response?.response?.data?.details?.length > 0)) {
          msg = response?.response?.data?.details[0]
        }
        notifyError(msg ? msg : 'Bad request')
        break;
      case 502:
        notifyError('Server Error')
        break;
      case 500:
        notifyError('Internal Server Error')
        break;
      default:
        notifyError('Something went wrong')
        break;
    }
  } catch (error) {
    notifyError('Something went wrong')
    console.log({ error });
  }
}


// GET request
export const getV1 = async (url, headers = {}) => {
  try {
    const response = await axiosInstanceV1.get(url, { headers: headers });
    return response.data;
  } catch (error) {
    await commonToaster(error)
    console.log(error);
    throw error;
  }
};

export const downloadXLS = async (url, headers = {}) => {
  try {
    const axiosInstanceDownload = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/v1`,
      headers: {
        "Content-Type": "application/octet-stream",
      },
      responseType: 'blob',
    });
    const response = await axiosInstanceDownload.get(url, { headers: headers });
    return response.data;
  } catch (error) {
    await commonToaster(error)
    throw error;
  }
};

// POST request
export const postV1 = async (url, data, headers = {}) => {
  try {
    const response = await axiosInstanceV1.post(url, data, { headers: headers });
    if (response?.status === 200 || response?.status === 201) {
      notifySuccess(response?.data?.message || response?.message)
    }
    return response.data;
  } catch (error) {
    await commonToaster(error)
    console.log(error.response.data);
    if (error?.response?.data?.statusCode !== 200) return error.response.data;
  }
};

// PUT request
export const putV1 = async (url, data, headers = {}) => {
  try {
    const response = await axiosInstanceV1.put(url, data, { headers: headers });
    if (response?.status === 200) {
      notifySuccess(response?.data?.message || response?.message)
    }
    return response.data;
  } catch (error) {
    await commonToaster(error)
    console.log(error);
    throw error;
  }
};

export const patchV1 = async (url, data, headers = {}) => {
  try {
    const response = await axiosInstanceV1.patch(url, data, { headers: headers });
    if (response?.status === 200) {
      notifySuccess(response?.data?.message || response?.message)
    }
    return response.data;
  } catch (error) {
    await commonToaster(error)
    throw error;
  }
};

// DELETE request
export const removeV1 = async (url, headers = {}) => {
  try {
    const response = await axiosInstanceV1.delete(url, { headers: headers });
    if (response?.status === 200) {
      notifySuccess(response?.data?.message || response?.message)
    }
    return response.data;
  } catch (error) {
    await commonToaster(error)
    console.log(error);
    throw error;
  }
};

// GET request
export const get = async (url, headers = {}) => {
  try {
    const response = await axiosInstance.get(url, { headers: headers });
    return response.data;
  } catch (error) {
    await commonToaster(error)
    console.log(error);
    throw error;
  }
};

// POST request
export const post = async (url, data, headers = {}) => {
  try {
    const response = await axiosInstance.post(url, data, { headers: headers });
    if (response?.status === 200 || response.status === 201) {
      notifySuccess(response?.data?.message)
    }
    return response.data;
  } catch (error) {
    await commonToaster(error)
    console.log(error.response.data);
    if (error?.response?.data?.statusCode !== 200) return error.response.data;
  }
};

// PUT request
export const put = async (url, data, headers = {}) => {
  try {
    const response = await axiosInstance.put(url, data, { headers: headers });
    if (response?.status === 200) {
      notifySuccess(response?.data?.message)
    }
    return response.data;
  } catch (error) {
    await commonToaster(error)
    console.log(error);
    throw error;
  }
};

// POST request
export const patch = async (url, data, headers = {}) => {
  try {
    const response = await axiosInstance.patch(url, data, { headers: headers });
    if (response?.status === 200 || response.status === 201) {
      notifySuccess(response?.data?.message)
    }
    return response.data;
  } catch (error) {
    await commonToaster(error)
    if (error?.response?.data?.statusCode !== 200) return error.response.data;
  }
};

// DELETE request
export const remove = async (url, headers = {}) => {
  try {
    const response = await axiosInstance.delete(url, { headers: headers });
    if (response?.status === 200) {
      notifySuccess(response?.data?.message)
    }
    return response.data;
  } catch (error) {
    await commonToaster(error)
    console.log(error);
    // throw error;
  }
};
