import { useState, useEffect } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { addNewHelperTool, editHelperTool } from "../../../api/adminApi";
import FormLabelStar from "../../core/FormLabelStar";
import { validationLength } from "../../../metaData/validationLength"

export default function RejectSkillTrade({ handleReject }) {
  const [validated, setValidated] = useState(false);
  const [reason, setReason] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReason(value)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    await handleReject('REJECTED', reason)
  };
  const handleReset = (e) => {
    setReason();
    setValidated(false);
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Rejection Reason<FormLabelStar /></Form.Label>
        <Form.Control
          name="reason"
          required
          value={reason}
          // type="text"
          as="textarea"
          rows={3}
          onChange={handleChange}
          placeholder="Reason"
          maxLength={validationLength.skillTrade.reason.maxLength}
        />
        {reason?.length > validationLength.skillTrade.reason.maxLength - 1 && (
          <div className="province-name-validate">{validationLength.message(validationLength.skillTrade.reason.maxLength)}</div>
        )}
        <Form.Control.Feedback type="invalid">
          Please enter reason.
        </Form.Control.Feedback>
      </Form.Group>
      <div className="d-flex justify-content-between m-2">
        <Button variant="primary" type="submit" size="sm">
          Submit
        </Button>
        <Button variant="secondary" type="reset" size="sm" onClick={() => handleReset()}>
          Reset
        </Button>
      </div>
    </Form>
  );
}
