import { Accordion, Button, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getServiceById, DeleteQuestion, getServiceDetailsById, getServicePricesById, getSuppliesByServiceId, deleteSupplyById } from "../../api/adminApi";
import { useParams } from "react-router-dom";
import ShowModal from "../../components/core/Modal";
import AddNewQuestion from "../../components/Admin/serviceManagement/AddNewQuestion";
import UpdateServicePrice from "../../components/Admin/serviceManagement/UpdateServicePrice";
import ReusableTable from "../../components/core/ReusableTable";
import { SupplyColumns } from "../../metaData/ColumnList";
import { useDispatch } from "react-redux";
import { START_LOADING, STOP_LOADING } from "../../redux/Slice/loaderSlice";
import AddNewSupply from "../../components/Admin/serviceManagement/AddNewSupply";
import { formatPriceInUSD } from '../../utils/priceUtils';
import Tooltip from '@mui/material/Tooltip';
import MyBreadCrumb from "../../components/core/MyBreadCrumb";
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { UPDATE_ROUTELIST } from "../../redux/Slice/adminSlice";


export default function ServiceDetials() {
  const { id } = useParams();
  const [service, setService] = useState();
  const [serviceDetails, setServiceDetails] = useState();
  const [questionModal, setquestionModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedQues, setSelectedQues] = useState();
  const [showEditModal, setEditModal] = useState(false);
  const [showDetailsEditModal, setDetailsEditModal] = useState(false);
  const [showUpdatePriceModal, setUpdatePriceModal] = useState(false);
  const [servicePrice, setServicePrice] = useState({});
  const [displayedSupplyList, setDisplayedSupplyList] = useState([]);
  const [actions, setActions] = useState([]);
  const dispatch = useDispatch();

  const [showSupplyEditModal, setSupplyEditModal] = useState(false);
  const [showSupplyAddModal, setSupplyAddModal] = useState(false);
  const [selectedSupply, setSelectedSupply] = useState();
  const [showSupplyDeleteModal, setSupplyDeleteModal] = useState(false);

  useEffect(() => {
    if (id) {
      getQuestions(id);
      // getPrices(id)
      // getAllServiceDetails(id)
    }
  }, [id]);

  function AddActions() {
    setActions([
      {
        label: "edit",
        clickAction: openEditModal,
      },
      {
        label: "delete",
        variant: "danger",
        clickAction: openSupplyDeleteModal,
      },
    ]);
  }

  const openSupplyDeleteModal = async (item) => {
    setSupplyDeleteModal(true);
    setSelectedSupply(item);
  };

  const openEditModal = async (item) => {
    setSupplyEditModal(true);
    setSelectedSupply(item);
  };

  async function fetchSupplies(id) {
    dispatch(START_LOADING());
    let supplies = await getSuppliesByServiceId(id);
    dispatch(STOP_LOADING());
    setDisplayedSupplyList(supplies.data);
    AddActions();
  }

  //call API to get question based on service Id

  const getQuestions = async (id) => {
    dispatch(START_LOADING());
    const serviceDetail = await getServiceById(id);

    serviceDetail?.data?.questions?.sort((a, b) => a.id - b.id);
    setService(serviceDetail.data);
    dispatch(STOP_LOADING());

  };

  // const getAllServiceDetails = async (id) => {
  //   const allServiceDetail = await getServiceDetailsById(id);
  //   setServiceDetails(allServiceDetail.data);
  // };

  const getPrices = async (id) => {
    const prices = await getServicePricesById(id);
    if (prices)
      setServicePrice(prices.data)
  };
  // const editQuestion = async (event, question) => {
  //   console.log(question);
  //   event.stopPropagation();
  // };

  const openNewQuestionModel = () => {
    setquestionModal(true);
    setSelectedQues()
  };

  const editQuestion = async (e, item) => {
    e.stopPropagation();
    setEditModal(true);
    setSelectedQues(item);
  };

  //Open Modal to add new Service
  const newQuestionAdded = async () => {
    setquestionModal(false);
    setEditModal(false);
    getQuestions(id);
  };

  const deleteQuestion = async () => {
    await DeleteQuestion(selectedQues.id);
    setShowDeleteModal(false);
    getQuestions(id);
  };

  const openDeleteModal = async (e, item) => {
    e.stopPropagation();
    setShowDeleteModal(true);
    setSelectedQues(item);
  };

  const handleSelectedTab = async (tabName) => {
    switch (tabName) {
      case "prices":
        const prices = await getServicePricesById(id);
        if (prices)
          setServicePrice(prices.data)
        break;
      case "questions":
        break;
      case "supply":
        await fetchSupplies(id)
        break;
      case "details":
        // await getAllServiceDetails(id)
        break;

      default:
        break;
    }
  };

  const updatePrice = async () => {
    setUpdatePriceModal(false)
    getPrices(id)
  }

  const handleSupplyModal = async () => {
    setSupplyAddModal(false)
    setSupplyEditModal(false)
    setSelectedSupply(null)
    await fetchSupplies(id)
  }

  const deleteSupply = async () => {
    await deleteSupplyById(selectedSupply.id);
    setSupplyDeleteModal(false);
    await fetchSupplies(id)
    setSelectedSupply(null);
  };

  function truncateString(str, maxLength) {
    if (str && str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  }

  const routeList = [
    {
      href: '/admin/dashboard',
      title: (
        <>
          <span>Dashboard</span>
        </>
      ),
    },

    {
      href: '/admin/services',
      title: (
        <>
          <span>Services</span>
        </>
      ),
    },
    {
      title: 'Service Details',
    },
  ]
  dispatch(UPDATE_ROUTELIST(routeList))


  return (
    <>
      {service && (
        <div className="bg-white p-4 service-question">
          <div className="mb-4">
            {/* <MyBreadCrumb routeList={routeList} title={'Service Details'} /> */}
            <h3 className="text-center"> Service Detail</h3>
          </div>
          <hr></hr>
          <Tabs
            defaultActiveKey="details"
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={handleSelectedTab}
          >
            <Tab eventKey="details" title="Service Details">
              <div class="container">
                <div class="row">
                  <div class="col-3">
                    <div className="column-name">
                      <label>Service Name : </label>
                    </div>
                  </div>
                  <div class="col-9">
                    <div className="column-name">
                      <span>{service.name}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3">
                    <div className="column-name">
                      <label>Description : </label>
                    </div>
                  </div>
                  <div class="col-9">
                    <div className="column-name">
                      <span>
                        <Tooltip title={service.description} arrow>
                          {truncateString(service.description, 100)}
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3">
                    <div className="column-name">
                      <label>Sub Services : </label>
                    </div>
                  </div>
                  <div class="col-9">
                    <div className="column-name" style={{ marginLeft: "-10px" }}>
                      <ul>
                        {service.subService.map((subService) => {
                          return (<li>{subService.name}</li>)
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3">
                    <div className="column-name">
                      <label>Recommended Tools : </label>
                    </div>
                  </div>
                  <div class="col-9">
                    <div className="column-name" style={{ marginLeft: "-10px" }}>
                      <ul>
                        {service.recommendedTools.map((tool) => {
                          return (<li>{tool.name}</li>)
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3">
                    <div className="column-name">
                      <label>Safety And Caution : </label>
                    </div>
                  </div>
                  <div class="col-9">
                    <div className="column-name" style={{ marginLeft: "-10px" }}>
                      <ul>
                        {service.safetyAndCaution.map((saftey) => {
                          return (<li>{saftey.name}</li>)
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3">
                    <div className="column-name">
                      <label>Scope Of Work : </label>
                    </div>
                  </div>
                  <div class="col-9">
                    <div className="column-name" style={{ marginLeft: "-10px" }}>
                      <ul>
                        {service.scopeOfWork.map((work) => {
                          return (<li>{work.name}</li>)
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3">
                    <div className="column-name">
                      <label>Wow Your Customer : </label>
                    </div>
                  </div>
                  <div class="col-9">
                    <div className="column-name" style={{ marginLeft: "-10px" }}>
                      <ul>
                        {service.wowYourCustomer.map((wow) => {
                          return (<li>{wow.name}</li>)
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="column-name">
                <Row>
                  <Col>
                    <label>Service Name : </label>
                    <span>{service.name}</span>
                  </Col>
                  <Col className="d-flex flex-row-reverse">
                  </Col>
                </Row>
              </div>
              <div className="column-name">
                <label>Description: </label>
                <span>{truncateString(service.description,100)}</span>
              </div> */}
              {/* <div className="column-name service-column">
                <label>Sub Services: </label>
                <ul>
                  {service.subService.map((subService) => {
                    return (<li>{subService.name}</li>)
                  })}
                </ul>
              </div>
              <div className="column-name service-column" >
                <label>Recommended Tools: </label>
                <ul>
                  {service.recommendedTools.map((tool) => {
                    return (<li>{tool.name}</li>)
                  })}
                </ul>
              </div>
              <div className="column-name service-column">
                <label>Safety And Caution: </label>
                <ul>
                  {service.safetyAndCaution.map((saftey) => {
                    return (<li>{saftey.name}</li>)
                  })}
                </ul>
              </div>
              <div className="column-name service-column">
                <label>Scope Of Work: </label>
                <ul>
                  {service.scopeOfWork.map((work) => {
                    return (<li>{work.name}</li>)
                  })}
                </ul>
              </div>
              <div className="column-name service-column">
                <label>Wow Your Customer: </label>
                <ul>
                  {service.wowYourCustomer.map((wow) => {
                    return (<li>{wow.name}</li>)
                  })}
                </ul>
              </div> */}
            </Tab>
            <Tab eventKey="prices" title="Prices">
              <div class="container">
                <div class="row">
                  <div class="col-3">
                    <div className="column-name">
                      <label>Service Name : </label>
                    </div>
                  </div>
                  <div class="col-9">
                    <div className="column-name">
                      <Row>
                        <Col>
                          <span>{service.name}</span>
                        </Col>
                        <Col className="d-flex flex-row-reverse">
                          <Button size="sm" onClick={() => { setUpdatePriceModal(true) }}>Update Prices</Button>
                        </Col>
                      </Row>

                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3">
                    <div className="column-name">
                      <label>Description : </label>
                    </div>
                  </div>
                  <div class="col-9">
                    <div className="column-name">
                      <span>
                        <Tooltip title={service.description} arrow>
                          {truncateString(service.description, 100)}
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3">
                    <div className="column-name">
                      <label>Minimum Price : </label>
                    </div>
                  </div>
                  <div class="col-9">
                    <div className="column-name">
                      <span>{formatPriceInUSD(servicePrice?.minimumPrice || 0)}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3">
                    <div className="column-name">
                      <label>Maximum Price : </label>
                    </div>
                  </div>
                  <div class="col-9">
                    <div className="column-name">
                      <span>{(formatPriceInUSD(servicePrice?.maximumPrice || 0))}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3">
                    <div className="column-name">
                      <label>Low Price Limit : </label>
                    </div>
                  </div>
                  <div class="col-9">
                    <div className="column-name">
                      <span>{formatPriceInUSD(servicePrice?.lowPriceLimit || 0)}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3">
                    <div className="column-name">
                      <label>Suggested Price Limit : </label>
                    </div>
                  </div>
                  <div class="col-9">
                    <div className="column-name">
                      <span>{formatPriceInUSD(servicePrice?.suggestedPriceLimit || 0)}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3">
                    <div className="column-name">
                      <label>Suggested Price : </label>
                    </div>
                  </div>
                  <div class="col-9">
                    <div className="column-name">
                      <span>{formatPriceInUSD(
                        Math.round(((servicePrice?.suggestedPriceLimit || 0) + (servicePrice?.lowPriceLimit || 0) + 2) / 2)
                      )}</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="column-name">
                <label>Minimum Price: </label>
                <span>{servicePrice?.minimumPrice || 0}</span>
              </div>
              <div className="column-name">
                <label>Maximum Price: </label>
                <span>{servicePrice?.maximumPrice || 0}</span>
              </div>
              <div className="column-name">
                <label>Low Price Limit: </label>
                <span>{servicePrice?.lowPriceLimit || 0}</span>
              </div>
              <div className="column-name">
                <label>Suggested Price Limit: </label>
                <span>{servicePrice?.suggestedPriceLimit || 0}</span>
              </div> */}
            </Tab>
            <Tab eventKey="questions" title="Questions">
              <div class="container">
                <div class="row">
                  <div class="col-2">
                    <div className="column-name">
                      <label>Service Name : </label>
                    </div>
                  </div>
                  <div class="col-10">
                    <div className="column-name">
                      <span>{service.name}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2">
                    <div className="column-name">
                      <label>Description : </label>
                    </div>
                  </div>
                  <div class="col-10">
                    <div className="column-name">
                      <span>
                        <Tooltip title={service.description} arrow>
                          {truncateString(service.description, 100)}
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column-name service-column">
                <div className="d-flex">
                  {" "}
                  <label>Questions: </label>{" "}
                  <Button
                    variant="primary"
                    style={{ marginBottom: "10px" }}
                    onClick={openNewQuestionModel}
                  >
                    Add New Question
                  </Button>
                </div>
                <Accordion>
                  {service.questions.map((q, i) => (
                    <Accordion.Item eventKey={i} key={i}>
                      <Accordion.Header>
                        <div className="d-flex w-100 justify-content-between">
                          {" "}
                          <label>{q.question} </label>{" "}
                          <div className="options">
                            <a
                              className="edit-btn mr-2"
                              onClick={(e) => editQuestion(e, q)}
                            >
                              Edit
                            </a>
                            <a className="delete-btn"
                              onClick={(e) => openDeleteModal(e, q)}
                            >Delete</a>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="column-name">
                          <label>Key : </label>
                          <span>{q.key}</span>
                        </div>
                        <div className="column-name">
                          <label>Question Type: </label>
                          <span>{q.type}</span>
                        </div>
                        <div className="column-name d-flex">
                          <label>Options:</label>
                          {q.serviceQuestionOptions.length > 0 ? (
                            <ul className="question-option">
                              {q.serviceQuestionOptions.map((o, i) => (
                                <li key={i}>{o.option}</li>
                              ))}
                            </ul>
                          ) : (
                            <span> No Options Present</span>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </Tab>
            <Tab eventKey="supply" title="Supply">
              <div class="container">
                <div class="row">
                  <div class="col-2">
                    <div className="column-name">
                      <label>Service Name : </label>
                    </div>
                  </div>
                  <div class="col-10">
                    <div className="column-name">
                      <div class="row">
                        <div class="col-6"><span>{service.name}</span></div>
                        <div class="col-6" style={{ textAlign: "end" }}>
                          <Button size="sm" onClick={() => { setSupplyAddModal(true) }}>Add New Supply</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2">
                    <div className="column-name">
                      <label>Description : </label>
                    </div>
                  </div>
                  <div class="col-10">
                    <div className="column-name">
                      <span>
                        <Tooltip title={service.description} arrow>
                          {truncateString(service.description, 100)}
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <Container className="service-list-table" style={{ padding: "0" }}>
                {/* {displayedSupplyList?.length === 0 ? (
                  <>
                    <div className="d-flex justify-content-center">
                      {" "}
                      No Supply to Show
                    </div>
                  </>
                ) : ( */}
                <ReusableTable
                  columns={SupplyColumns}
                  data={displayedSupplyList}
                  actions={actions}
                  actionWidth={'300px'}
                ></ReusableTable>
                {/* )} */}
              </Container>
            </Tab>
          </Tabs>
        </div>
      )}
      <ShowModal
        modalheading={showEditModal ? "Edit Question" : "Add New Question"}
        show={questionModal || showEditModal}
        body={<AddNewQuestion service={service} question={selectedQues} isAdded={newQuestionAdded} questionList={service?.questions || []}></AddNewQuestion>}
        handleClose={() => {
          setquestionModal(false)
          setEditModal(false)
        }}
      ></ShowModal>
      <ShowModal
        modalheading="Delete Question"
        show={showDeleteModal}
        classname={"add-service-modal"}
        body="Are you sure you want to delete this question"
        handleClose={() => setShowDeleteModal(false)}
        showFooter={true}
        Submit={deleteQuestion}
      ></ShowModal>
      <ShowModal
        modalheading="Update Prices"
        show={showUpdatePriceModal}
        classname={"add-service-modal"}
        body={<UpdateServicePrice servicePrice={servicePrice} serviceId={id} isAdded={updatePrice}></UpdateServicePrice>}
        handleClose={() => setUpdatePriceModal(false)}
      ></ShowModal>
      <ShowModal
        modalheading={showSupplyEditModal ? "Edit Supply" : "Add Supply"}
        show={showSupplyEditModal || showSupplyAddModal}
        classname={"add-service-modal"}
        body={<AddNewSupply supply={selectedSupply} serviceId={id} isAdded={handleSupplyModal}></AddNewSupply>}
        handleClose={() => {
          setSupplyAddModal(false)
          setSupplyEditModal(false)
          setSelectedSupply(null)
        }}
      ></ShowModal>
      <ShowModal
        modalheading="Delete Supply"
        show={showSupplyDeleteModal}
        classname={"add-service-modal"}
        body="Are you sure you want to delete this supply"
        handleClose={() => setSupplyDeleteModal(false)}
        showFooter={true}
        Submit={deleteSupply}
      ></ShowModal>
    </>
  );
}
