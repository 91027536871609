import React, { useEffect, useState } from "react";
import { downloadHelperList, getTasker, updateStatus } from "../../api/adminApi";
import ReusableTable from "../../components/core/ReusableTable";
import { TaskerColumns } from "../../metaData/ColumnList";
import { Button, Container } from "react-bootstrap";
import PaginationComponent from "../../components/core/PaginationControl";
import { START_LOADING, STOP_LOADING } from "../../redux/Slice/loaderSlice";
import { useDispatch } from "react-redux";
import ShowModal from "../../components/core/Modal";
import UpdateUserDetails from "../../components/account/UpdateUserDetails";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fileDownload from 'js-file-download'
import { notifyError } from "../../api/toasterService";

export default function TaskerList() {
  const [taskerList, setTaskerList] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItem, setTotalItem] = useState([10]);
  const [actions, setActions] = useState([]);
  const [showEditModal, setEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [searchFilter, setSearchFilter] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchTasker();
      AddActions()
    }, 500);
    return () => clearTimeout(debounceTimer);
  }, [currentPage, pageSize, searchFilter]);

  async function fetchTasker() {
    dispatch(START_LOADING());
    const filterArr = Object.entries(searchFilter)
    // console.log({ filterArr });
    const searchQuery = filterArr.reduce((str, ele) => {
      if (ele[1])
        return str += `&${ele[0]}=${ele[1]}`
      else
        return str
    }, '')
    console.log({ searchQuery });

    let res = await getTasker(searchQuery ? 0 : currentPage, pageSize, searchQuery);
    dispatch(STOP_LOADING());
    setTotalItem(res.data.totalRecords);
    setTaskerList(res.data.content);
  }

  const onPageChange = (pageNumber, noOfItem) => {
    setCurrentPage(pageNumber);
    setPageSize(noOfItem);
  };
  async function updateUserStatus(user) {
    console.log({ user });
    dispatch(START_LOADING());
    await updateStatus(user.userId, !user.isActive)
    dispatch(STOP_LOADING());
    await fetchTasker();
  }

  const openEditModal = async (user) => {
    setEditModal(true);
    setSelectedUser(user);
  };

  function AddActions() {
    setActions([
      {
        label: "view",
        clickAction: viewUserDetails,
        variant: "success",
      },
      {
        label: "edit",
        clickAction: openEditModal,
      },
    ]);
  }

  const viewUserDetails = (user) => {
    navigate(`/admin/helper/userDetails/${user.userId}`);
  };

  const newServiceAdded = () => {
    setEditModal(false);
    fetchTasker();
  };

  const handleFilter = (val, key) => {
    console.log(val, key);
    switch (key) {
      case 'customerName':
        setSearchFilter({ ...searchFilter, helperName: val })
        break;
      case 'phone':
        setSearchFilter({ ...searchFilter, phone: val })
        break;
      case 'email':
        setSearchFilter({ ...searchFilter, email: val })
        break;
      case 'postalCode':
        setSearchFilter({ ...searchFilter, postalCode: val })
        break;
      case 'postalAddress':
        setSearchFilter({ ...searchFilter, postaAddress: val })
        break;
      default:
        break;
    }
    setCurrentPage(0);
  };

  const downloadExcel = async () => {
    dispatch(START_LOADING());
    try {
      const response = await downloadHelperList()
      fileDownload(response, 'helperList.xlsx')
    } catch (error) {
      notifyError('Something went wrong!!!')
      console.error('Error downloading Excel file:', error);
    } finally {
      dispatch(STOP_LOADING());
    }
  }

  return (
    <div className="bg-white" style={{ padding: "1rem" }}>
      <div className="d-flex justify-content-between m-2">
        <h3 className="heading bg-white p-2"> Helper List</h3>
        <Button variant="primary" type="submit" size="sm" style={{ height: "fit-content" }}
          onClick={downloadExcel}
        >
          <FontAwesomeIcon icon="fa-solid fa-file-excel" /> Download All Data
        </Button>
      </div>
      {(
        <>
          <ReusableTable
            columns={TaskerColumns}
            data={taskerList}
            userStatusChange={updateUserStatus}
            actions={actions}
            handleColumnFilter={handleFilter}
            fontSize={'10px'}
          ></ReusableTable>
          <PaginationComponent
            onPageChange={onPageChange}
            itemsPerPage={pageSize}
            totalItems={totalItem}
            pageNo={currentPage}
            onPageSizeChange={setPageSize}
          ></PaginationComponent>
        </>
      )}
      <ShowModal
        modalheading={"Edit User Details"}
        show={showEditModal}
        classname={"add-service-modal"}
        body={<UpdateUserDetails isAdded={newServiceAdded} user={showEditModal ? selectedUser : undefined}></UpdateUserDetails>}
        handleClose={() => {
          setEditModal(false)
        }}
      ></ShowModal>
    </div>
  );
}
