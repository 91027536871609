// src/components/LoginPage.js
import React, { useState } from "react";
import { Container, Form, Button, Row, Col, Image, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import LeftSide from "../../components/account/leftSide";
import { signIn } from "../../api/authApi";
import { useDispatch } from "react-redux";
import { LOGIN_SUCCESS } from "../../redux/Slice/userSlice";
import { notifySuccess, notifyError, notifyInfo, notifyWarning } from '../../api/toasterService';
import { START_LOADING, STOP_LOADING } from "../../redux/Slice/loaderSlice";

const LoginPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(START_LOADING());
    try {
      let logIn = await signIn(formData);
      if (!logIn.isSuccess) {
        return;
      }
      // else {
      //   notifySuccess(logIn.message)
      // }
      dispatch(LOGIN_SUCCESS(logIn.data))
      navigate("/admin/dashboard");
      // console.log(logIn.data);

      // Save the token to local storage
      localStorage.setItem('token', logIn.data.authToken);
      // history.push("/dashboard");
    } catch (error) {

    } finally {
      dispatch(STOP_LOADING());
    }
  };

  return (
    <Row className="grey-back w-100 h-100">
      <Container style={{ marginTop: '100px' }} className=" white-back login flex-column">
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <Navbar.Brand href=""><img src="/logo.svg" style={{ width: "250px" }}></img></Navbar.Brand>
        </div>

        <h2>Welcome Back!</h2>
        <span className="grey-text-small">Login to your account</span>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="email" className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Enter email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="password" className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </Form.Group>
          {/* <div className="d-flex">
              <a>Remember me</a>
              <a>Forgot password?</a>
            </div> */}
          <Button
            className="form-control mt-3"
            variant="primary"
            type="submit"
          >
            Login
          </Button>
          <div className="mt-2">
            <Link to="forgot-password">Forgot password</Link>
          </div>
        </Form>
      </Container>

    </Row>
  );
};

export default LoginPage;
