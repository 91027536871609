// src/redux/store.js
import adminSlice from "./Slice/adminSlice";
import loaderSlice from "./Slice/loaderSlice";
import locationSlice from "./Slice/locationSlice";
import userSlice from "./Slice/userSlice";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: {
    admin: adminSlice,
    loader: loaderSlice,
    location: locationSlice
  },
});

export default store;
