import { useState } from "react";
import { Container, Dropdown, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { LOGOUT } from "../../redux/Slice/userSlice";

const TopNav = () => {
  const [openSeriviceMenu, setOpenServiceMenu] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const logout = () => {
    dispatch(LOGOUT());
    localStorage.clear();
    navigate("/");
  };


  const isNavLinkActive = (paths) => {
    return paths.some((path) => location.pathname === path || location.pathname.includes(path));
  };

  return (
    <Navbar className="topNav bg-body-tertiary" variant="light" bg="light" expand="lg"
      style={{ position: "sticky", top: 0 }}
    >
      <Container fluid className="d-flex justify-content-between">
        <Navbar.Brand href="/admin/dashboard"><img src="/logo.svg" style={{ width: "150px" }}></img></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-dark-example" />
        <Navbar.Collapse className="justify-content-center" style={{ marginLeft: "150px" }}>
          <Nav className=" justify-content-between align-items-center ">
            <NavLink active="active" to="/admin/dashboard">
              Dashboard
            </NavLink>

            <NavDropdown
              className="nav-link-top"
              title="Users"
              menuVariant="light"
              active={`${isNavLinkActive(['/admin/helper', '/admin/customer']) ? "active" : ""}`}
            >
              <NavDropdown.Item>
                <NavLink
                  className="dropdownLink"
                  active="active"
                  to="/admin/helper"
                >
                  Helper
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item>
                {" "}
                <NavLink
                  className="dropdownLink"
                  active="active"
                  to="/admin/customer"
                >
                  Customer
                </NavLink>
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              className="nav-link-top"
              title="Services"
              menuVariant="light"
              active={`${isNavLinkActive(['/admin/servicesCategory', '/admin/services']) ? "active" : ""}`}
            >
              <NavDropdown.Item>
                <NavLink active="active" to="/admin/servicesCategory">
                  Services Categories
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item>
                {" "}
                <NavLink active="active" to="/admin/services">
                  Services
                </NavLink>
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              className="nav-link-top"
              title="Locations"
              menuVariant="light"
              active={`${isNavLinkActive(['/admin/postalCode']) ? "active" : ""}`}
            >
              <NavDropdown.Item>
                <NavLink active="active" to="/admin/postalCode">
                  Postal Code
                </NavLink>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              className="nav-link-top"
              title="Helper Settings"
              menuVariant="light"
              active={`${isNavLinkActive(['/admin/settings-tools', '/admin/settings-language', '/admin/settings-vehicle', '/admin/settings-quick-fact']) ? "active" : ""}`}
            >
              <NavDropdown.Item>
                <NavLink active="active" to="/admin/settings-tools">
                  Tools
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item>
                {" "}
                <NavLink active="active" to="/admin/settings-language">
                  Language
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <NavLink active="active" to="/admin/settings-vehicle">
                  Vehicle
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item>
                {" "}
                <NavLink active="active" to="/admin/settings-quick-fact">
                  Quick Facts
                </NavLink>
              </NavDropdown.Item>
            </NavDropdown>
            <NavLink active="active" to="/admin/tasks">
              Tasks
            </NavLink>
            <NavLink active="active" to="/admin/skillTrade">
              Skill Trade
            </NavLink>
            <NavDropdown
              className="nav-link-top"
              title="Transactions"
              menuVariant="light"
              active={`${isNavLinkActive(['/admin/payments', '/admin/payouts']) ? "active" : ""}`}
            >
              <NavDropdown.Item>
                <NavLink active="active" to="/admin/payments">
                  Payments
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item>
                {" "}
                <NavLink active="active" to="/admin/payouts">
                  Payouts
                </NavLink>
              </NavDropdown.Item>
            </NavDropdown>
            {/* <NavLink active="active" to="/admin/payments">
              Payments
            </NavLink> */}
          </Nav>
        </Navbar.Collapse>

        <Dropdown align={"end"}>
          <Dropdown.Toggle
            className="profile-button"
            variant="outline-secondary"
            id="dropdown-basic"
          >
            <a className="profile">A</a>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Container>
    </Navbar>
  );
};
export default TopNav;
