import { useEffect, useState, useMemo } from "react";
import {
  getAllSkillTradeList,
} from "../../api/adminApi";
import { Button, Container, Form } from "react-bootstrap";
import ReusableTable from "../../components/core/ReusableTable";
import { SkillTradeColumns } from "../../metaData/ColumnList";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SAVE_CATEGORIES } from "../../redux/Slice/adminSlice";
import { START_LOADING, STOP_LOADING } from "../../redux/Slice/loaderSlice";
import { useNavigate } from "react-router-dom";
import { parseFilterDate } from '../../utils/dateUtils';

import PaginationComponent from "../../components/core/PaginationControl";
import SkillTradeDetails from "./SkillTradeDetails";
import ShowModal from "../../components/core/Modal";

export default function SkillTrades() {
  const dispatch = useDispatch();
  const [actions, setActions] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItem, setTotalItem] = useState([10]);
  const [data, setData] = useState([]);
  const [searchFilter, setSearchFilter] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState();

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchSkillTrade();
      AddActions()
    }, 500);
    return () => clearTimeout(debounceTimer);
  }, [currentPage, pageSize, searchFilter]);

  const viewSkillTradeDetails = (item) => {
    console.log({ item });
    navigate(`/admin/skillTrade/skillTradeDetails/`, { state: item });
  };

  async function fetchSkillTrade() {
    dispatch(START_LOADING());

    try {
      const filterArr = Object.entries(searchFilter);
      const searchQuery = filterArr.reduce((str, ele) => {
        if (ele[1]) return str += `&${ele[0]}=${ele[1]}`;
        return str;
      }, '');

      const response = await getAllSkillTradeList(currentPage, pageSize, searchQuery);
      setData(response.data.content);
      setTotalItem(response.data.totalRecords);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(STOP_LOADING());
    }
  }

  const onPageChange = (pageNumber, noOfItem) => {
    console.log({ pageNumber, noOfItem });
    setCurrentPage(() => pageNumber);
    setPageSize(() => noOfItem);
  };

  const handleFilter = (val, key) => {
    console.log({ val, key });
    switch (key) {
      case 'serviceName':
        setSearchFilter({ ...searchFilter, serviceName: val })
        break;
      case 'helperName':
        setSearchFilter({ ...searchFilter, helperName: val })
        break;
      case 'email':
        setSearchFilter({ ...searchFilter, email: val })
        break;
      default:
        break;
    }
    setCurrentPage(0)
  };

  const handleDateChange = (date, dateArray) => {
    // console.log({ date, dateArray });
    if (dateArray.length > 0) {
      const { startOfDay, endOfDay } = parseFilterDate(dateArray)
      setSearchFilter({ ...searchFilter, startDate: startOfDay, endDate: endOfDay })
      setCurrentPage(0)
    }
  };

  const handleSelectChange = (value, option, type) => {
    console.log({ value, option });
    setCurrentPage(0)
    setSearchFilter({ ...searchFilter, skillTradeStatus: value })
  };

  function AddActions() {
    setActions([
      {
        label: "view",
        clickAction: viewSkillTradeDetails,
        variant: "success",
      },
    ]);
  }

  const openViewModal = async (item) => {
    setShowModal(true);
    setSelectedSkill(item);
  };

  return (
    <Container className="bg-white" style={{ padding: "1rem" }}>
      <Container className="d-flex justify-content-between mb-2 bg-white p-2">
        <h3 className="heading"> Skill Trade List</h3>
      </Container>
      <hr></hr>
      <Container className="service-list-table" style={{ padding: "0" }}>
        <ReusableTable
          columns={SkillTradeColumns}
          data={data}
          actions={actions}
          handleColumnFilter={handleFilter}
          handleDateChange={handleDateChange}
          handleSelectChange={handleSelectChange}
        ></ReusableTable>
        <PaginationComponent
          onPageChange={onPageChange}
          itemsPerPage={pageSize}
          totalItems={totalItem}
          pageNo={currentPage}
          onPageSizeChange={setPageSize}
        ></PaginationComponent>
      </Container>
      {/* <ShowModal
        modalheading={"Skill Trade Details"}
        show={showModal}
        classname={"view-skill-trade"}
        body={<SkillTradeDetails data={selectedSkill} />}
        handleClose={() => {
          setShowModal(false)
        }}
        titleClass={'ms-auto'}
      ></ShowModal> */}
    </Container>
  );
}
