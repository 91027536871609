import { Accordion, Button, Col, Container, Form, Image, Row, Tab, Tabs } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { START_LOADING, STOP_LOADING } from "../../redux/Slice/loaderSlice";
import { notifySuccess, notifyError, notifyInfo, notifyWarning } from '../../api/toasterService';
import { formatDate, formatDateOnly } from '../../utils/dateUtils';
import { formatPriceInUSD } from '../../utils/priceUtils';
import { useLocation, useNavigate } from "react-router-dom";
import * as React from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { saveAs } from 'file-saver';
import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import DetailsCard from "../../components/core/DetailsCard";
import { Space, Table, Tag } from 'antd';
import { RightOutlined, DownOutlined, EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import validUrl from 'valid-url';
import ShowModal from "../../components/core/Modal";
import MyBreadCrumb from "../../components/core/MyBreadCrumb";
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { getAllSkillTradeList, skillTradeApproveReject } from "../../api/adminApi";
import RejectSkillTrade from "../../components/Admin/serviceManagement/RejectSkillTrade";
import { UPDATE_ROUTELIST } from "../../redux/Slice/adminSlice";

const InvertedIconButton = styled(IconButton)({
  color: 'white',
  backgroundColor: '#0b5ed7',
  width: '30px',
  height: '30px',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#0b5ed7',
  },
});

export default function SkillTradeDetails(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState();
  const [showmodal, setShowmodal] = useState();
  const [viewDocType, setViewDocType] = useState();
  const [skillTradeDetails, setSkillTradeDetails] = useState();
  const [rejectModal, setRejectModal] = useState();

  const location = useLocation();
  // const skillTradeDetails = location.state;
  // console.log({ skillTradeDetails });
  console.log({ routr: props.routeList });
  useEffect(() => {
    setSkillTradeDetails(location.state)
    if (!location.state) {
      notifyError('Something went wrong')
      window.location.href = '/admin/skillTrade';
    }
  }, []);

  const handlePDF = (url, type) => {
    if (validUrl.isWebUri(url)) {
      window.open(url, '_blank');
    } else {
      notifyWarning('Invalid document url.')
    }
  };

  const viewImage = (url, docType) => {
    setImageUrl(url);
    setViewDocType(docType);
    setShowmodal(true);
  };

  function truncateString(str, maxLength) {
    if (str && str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  }

  function isDateGreater(date) {
    const currentDate = new Date();
    const compareDate = new Date(date);
    if (compareDate < currentDate) {
      return true;
    } else {
      return false;
    }
  }

  const columns = [
    {
      title: 'Document Type',
      dataIndex: 'docType',
      key: 'docType',
      render: (docType) => {
        return <Tooltip title={docType} arrow>
          {truncateString(docType, 30)}
        </Tooltip>
      }
    },
    {
      title: 'Document Name',
      dataIndex: 'docName',
      key: 'docName',
      render: (docName) => {
        return <Tooltip title={docName} arrow>
          {truncateString(docName, 30)}
        </Tooltip>
      }
    },
    {
      title: 'Document Number',
      dataIndex: 'docNo',
      key: 'docNo',
      render: (docNo) => {
        return <Tooltip title={docNo} arrow>
          {truncateString(docNo, 30)}
        </Tooltip>
      }
    },
    {
      title: 'Issue Date',
      dataIndex: 'issueDate',
      key: 'issueDate',
      render: (issueDate) => formatDateOnly(issueDate)
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: (expiryDate) => <span
        style={{ color: isDateGreater(expiryDate) ? "red" : "" }}
      >{formatDateOnly(expiryDate)}</span>
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      align: "center",
      render: (record) => <>
        {/* <Tooltip title={'View'} arrow>
          <EyeOutlined onClick={() => viewImage(record.docUrl, record?.docType)} />
        </Tooltip> */}
        {'  '}
        <Tooltip title={'View'} arrow>
          <EyeOutlined onClick={() => handlePDF(record.docUrl)} />
        </Tooltip>
      </>,
    },
  ];
  const data = []

  const doc1 = skillTradeDetails?.tradeCertificateName ? {
    key: 1,
    docName: skillTradeDetails?.tradeCertificateName,
    docNo: skillTradeDetails?.tradeCertificateNumber,
    issueDate: skillTradeDetails?.tradeCertificateIssueDate,
    expiryDate: skillTradeDetails?.tradeCertificateExpiryDate,
    docUrl: skillTradeDetails?.tradeCertificateFileUrl,
    authorityName: skillTradeDetails?.tradeCertificateAuthorityName,
    authorityAddress: skillTradeDetails?.tradeCertificateAuthorityAddress,
    docType: 'Trade Certificate'
  } : ''
  if (doc1)
    data.push(doc1)

  const doc2 = skillTradeDetails?.insurerName ? {
    key: 2,
    docName: skillTradeDetails?.insurerName,
    docNo: skillTradeDetails?.insuranceNumber,
    issueDate: skillTradeDetails?.insuranceIssueDate,
    expiryDate: skillTradeDetails?.insuranceExpiryDate,
    docUrl: skillTradeDetails?.insuranceFileUrl,
    insurerName: skillTradeDetails?.insurerName,
    docType: 'Insurance Certificate'
  } : ''
  if (doc2)
    data.push(doc2)

  const doc3 = skillTradeDetails?.supportingDocumentName ? {
    key: 3,
    docName: skillTradeDetails?.supportingDocumentName,
    docNo: 'NA',
    issueDate: '',
    expiryDate: '',
    docUrl: skillTradeDetails?.supportingDocumentFileUrl,
    authorityName: skillTradeDetails?.supportingDocumentAuthorityName,
    docType: 'Supporting Certificate'
  } : ''
  if (doc3)
    data.push(doc3)


  const routeList = [
    {
      href: '/admin/dashboard',
      title: (
        <>
          <span>Dashboard</span>
        </>
      ),
    },

    {
      href: '/admin/skillTrade',
      title: (
        <>
          <span>Skill Trade</span>
        </>
      ),
    },
    {
      title: 'Skill Trade Details',
    },
  ]

  dispatch(UPDATE_ROUTELIST(routeList))

  const handleApproveReject = async (status, reason) => {
    dispatch(START_LOADING());
    try {
      const updateQuery = `skillId=${skillTradeDetails?.taskerSkillId}&status=${status}${reason ? '&rejectionReason=' + reason : ''}`
      const apiRes = await skillTradeApproveReject({ updateQuery });
      if (apiRes) {
        const response = await getAllSkillTradeList(0, 100, `&taskerSkillId=${skillTradeDetails?.taskerSkillId}`);
        console.log({ response });
        if (response?.data?.content.length > 0) {
          // const updatedSkillTrade = response?.data?.content.filter((skill) => skill.taskerSkillId == skillTradeDetails.taskerSkillId)
          // console.log({ updatedSkillTrade });
          setSkillTradeDetails(response?.data?.content[0])
        }
      }
    } catch (error) {

    } finally {
      dispatch(STOP_LOADING());
      setRejectModal(false)
    }
  };

  return (
    <>
      {skillTradeDetails && (
        <div className="bg-white p-4 service-question">
          <div className="mb-4">
            {/* <MyBreadCrumb routeList={routeList} title={'Skill Trade Details'} /> */}
            <h3 className="text-center"> Skill Trade Details</h3>
          </div>
          <hr></hr>
          <div class="container" >
            <div class="row" >
              <DetailsCard title={'Service Name'} value={skillTradeDetails.serviceName || skillTradeDetails.serviceId} length={32} />
              {/* <DetailsCard title={'Description'} value={skillTradeDetails.description} length={25} /> */}
              <DetailsCard title={'Helper Name'} value={`${skillTradeDetails.helperName}`} length={32} />
              <DetailsCard title={'Email'} value={skillTradeDetails.email} length={32} />
              <DetailsCard title={'Created At'} value={formatDate(skillTradeDetails.createdAt)} length={32} />
            </div>
            <div class="row" >
              <DetailsCard title={'Phone'} value={skillTradeDetails.phone || "NA"} length={32} />
              <DetailsCard title={'Price'} value={formatPriceInUSD(skillTradeDetails.price)} length={32} />
              <DetailsCard title={'Base Price'} value={formatPriceInUSD(skillTradeDetails.basePrice)} length={32} />
              <DetailsCard title={'Status'} value={skillTradeDetails.skillTradeStatus} length={32} color={
                skillTradeDetails.skillTradeStatus == 'REJECTED'
                  ? '#d32f2f'
                  : skillTradeDetails.skillTradeStatus == 'SUBMITTED'
                    ? '#f57c00'
                    : '#388e3c'
              }
              />
            </div>
            <div class="row" >
              <DetailsCard title={'Two Hour Required'} value={skillTradeDetails.isTwoHourRequired ? 'Yes' : 'No'} length={25} />
              <DetailsCard title={'Skills And Experiences'} value={skillTradeDetails?.skillsAndExperiences || 'NA'} length={50} />
              <DetailsCard title={''} value={''} length={50} />
              {skillTradeDetails?.getSkillTradeRejectionReason && skillTradeDetails?.skillTradeStatus === 'REJECTED' ?
                <DetailsCard title={'Rejection Reason'} value={skillTradeDetails?.getSkillTradeRejectionReason || 'NA'} length={70} />
                : <DetailsCard title={''} value={''} length={32} />
              }
            </div>
            {/* <div class="row" >
              <DetailsCard title={'Is Skill Trade'} value={skillTradeDetails.isSkillTrade ? 'Yes' : 'No'} length={25} />
              <DetailsCard title={'Two Hour Required'} value={skillTradeDetails.isTwoHourRequired ? 'Yes' : 'No'} length={25} />
            </div> */}
          </div>
          <hr style={{ marginTop: "0px" }}></hr>
          <h5 className=""> Documnets</h5>
          <Table
            columns={columns}
            pagination={false}
            expandable={{
              expandedRowRender: (record) => (
                <div class="container" style={{ marginLeft: "40px" }}>
                  <div class="row" >
                    {
                      record?.authorityName &&
                      <DetailsCard title={'Authority Name'} value={record?.authorityName} length={50} />
                    }
                    {
                      record?.authorityAddress &&
                      <DetailsCard title={'Authority Address'} value={record?.authorityAddress} length={50} />
                    }
                    {/* {
                      record?.insurerName &&
                      <DetailsCard title={'Insurer Name'} value={record?.insurerName} length={50} />
                    } */}
                  </div>
                </div>
                // <p
                //   style={{
                //     margin: 0,
                //   }}
                // >
                //   {record.authorityName}
                // </p>
              ),
              rowExpandable: (record) => record?.authorityName,
              expandIcon: ({ expanded, onExpand, record }) => {
                if (record?.authorityName) {
                  return expanded ? (
                    <DownOutlined onClick={e => onExpand(record, e)} />
                  ) : (
                    <RightOutlined onClick={e => onExpand(record, e)} />
                  )
                } else {
                  return ''
                }
              }
            }
            }
            dataSource={data}
          />
          <hr style={{ marginTop: "0px" }}></hr>
          <div
            style={{ textAlign: "center" }}
          >
            <Button variant="success"
              style={{ width: '80px', marginRight: "10px" }}
              size="sm"
              disabled={skillTradeDetails?.skillTradeStatus === 'VERIFIED'}
              onClick={() => handleApproveReject('VERIFIED')}
            >Approve</Button>{' '}
            <Button variant="danger"
              style={{ width: '80px' }}
              size="sm"
              disabled={skillTradeDetails?.skillTradeStatus === 'REJECTED'}
              onClick={() => setRejectModal(true)}
            >Reject</Button>{' '}
          </div>
        </div >
      )
      }
      <ShowModal
        modalheading={viewDocType}
        classname="modal-image"
        show={showmodal}
        body={<Image className="modal-img" src={imageUrl}></Image>}
        handleClose={() => setShowmodal(false)}
        titleClass={'ms-auto'}
      ></ShowModal>
      <ShowModal
        modalheading={'Rejection Reason'}
        classname={"add-service-modal"}
        show={rejectModal}
        body={<RejectSkillTrade handleReject={handleApproveReject} />}
        handleClose={() => setRejectModal(false)}
      ></ShowModal>
    </>
  );
}
