import { useState, useEffect, useRef } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { addNewSupply, editSupply } from "../../../api/adminApi";
import FormLabelStar from "../../core/FormLabelStar";
import { validationLength } from "../../../metaData/validationLength"

const isMandataryList = [
  {
    name: "True",
    id: true
  },
  {
    name: "False",
    id: false
  }
]
export default function AddNewSupply({ isAdded, supply, serviceId }) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    id: "",
    serviceId,
    isMandatory: false
  });

  const [selectedIsMandatary, setSelectedIsMandatary] = useState("");
  const isMandataryRef = useRef(null);


  useEffect(() => {
    if (supply) {
      console.log({ supply });
      setFormData({
        ...formData,
        name: supply.name,
        id: supply.id,
        isMandatory: supply.isMandatory
      })
      setSelectedIsMandatary(supply.isMandatory || false)
    }
  }, [supply])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleReset = () => {
    setFormData({
      ...formData,
      name: '',
      isMandatory: ''
    });
    setSelectedIsMandatary('')
    setTimeout(() => {
      isMandataryRef.current.selectedIndex = 0
    }, 0)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    if (supply) {
      await editSupply(formData);
    } else {
      await addNewSupply(formData);
    }
    isAdded(true);
  };

  const handleChangeMandatatry = (event) => {
    const { name, value } = event.target;

    setSelectedIsMandatary(value)
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Supply Name<FormLabelStar /></Form.Label>
        <Form.Control
          name="name"
          required
          value={formData.name}
          type="text"
          onChange={handleChange}
          placeholder="Enter Supply Name"
          maxLength={validationLength.service.supply.name.maxLength}
        />
        {formData.name?.length > validationLength.service.supply.name.maxLength - 1 && (
          <div className="province-name-validate">{validationLength.message(validationLength.service.supply.name.maxLength)}</div>
        )}
        <Form.Control.Feedback type="invalid">
          Please enter supply name.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Is Mandatory<FormLabelStar /></Form.Label>
        <Form.Select
          size="sm"
          aria-label=" Select Is Mandatory"
          name="isMandatory"
          onChange={handleChangeMandatatry}
          value={selectedIsMandatary}
          required
          ref={isMandataryRef}
        >
          <option value="" disabled>Select</option>
          {isMandataryList?.map((cat) => (
            <option value={cat.id}>{cat.name}</option>
          ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          Please select Is Mandatory.
        </Form.Control.Feedback>
      </Form.Group>
      <div className="d-flex justify-content-between m-2">
        <Button variant="primary" type="submit" size="sm">
          Submit
        </Button>
        <Button variant="secondary" type="reset"
          onClick={() => { handleReset() }}
          size="sm"
        >
          Reset
        </Button>
      </div>
    </Form>
  );
}
