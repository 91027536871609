import { createSlice } from "@reduxjs/toolkit";

const locationSlice = createSlice({
  name: "locationData",
  initialState: {
    totalCountry: [],
    totalProvince: [],
    totalSuperCity: [],
    totalCity: [],
    totalPostalCodes: [],
    filteredCountry: [],
    filteredProvince: [],
    filteredSuperCity: [],
    filteredCity: [],
    filteredPostalCode: [],
    selectedCountry: [],
    selectedProvince: [],
    selectedSuperCity: [],
    selectedCity: [],
    selectedPostalCode: [],
  },
  reducers: {
    LOAD_COUNTRY: (state, action) => {
      return {
        ...state,
        filteredProvince: [],
        filteredSuperCity: [],
        filteredCity: [],
        filteredPostalCode: [],
        totalProvince: [],
        totalSuperCity: [],
        totalCity: [],
        totalPostalCodes: [],
        selectedProvince: [],
        selectedSuperCity: [],
        selectedCity: [],
        selectedPostalCode: [],
        totalCountry: action.payload,
        filteredCountry: action.payload,
      };
    },
    LOAD_PROVINCE: (state, action) => {
      return {
        ...state,
        filteredSuperCity: [],
        filteredCity: [],
        filteredPostalCode: [],
        totalSuperCity: [],
        totalCity: [],
        totalPostalCodes: [],
        selectedSuperCity: [],
        selectedCity: [],
        selectedPostalCode: [],
        totalProvince: [...action.payload],
        filteredProvince: [...action.payload],
      };
    },
    UPDATE_PROVINCE: (state, action) => {
      return {
        ...state,
        totalProvince: [...state.totalProvince, ...action.payload],
        filteredProvince: [...state.totalProvince, ...action.payload],
      };
    },
    CLEAR_PROVINCE: (state, action) => {
      return {
        ...state,
        totalProvince: [],
        filteredProvince: [],
        totalSuperCity: [],
        filteredSuperCity: [],
        totalCity: [],
        filteredCity: [],
        totalPostalCodes: [],
        filteredPostalCode: [],
      };
    },
    CLEAR_SUPER_CITY: (state, action) => {
      return {
        ...state,
        totalSuperCity: [],
        filteredSuperCity: [],
        totalCity: [],
        filteredCity: [],
        totalPostalCodes: [],
        filteredPostalCode: [],
      };
    },
    CLEAR_CITY: (state, action) => {
      return {
        ...state,
        totalCity: [],
        filteredCity: [],
        totalPostalCodes: [],
        filteredPostalCode: [],
      };
    },
    CLEAR_POSTAL_CODES: (state, action) => {
      return {
        ...state,
        totalPostalCodes: [],
        filteredPostalCode: [],
      };
    },
    LOAD_SUPERCITY: (state, action) => {
      return {
        ...state,
        filteredCity: [],
        filteredPostalCode: [],
        totalCity: [],
        totalPostalCodes: [],
        selectedCity: [],
        selectedPostalCode: [],
        totalSuperCity: [...action.payload],
        filteredSuperCity: [...action.payload],
      };
    },
    UPDATE_SUPERCITY: (state, action) => {
      return {
        ...state,
        totalSuperCity: [...state.totalSuperCity, ...action.payload],
        filteredSuperCity: [...state.totalSuperCity, ...action.payload],
      };
    },
    LOAD_CITY: (state, action) => {
      return {
        ...state,
        filteredPostalCode: [],
        totalPostalCodes: [],
        selectedPostalCode: [],
        totalCity: [...action.payload],
        filteredCity: [...action.payload],
      };
    },
    UPDATE_CITY: (state, action) => {
      return {
        ...state,
        totalCity: [...state.totalCity, ...action.payload],
        filteredCity: [...state.totalCity, ...action.payload],
      };
    },
    LOAD_POSTAL_CODE: (state, action) => {
      return {
        ...state,
        totalPostalCodes: [...action.payload],
        filteredPostalCode: [...action.payload],
      };
    },
    UPDATE_POSTAL_CODE: (state, action) => {
      return {
        ...state,
        totalPostalCodes: [...state.totalPostalCodes, ...action.payload],
        filteredPostalCode: [...state.totalPostalCodes, ...action.payload],
      };
    },
    CHANGE_COUNTRY_IS_ACTIVE: (state, action) => {
      return {
        ...state,
        selectedProvince: [],
        selectedSuperCity: [],
        selectedCity: [],
        selectedPostalCode: [],
        totalCountry: action.payload.updatedTotalCountry,
        filteredCountry: [...action.payload.updatedFilteredCountry],
      };
    },
    CHANGE_PROVINCE_IS_ACTIVE: (state, action) => {
      return {
        ...state,
        selectedSuperCity: [],
        selectedCity: [],
        selectedPostalCode: [],
        totalProvince: action.payload.updatedTotalProvince,
        filteredProvince: [...action.payload.updatedFilteredProvince],
        selectedProvince: [...state.selectedProvince, action.payload.updatedLocation],
      };
    },
    CHANGE_SUPER_CITY_IS_ACTIVE: (state, action) => {
      return {
        ...state,
        selectedCity: [],
        selectedPostalCode: [],
        totalSuperCity: action.payload.updatedTotalSuperCity,
        filteredSuperCity: [...action.payload.updatedFilteredSuperCity],
        selectedSuperCity: [...state.selectedSuperCity, action.payload.updatedLocation],
      };
    },
    CHANGE_CITY_IS_ACTIVE: (state, action) => {
      return {
        ...state,
        selectedPostalCode: [],
        totalCity: action.payload.updatedTotalCity,
        filteredCity: [...action.payload.updatedFilteredCity],
        selectedCity: [...state.selectedCity, action.payload.updatedLocation],
      };
    },
    CHANGE_POSTAL_CODE_IS_ACTIVE: (state, action) => {
      console.log({ ...action.payload });
      return {
        ...state,
        totalPostalCodes: action.payload.updatedTotalPostalCodes,
        filteredPostalCode: [...action.payload.updatedFilteredPostalCode],
        selectedPostalCode: [...state.selectedPostalCode, action.payload.updatedLocation],
      };
    },
    SELECT_PROVINCE: (state, action) => {
      return {
        ...state,
        selectedSuperCity: [],
        selectedCity: [],
        selectedPostalCode: [],
        selectedProvince: [...state.selectedProvince, action.payload],
      };
    },
    FILTER_COUNTRY: (state, action) => {
      return {
        ...state,
        filteredCountry: action.payload.searchText
          ? action.payload.filteredCountries
          : state.totalCountry,
      };
    },
    FILTER_PROVINCE: (state, action) => {
      return {
        ...state,
        filteredProvince: action.payload.searchText
          ? action.payload.filteredProvinces
          : state.totalProvince,
      };
    },
    FILTER_SUPER_CITY: (state, action) => {
      return {
        ...state,
        filteredSuperCity: action.payload.searchText
          ? action.payload.filteredSuperCities
          : state.totalSuperCity,
      };
    },
    FILTER_CITY: (state, action) => {
      return {
        ...state,
        filteredCity: action.payload.searchText
          ? action.payload.filteredCities
          : state.totalCity,
      };
    },
    FILTER_POSTAL_CODE: (state, action) => {
      return {
        ...state,
        filteredPostalCode: action.payload.searchText
          ? action.payload.filteredPostalCodes
          : state.totalPostalCodes,
      };
    },
  },
});

export const {
  LOAD_COUNTRY,
  LOAD_PROVINCE,
  UPDATE_PROVINCE,
  UPDATE_SUPERCITY,
  UPDATE_CITY,
  UPDATE_POSTAL_CODE,
  CLEAR_PROVINCE,
  CLEAR_SUPER_CITY,
  CLEAR_CITY,
  CLEAR_POSTAL_CODES,
  LOAD_SUPERCITY,
  LOAD_CITY,
  LOAD_POSTAL_CODE,
  CHANGE_COUNTRY_IS_ACTIVE,
  CHANGE_PROVINCE_IS_ACTIVE,
  CHANGE_SUPER_CITY_IS_ACTIVE,
  CHANGE_CITY_IS_ACTIVE,
  CHANGE_POSTAL_CODE_IS_ACTIVE,
  SELECT_PROVINCE,
  FILTER_COUNTRY,
  FILTER_PROVINCE,
  FILTER_SUPER_CITY,
  FILTER_CITY,
  FILTER_POSTAL_CODE,
} = locationSlice.actions;
export default locationSlice.reducer;
