import { useState, useEffect } from "react";
import {
  Button,
  Form,
  Container,
  Card,
  Row,
  Col,
  Stack,
  ListGroup,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as ReactTooltip } from "react-tooltip";
import { v4 as uuidv4 } from 'uuid';
import Tooltip from '@mui/material/Tooltip';


export default function LocationGridRow({
  location,
  deleteLocation,
  handleCheckboxChange,
  populateLocation,
  checked,
  updateLocation,
  setBorder = false
}) {
  const tooltipId = uuidv4();
  console.log({ location, tooltipId });

  function truncateString(str, maxLength) {
    if (str && str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  }

  return (
    <Container style={{
      marginBottom: "10px",
      width: "95%"
    }}>
      <Row style={{
        paddingTop: "0px",
        // border: setBorder ? "2px solid blue" : "none",
        background: setBorder ? "rgb(169 169 247)" : "none",
        borderRadius: "10px",
      }}>
        <Col sm={8} className="d-flex align-items-center">
          <Form.Group controlId="formBasicCheckbox" className="mr-auto">
            <Tooltip title={location.countryName || location.provinceName || location.superCityName || location.cityName || location.postalCode} arrow>
              <div className="d-flex align-items-center">
                <Form.Check
                  type="checkbox"
                  name={location.value}
                  onChange={(ev) => handleCheckboxChange(ev, location)}
                  checked={location?.isChecked}
                />
                <span
                  onClick={(e) => {
                    populateLocation(location);
                  }}
                  style={{ marginLeft: "10px" }}
                  className={(!location.postalCode && !location.isActive && "deactivated-text") + " cursor-change"}
                  data-tooltip-id={tooltipId}
                >

                  {truncateString(location.countryName || location.provinceName || location.superCityName || location.cityName || location.postalCode, 10)}
                  {location?.serviceStatus &&
                    ((location?.serviceStatus === 'ACTIVE')
                      ? <FontAwesomeIcon icon="fa-solid fa-circle-check"
                        style={{ color: "rgb(53 179 40)", paddingLeft: "5px" }}
                      />
                      : (location?.serviceStatus === 'TEMP_BLOCK')
                        ? <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation"
                          style={{ color: "rgb(255,255,0)", paddingLeft: "5px" }}
                        />
                        : <FontAwesomeIcon icon="fa-solid fa-circle-xmark"
                          style={{ color: "rgb(253 0 0)", paddingLeft: "5px" }}
                        />)
                  }
                </span>
                {/* <ReactTooltip
                id={tooltipId}
                place="bottom"
                content={location.countryName || location.provinceName || location.superCityName || location.cityName || location.postalCode}
              /> */}
              </div>
            </Tooltip>
          </Form.Group>
        </Col>
        <Col sm={4} style={{ textAlign: "center" }} className="align-items-center">
          <div className=" cursor-change">
            <FontAwesomeIcon
              icon={faPenToSquare}
              onClick={(e) => {
                updateLocation(location);
              }}
              style={{ color: "rgb(8 0 255)", paddingLeft: "5px" }}
            />
            {
              (!location?.countryName) &&
              <FontAwesomeIcon
                icon={faTrash}
                onClick={(e) => {
                  deleteLocation(location);
                }}
                style={{ color: "rgb(253 0 0)", paddingLeft: "5px" }}
              />
            }
          </div>
        </Col>
      </Row>
    </Container >
  );
}
