import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

function Loader() {
  const loading = useSelector((state) => state.loader).isLoading;

  console.log(loading);

  return loading ? (
    <div className="full-page-spinner">
      <Spinner
        className="spinner-loader"
        animation="border"
        size="lg"
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  ) : null;
}

export default Loader;
