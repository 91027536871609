import moment from 'moment';

export const formatDate = function (inputDateStr) {
  // Split the input date string into components
  // console.log({ inputDateStr });
  if (!inputDateStr)
    return 'NA'
  // const [datePart, timePart] = inputDateStr.split(' ');
  // const [day, month, year] = datePart.split('/');
  // const [hours, minutes] = timePart.split(':');

  // Create a new Date object using the components
  // const inputDate = new Date(`${year}-${month}-${day}T${hours}:${minutes}`);
  const inputDate = new Date(inputDateStr);

  // Check if the parsed date is valid
  if (isNaN(inputDate.getTime())) {
    // If the input date is invalid, return an error or handle it accordingly
    return false;
  }

  const formattedDate = inputDate.toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  const parts = formattedDate.split(', ');
  const result = `${parts[0]}, ${parts[1]} ${parts[2]}`;
  // console.log({ inputDateStr, result });
  return result;
}

export const formatDateOnly = function (inputDateStr) {
  // Split the input date string into components
  // console.log({ inputDateStr });
  if (!inputDateStr)
    return 'NA'
  // const [datePart, timePart] = inputDateStr.split(' ');
  // const [day, month, year] = datePart.split('/');
  // const [hours, minutes] = timePart.split(':');

  // Create a new Date object using the components
  // const inputDate = new Date(`${year}-${month}-${day}T${hours}:${minutes}`);
  const inputDate = new Date(inputDateStr);

  // Check if the parsed date is valid
  if (isNaN(inputDate.getTime())) {
    // If the input date is invalid, return an error or handle it accordingly
    return false;
  }

  // Define options for formatting
  const options = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    // hour: '2-digit',
    // minute: '2-digit',
    // hour12: true,
  };

  // Format the date using Intl.DateTimeFormat
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(inputDate);

  // Output the formatted date
  // console.log({ formattedDate });

  // Return the formatted date
  return formattedDate;
}

export const parseFilterDate = dateArray => {
  let startOfDay = '';
  let endOfDay = '';
  try {
    const startDate = moment(dateArray[0], 'MMM DD, YYYY');
    const endDate = moment(dateArray[1], 'MMM DD, YYYY');

    // Start of the day
    startOfDay = startDate.startOf('day').toISOString();

    // End of the day
    endOfDay = endDate.endOf('day').toISOString();
    return { startOfDay, endOfDay }
  } catch (error) {
    return { startOfDay, endOfDay }
  }
};


// Example usage:
// const inputDateStr = '13/12/2023 05:00 AM';
// const formattedDate = formatDate(inputDateStr);
// console.log(formattedDate);
