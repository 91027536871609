// src/components/LoginPage.js
import React, { useState, useEffect } from "react";
import { Container, Form, Button, Row, Col, Image, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import LeftSide from "../../components/account/leftSide";
import { forgotPassword, resetPassword } from "../../api/authApi";
import { useDispatch } from "react-redux";
import { notifySuccess, notifyError, notifyInfo, notifyWarning } from '../../api/toasterService';
import { START_LOADING, STOP_LOADING } from "../../redux/Slice/loaderSlice";
import { InputOTP } from "antd-input-otp";

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    "emailPhone": "",
    "forgotBy": "ALL",
    "otp": ""
  });

  const [passwordFormData, setPasswordFormData] = useState({
    "emailPhone": "",
    "forgotBy": "ALL",
    "otp": "",
    newPassword: "",
    confirmPassword: ""
  });

  const timeInSec = 30;
  const [isResendActive, setIsResendActive] = useState(false);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [otpValues, setOtpValues] = useState([]);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [countdown, setCountdown] = useState(timeInSec);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const otpString = otpValues.join('');
      console.log(otpString);
      if (isOTPSent && otpString.length < 6) {
        return
      }
      dispatch(START_LOADING());

      if (isOTPSent) {
        const response = await forgotPassword({
          ...formData,
          otp: otpString
        });
        if (response?.isSuccess) {
          setIsOTPVerified(true)
          setPasswordFormData({
            ...passwordFormData,
            otp: otpString,
            emailPhone: formData.emailPhone
          });
        }
      } else {
        handleResendClick()
      }
    } catch (error) {

    } finally {
      dispatch(STOP_LOADING());
    }
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordFormData({
      ...passwordFormData,
      [name]: value,
    });
  };

  useEffect(() => {
    // Update UI when the countdown changes
    if (countdown > 0 && resendDisabled) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [countdown, resendDisabled]);

  const handleResendClick = async () => {
    try {
      dispatch(START_LOADING());

      const response = await forgotPassword(formData);
      if (response?.isSuccess) {
        setIsOTPSent(true)

        setResendDisabled(true);
        setCountdown(timeInSec);

        const timer = setInterval(() => {
          setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        setTimeout(() => {
          clearInterval(timer);
          setResendDisabled(false);
        }, timeInSec * 1000);
      }
    } catch (error) {

    } finally {
      dispatch(STOP_LOADING());
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      // Password validation logic
      const regexLowerCase = /[a-z]/;
      const regexUpperCase = /[A-Z]/;
      const regexSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
      if (passwordFormData.newPassword.length < 8) {
        setError('Password must be at least 8 characters long');
        return
      } else if (!regexLowerCase.test(passwordFormData.newPassword)) {
        setError('Password must contain at least one lowercase letter');
        return
      } else if (!regexUpperCase.test(passwordFormData.newPassword)) {
        setError('Password must contain at least one uppercase letter');
        return
      } else if (!regexSpecialChar.test(passwordFormData.newPassword)) {
        setError('Password must contain at least one special character');
        return
      } else if (passwordFormData.newPassword !== passwordFormData.confirmPassword) {
        setError('Passwords do not match');
        return
      } else {
        setError('')
        console.log('Password is valid:', passwordFormData.newPassword);
        dispatch(START_LOADING());
        const response = await resetPassword(passwordFormData);
        console.log({ response });
        if (response?.isSuccess) {
          navigate("/")
        }
      }
    } catch (error) {

    } finally {
      dispatch(STOP_LOADING());
    }
  };

  return (
    <Row className="grey-back w-100 h-100">
      <Container style={{ marginTop: '100px' }} className=" white-back login flex-column">
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <Navbar.Brand href=""><img src="/logo.svg" style={{ width: "250px" }}></img></Navbar.Brand>
        </div>
        <h2>Forgot Password</h2>
        {/* <span className="grey-text-small">Please enter your email to recieve a verification code</span> */}
        {!isOTPVerified && < Form onSubmit={handleSubmit}>
          <Form.Group controlId="email" className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="emailPhone"
              placeholder="Enter email"
              value={formData.emailPhone}
              onChange={handleChange}
              required
              disabled={isOTPSent}
            />
          </Form.Group>
          {isOTPSent &&
            <>
              <Form.Group className="mb-3">
                <Form.Label>OTP</Form.Label>
                <InputOTP
                  inputClassName="otp-input"
                  autoFocus inputType="numeric"
                  length={6}
                  value={otpValues}
                  onChange={setOtpValues}
                />
              </Form.Group>

              <div className="d-flex justify-content-between ">
                {resendDisabled ? (<p>Resend OTP in {countdown}s</p>) :
                  <Button style={{ padding: "0px" }} variant="link" onClick={handleResendClick}>Resend OTP</Button>
                }
              </div>
            </>
          }
          <Button
            className="form-control mt-3"
            variant="primary"
            type="submit"
            disabled={isOTPSent && otpValues.join('').length !== 6}
          >
            Submit
          </Button>

        </Form>
        }
        {isOTPVerified &&
          <Form onSubmit={handlePasswordSubmit}>
            <Form.Group controlId="password1" className="mb-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                name="newPassword"
                placeholder="Enter password"
                value={passwordFormData.newPassword}
                onChange={handlePasswordChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="password2" className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                name="confirmPassword"
                placeholder="Enter confirm password"
                value={passwordFormData.confirmPassword}
                onChange={handlePasswordChange}
                required
              />
            </Form.Group>
            {error && <div className="province-name-validate">
              {error}
            </div>}
            <Button
              className="form-control mt-3"
              variant="primary"
              type="submit"
            >
              Submit
            </Button>
          </Form>
        }
        <div className="mt-2">
          <Link to="/">Login</Link>
        </div>
      </Container>

    </Row >
  );
};

export default ForgotPassword;
