function formatPriceInUSD(price) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  return formatter.format(price);
}

// Example usage:
// const price = 1234.5678;
// const formattedPrice = formatPriceInUSD(price);
// console.log(formattedPrice);  // Output: $1,234.57
module.exports = {
  formatPriceInUSD
}