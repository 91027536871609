import React, { useEffect, useState } from "react";
import { downloadCustomerList, getCustomer, updateStatus } from "../api/adminApi";
import ReusableTable from "../components/core/ReusableTable";
import { CustomerColumns } from "../metaData/ColumnList";
import { Button, Container } from "react-bootstrap";
import PaginationComponent from "../components/core/PaginationControl";
import { useDispatch } from "react-redux";
import { START_LOADING, STOP_LOADING } from "../redux/Slice/loaderSlice";
import UpdateUserDetails from "../components/account/UpdateUserDetails";
import ShowModal from "../components/core/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fileDownload from 'js-file-download'
import { notifyError } from "../api/toasterService";

export default function Customer() {
  const [customerList, setCustomerList] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItem, setTotalItem] = useState([10]);
  const [actions, setActions] = useState([]);
  const [showEditModal, setEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [searchFilter, setSearchFilter] = useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchCustomer();
      AddActions()
    }, 500);
    return () => clearTimeout(debounceTimer);
  }, [currentPage, pageSize]);

  async function fetchCustomer() {
    dispatch(START_LOADING());
    console.log({ searchFilter });
    const filterArr = Object.entries(searchFilter)
    console.log({ filterArr });
    const searchQuery = filterArr.reduce((str, ele) => {
      if (ele[1])
        return str += `&${ele[0]}=${ele[1]}`
      else
        return str
    }, '')
    console.log({ searchQuery });
    let customerList = await getCustomer(currentPage, pageSize, searchQuery);
    dispatch(STOP_LOADING());

    setTotalItem(customerList.data.totalRecords);
    setCustomerList(customerList.data.content);
  }
  const onPageChange = (pageNumber, noOfItem) => {
    setCurrentPage(pageNumber);
    setPageSize(noOfItem);
  };

  async function updateUserStatus(user) {
    console.log({ user });
    dispatch(START_LOADING());
    await updateStatus(user.userId, !user.isActive)
    dispatch(STOP_LOADING());
    await fetchCustomer();
  }
  const openEditModal = async (user) => {
    setEditModal(true);
    setSelectedUser(user);
  };

  function AddActions() {
    setActions([
      // {
      //   label: "view",
      //   // clickAction: viewServiceDetails,
      //   variant: "success",
      // },
      {
        label: "edit",
        clickAction: openEditModal,
      },
    ]);
  }

  const newServiceAdded = () => {
    setEditModal(false);
    fetchCustomer();
  };

  const handleFilter = (val, key) => {

    console.log(val, key);
    switch (key) {
      case 'customerName':
        setSearchFilter({ ...searchFilter, customerName: val })
        break;
      case 'phone':
        setSearchFilter({ ...searchFilter, phone: val })
        break;
      case 'email':
        setSearchFilter({ ...searchFilter, email: val })
        break;
      case 'postalCode':
        setSearchFilter({ ...searchFilter, postalCode: val })
        break;
      case 'postalAddress':
        setSearchFilter({ ...searchFilter, postalAddress: val })
        break;
      default:
        break;
    }
    setCurrentPage([0]);
  };
  const downloadExcel = async () => {
    dispatch(START_LOADING());
    try {
      const response = await downloadCustomerList()
      fileDownload(response, 'customerList.xlsx')
    } catch (error) {
      notifyError('Something went wrong!!!')
      console.error('Error downloading Excel file:', error);
    } finally {
      dispatch(STOP_LOADING());
    }
  }

  return (
    <div className="bg-white" style={{ padding: "1rem" }}>
      {/* <h3 className="heading bg-white p-2"> Customer List</h3> */}
      <div className="d-flex justify-content-between m-2">
        <h3 className="heading bg-white p-2"> Customer List</h3>
        <Button variant="primary" type="submit" size="sm" style={{ height: "fit-content" }}
          onClick={downloadExcel}
        >
          <FontAwesomeIcon icon="fa-solid fa-file-excel" /> Download All Data
        </Button>
      </div>
      {(
        <>
          <ReusableTable
            columns={CustomerColumns}
            data={customerList}
            userStatusChange={updateUserStatus}
            actions={actions}
            handleColumnFilter={handleFilter}
          ></ReusableTable>
          <PaginationComponent
            onPageChange={onPageChange}
            itemsPerPage={pageSize}
            totalItems={totalItem}
            pageNo={currentPage}
            onPageSizeChange={setPageSize}
          ></PaginationComponent>
        </>
      )}
      <ShowModal
        modalheading={"Edit User Details"}
        show={showEditModal}
        classname={"add-service-modal"}
        body={<UpdateUserDetails isAdded={newServiceAdded} user={showEditModal ? selectedUser : undefined}></UpdateUserDetails>}
        handleClose={() => {
          setEditModal(false)
        }}
      ></ShowModal>
    </div>
  );
}
