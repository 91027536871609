import { useEffect, useState } from "react";
import { deleteHelperLanguage, getHelperLanguage } from "../../api/adminApi";
import { Container, Button } from "react-bootstrap";
import { helperLanguageSetting } from "../../metaData/ColumnList";
import ShowModal from "../../components/core/Modal";
import ReusableTable from "../../components/core/ReusableTable";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { START_LOADING, STOP_LOADING } from "../../redux/Slice/loaderSlice";
import AddNewHelperLanguage from "../../components/Admin/serviceManagement/AddNewHelperLanguage";

export default function LanguageSettings() {
  const [languageList, setlanguageList] = useState();
  const [showModal, setShowModal] = useState(false);
  const [actions, setActions] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    fetchHelperLanguage();
  }, []);


  async function fetchHelperLanguage() {
    dispatch(START_LOADING());
    let serviceCategory = await getHelperLanguage();
    dispatch(STOP_LOADING());
    setlanguageList(serviceCategory.data);
    console.log(serviceCategory);
    AddActions();
  }
  function AddActions() {
    setActions([
      {
        label: "edit",
        clickAction: openEditModal,
      },
      {
        label: "delete",
        variant: "danger",
        clickAction: openDeleteModal,
      },
    ]);
  }
  const newCatAdded = () => {
    setShowModal(false);
    setShowEditModal(false);
    fetchHelperLanguage();
  };
  const openDeleteModal = async (item) => {
    setShowDeleteModal(true);
    setSelectedLanguage(item);
  };
  const openEditModal = async (item) => {
    setShowEditModal(true);
    setSelectedLanguage(item);
  };

  const deleteLanguage = async () => {
    await deleteHelperLanguage(selectedLanguage.id);
    setShowDeleteModal(false);
    fetchHelperLanguage();
  };
  return (
    <Container className="bg-white" style={{ padding: "1rem" }}>
      <Container className="d-flex justify-content-between mb-2 bg-white p-2">
        <h3 className="heading "> Helper Language List</h3>
        <Button size="sm" onClick={() => setShowModal(true)}>Add New Language</Button>
      </Container>
      <Container className="service-list-table" style={{ width: "40%" }}>
        {/* {languageList?.length && ( */}
        <ReusableTable
          columns={helperLanguageSetting}
          data={languageList}
          actions={actions}
          actionWidth={"300px"}
        ></ReusableTable>
        {/* )} */}
      </Container>
      <ShowModal
        modalheading={showEditModal ? "Edit Language" : "Add New Language"}
        show={showModal || showEditModal}
        body={<AddNewHelperLanguage isAdded={newCatAdded} language={showEditModal ? selectedLanguage : undefined}></AddNewHelperLanguage>}
        handleClose={() => {
          setShowModal(false)
          setShowEditModal(false)
        }
        }
      ></ShowModal>
      <ShowModal
        modalheading="Delete Language"
        show={showDeleteModal}
        extraData={selectedLanguage}
        classname={"add-service-modal"}
        body="Are you sure you want to delete this language"
        handleClose={() => setShowDeleteModal(false)}
        showFooter={true}
        Submit={deleteLanguage}
      ></ShowModal>
    </Container>
  );
}
