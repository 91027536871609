export const validationLength = {
  message: (maxLength) => {
    return `Maximum ${maxLength} characters allowed.`
  },
  user: {
    fName: {
      maxLength: 32
    },
    lName: {
      maxLength: 32
    }
  },
  serviceCategory: {
    name: {
      maxLength: 50
    }
  },
  service: {
    name: {
      maxLength: 50
    },
    sentence: {
      maxLength: 200
    },
    description: {
      maxLength: 1000
    },
    question: {
      name: {
        maxLength: 200
      },
      key: {
        maxLength: 30
      },
      option: {
        maxLength: 50
      }
    },
    supply: {
      name: {
        maxLength: 100
      }
    }
  },
  location: {
    name: {
      maxLength: 50
    },
  },
  skillTrade: {
    reason: {
      maxLength: 500
    },
  },
  helperSetting: {
    tools: {
      name: {
        maxLength: 50
      },
    },
    language: {
      name: {
        maxLength: 50
      },
    },
    vehicle: {
      name: {
        maxLength: 100
      },
      description: {
        maxLength: 1000
      }
    },
    fact: {
      name: {
        maxLength: 200
      },
      description: {
        maxLength: 1000
      }
    }
  }
}