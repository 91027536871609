import { useState, useEffect, useRef } from "react";
import { Button, Container, Form, Image } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { AddNewServiceCategory, EditNewServiceCategory, uploadFile } from "../../../api/adminApi";
import FormLabelStar from "../../core/FormLabelStar";
import { notifySuccess, notifyError, notifyInfo, notifyWarning } from '../../../api/toasterService';
import { START_LOADING, STOP_LOADING } from "../../../redux/Slice/loaderSlice";
import { validationLength } from "../../../metaData/validationLength"

export default function AddNewCategory({ isAdded, category }) {
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    id: "",
    imageUrl: ""
  });

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (category) {
      setFormData({
        name: category.name,
        id: category.id,
        imageUrl: category.imageUrl
      })
    }
  }, [category])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    try {


      event.preventDefault();
      console.log(formData);
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.stopPropagation();
        setValidated(true);
        return;
      }

      let url = ''
      dispatch(START_LOADING());
      if (file || !category) {
        url = await handleFileUpload();
      }

      const serviceCategory = { ...formData, imageUrl: url };
      console.log({ serviceCategory });
      let apiRes = ''
      if (category) {
        if (!url)
          serviceCategory.imageUrl = formData.imageUrl;
        apiRes = await EditNewServiceCategory(serviceCategory);
      } else {
        apiRes = await AddNewServiceCategory(serviceCategory);
      }
      if (apiRes)
        isAdded(true);
      // if (category) {
      //   await EditNewServiceCategory(formData);
      // } else {
      //   await AddNewServiceCategory(formData);
      // }
      // isAdded(true);
    } catch (error) {

    } finally {
      dispatch(STOP_LOADING());
    }
  };

  const handleReset = (e) => {
    setFormData({
      name: "",
      id: "",
      imageUrl: ""
    });
    setFile(null);
    setValidated(false);
  };

  const isImageFile = (file) => {
    const allowedImageTypes = ["image/png", "image/jpeg", "image/jpg", "image/svg+xml"];
    return allowedImageTypes.includes(file.type);
  };

  const handleFileChange = (e) => {
    console.log(e.target.files);
    const selectedFile = e.target.files[0];
    if (selectedFile && isImageFile(selectedFile)) {
      setFile(selectedFile);
    } else {
      fileInputRef.current.value = "";
      notifyWarning("Please select a valid image file.")
    }
  };

  const handleFileUpload = async () => {
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    const formData1 = new FormData();
    formData1.append("file", file);
    const fileResponse = await uploadFile(formData1);
    setFormData({
      ...formData,
      imageUrl: fileResponse.data,
    });
    return fileResponse.data;
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Category Name<FormLabelStar /></Form.Label>
        <Form.Control
          name="name"
          required
          value={formData.name}
          type="text"
          onChange={handleChange}
          placeholder="Enter Category Name"
          maxLength={validationLength.serviceCategory.name.maxLength}
        />
        {formData.name.length > validationLength.serviceCategory.name.maxLength - 1 && (
          <div className="province-name-validate">{validationLength.message(validationLength.serviceCategory.name.maxLength)}</div>
        )}
        <Form.Control.Feedback type="invalid">
          Please enter category name.
        </Form.Control.Feedback>
        {/* <Form.Text className="text-muted">Enter the Category Name</Form.Text> */}
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Image<FormLabelStar /></Form.Label>
        <Form.Control
          ref={fileInputRef}
          accept=".png, .jpeg, .jpg"
          type="file"
          required={!formData.imageUrl}
          name="serviceImage"
          onChange={handleFileChange}
          placeholder="Select File"
        />
        {category &&
          <Image className="modal-img" src={formData.imageUrl}
            style={{ maxWidth: '100%', height: 'auto', marginTop: '10px' }}
          ></Image>}
        {!category &&
          <Form.Control.Feedback type="invalid">
            Please select a image.
          </Form.Control.Feedback>
        }

      </Form.Group>
      <div className="d-flex justify-content-between m-2">
        <Button variant="primary" type="submit" size="sm">
          Submit
        </Button>
        <Button variant="secondary" type="reset" size="sm" onClick={() => handleReset()}>
          Reset
        </Button>
      </div>
    </Form>
  );
}
