import { useEffect, useState } from "react";
import { deleteHelperQuickFact, getHelperQuickFact } from "../../api/adminApi";
import { Container, Button } from "react-bootstrap";
import { helperQuickFactSetting } from "../../metaData/ColumnList";
import ShowModal from "../../components/core/Modal";
import ReusableTable from "../../components/core/ReusableTable";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { START_LOADING, STOP_LOADING } from "../../redux/Slice/loaderSlice";
import AddNewHelperFact from "../../components/Admin/serviceManagement/AddNewHelperFact";

export default function QuickFactSettings() {
  const [helperFactList, sethelperFactList] = useState();
  const [showModal, setShowModal] = useState(false);
  const [actions, setActions] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedFact, setSelectedFact] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    fetchHelperFact();
  }, []);


  async function fetchHelperFact() {
    dispatch(START_LOADING());
    let helperFacts = await getHelperQuickFact();
    dispatch(STOP_LOADING());
    sethelperFactList(helperFacts.data);
    console.log(helperFacts);
    AddActions();
  }
  function AddActions() {
    setActions([
      {
        label: "edit",
        clickAction: openEditModal,
      },
      {
        label: "delete",
        variant: "danger",
        clickAction: openDeleteModal,
      },
    ]);
  }
  const newCatAdded = () => {
    setShowModal(false);
    setShowEditModal(false);
    fetchHelperFact();
  };
  const openDeleteModal = async (item) => {
    setShowDeleteModal(true);
    setSelectedFact(item);
  };
  const openEditModal = async (item) => {
    setShowEditModal(true);
    setSelectedFact(item);
  };

  const deleteFact = async () => {
    await deleteHelperQuickFact(selectedFact.id);
    setShowDeleteModal(false);
    fetchHelperFact();
  };
  return (
    <Container className="bg-white" style={{ padding: "1rem" }}>
      <Container className="d-flex justify-content-between mb-2 bg-white p-2">
        <h3 className="heading "> Helper Quick Fact</h3>
        <Button size="sm" onClick={() => setShowModal(true)}>Add Fact</Button>
      </Container>
      <Container className="service-list-table">
        {/* {helperFactList?.length && ( */}
        <ReusableTable
          columns={helperQuickFactSetting}
          data={helperFactList}
          actions={actions}
          actionWidth={"300px"}
        ></ReusableTable>
        {/* )} */}
      </Container>
      <ShowModal
        modalheading={showEditModal ? "Edit Fact" : "Add New Fact"}
        show={showModal || showEditModal}
        body={<AddNewHelperFact isAdded={newCatAdded} fact={showEditModal ? selectedFact : undefined}></AddNewHelperFact>}
        handleClose={() => {
          setShowModal(false)
          setShowEditModal(false)
        }
        }
      ></ShowModal>
      <ShowModal
        modalheading="Delete Fact"
        show={showDeleteModal}
        extraData={selectedFact}
        classname={"add-service-modal"}
        body="Are you sure you want to delete this fact"
        handleClose={() => setShowDeleteModal(false)}
        showFooter={true}
        Submit={deleteFact}
      ></ShowModal>
    </Container>
  );
}
