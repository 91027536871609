import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
  name: "adminData",
  initialState: {
    categories: [],
    questionOptions: [
      "LOCATION",
      "MULTIPLE_CORRECT",
      "SINGLE_CORRECT",
      "TYPE_IN",
      "SINGLE_CORRECT_AND_TYPE_IN",
      "OTHER_LOCATION"
    ],
    routeList: []
  },
  reducers: {
    SAVE_CATEGORIES: (state, action) => {
      console.log(action);
      return {
        ...state,
        categories: action.payload,
      };
    },
    UPDATE_ROUTELIST: (state, action) => {
      return {
        ...state,
        routeList: action.payload,
      };
    },
  },
});

export const { SAVE_CATEGORIES, UPDATE_ROUTELIST } = adminSlice.actions;
export default adminSlice.reducer;
