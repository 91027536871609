import { useEffect, useState } from "react";
import { deleteHelperVehicle, getHelperVehicle } from "../../api/adminApi";
import { Container, Button } from "react-bootstrap";
import { helperVehicleSetting } from "../../metaData/ColumnList";
import ShowModal from "../../components/core/Modal";
import ReusableTable from "../../components/core/ReusableTable";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { START_LOADING, STOP_LOADING } from "../../redux/Slice/loaderSlice";
import AddNewHelperVehicle from "../../components/Admin/serviceManagement/AddNewHelperVehicle";

export default function VehicleSettings() {
  const [vehicleList, setvehicleList] = useState();
  const [showModal, setShowModal] = useState(false);
  const [actions, setActions] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    fetchHelperVehicle();
  }, []);


  async function fetchHelperVehicle() {
    dispatch(START_LOADING());
    let serviceCategory = await getHelperVehicle();
    dispatch(STOP_LOADING());
    setvehicleList(serviceCategory.data);
    console.log(serviceCategory);
    AddActions();
  }
  function AddActions() {
    setActions([
      {
        label: "edit",
        clickAction: openEditModal,
      },
      {
        label: "delete",
        variant: "danger",
        clickAction: openDeleteModal,
      },
    ]);
  }
  const newCatAdded = () => {
    setShowModal(false);
    setShowEditModal(false);
    fetchHelperVehicle();
  };
  const openDeleteModal = async (item) => {
    setShowDeleteModal(true);
    setSelectedVehicle(item);
  };
  const openEditModal = async (item) => {
    setShowEditModal(true);
    setSelectedVehicle(item);
  };

  const deleteCategory = async () => {
    await deleteHelperVehicle(selectedVehicle.id);
    setShowDeleteModal(false);
    fetchHelperVehicle();
  };
  return (
    <Container className="bg-white" style={{ padding: "1rem" }}>
      <Container className="d-flex justify-content-between mb-2 bg-white p-2">
        <h3 className="heading "> Helper Vehicle List</h3>
        <Button size="sm" onClick={() => setShowModal(true)}>Add New Vehicle</Button>
      </Container>
      <Container className="service-list-table">
        {/* {vehicleList?.length && ( */}
        <ReusableTable
          columns={helperVehicleSetting}
          data={vehicleList}
          actions={actions}
          actionWidth={"300px"}
        ></ReusableTable>
        {/* )} */}
      </Container>
      <ShowModal
        modalheading={showEditModal ? "Edit Category" : "Add New Category"}
        show={showModal || showEditModal}
        body={<AddNewHelperVehicle isAdded={newCatAdded} vehicle={showEditModal ? selectedVehicle : undefined}></AddNewHelperVehicle>}
        handleClose={() => {
          setShowModal(false)
          setShowEditModal(false)
        }
        }
      ></ShowModal>
      <ShowModal
        modalheading="Delete Service"
        show={showDeleteModal}
        extraData={selectedVehicle}
        classname={"add-service-modal"}
        body="Are you sure you want to delete this service"
        handleClose={() => setShowDeleteModal(false)}
        showFooter={true}
        Submit={deleteCategory}
      ></ShowModal>
    </Container>
  );
}
