import { useState, useEffect, useRef } from "react";
import { Button, Container, Form } from "react-bootstrap";
import {
  getCountryList,
  addNewProvince,
  getProvinceByCountryId
} from "../../../api/locationApi";
import { START_LOADING, STOP_LOADING } from "../../../redux/Slice/loaderSlice";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { CLEAR_PROVINCE, UPDATE_PROVINCE } from "../../../redux/Slice/locationSlice";
import FormLabelStar from "../../core/FormLabelStar";
import { validationLength } from "../../../metaData/validationLength"

const intialForm = {
  "id": 0,
  "provinces": [
  ],
  "countryId": null
}

export default function AddNewProvince({ isAdded }) {
  const provinceList = useSelector((state) => state.location).filteredProvince;
  const mySelectRef = useRef(null);
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(intialForm);
  const [countryList, setcountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [openAddOption, setOpenAddOption] = useState(true);
  const [currentProvince, setcurrentProvince] = useState("");
  const [isProvinceValid, setIsProvinceValid] = useState(true);
  const [isProvinceLenValid, setIsProvinceLenValid] = useState(true);

  useEffect(() => {
    fetchCountry();
  }, []);

  async function fetchCountry() {
    let country = await getCountryList();
    if (country?.data) {
      setcountryList(country.data);
    }
  }


  const handleChange = (e) => {
    setSelectedCountry(e.target.value)

    setFormData({
      ...formData,
      ["countryId"]: e.target.value
    });
  };

  const provinceHandleChange = (e) => {
    const province = e.target.value;
    setcurrentProvince(province)
    if (province.trim("") !== "")
      setIsProvinceValid(true)
    // setFormData({
    //   ...formData,
    //   ["provinces"]: [...formData.provinces, province]
    // });
  };

  const categorizedProvincesList = (provinces) => {
    return provinces.reduce((acc, province) => {
      const { countryId } = province;
      if (!acc[countryId]) {
        acc[countryId] = [];
      }
      acc[countryId].push(province);
      return acc;
    }, {});
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);
    const form = event.currentTarget;

    let isValidated = true;
    if (formData.provinces.length === 0 || openAddOption) {
      setIsProvinceLenValid(false)
      isValidated = false;
    } else {
      setIsProvinceLenValid(true)
    }
    if (!isValidated) {
      setValidated(true);
      return;
    }

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setIsProvinceValid(false)
      setValidated(true);
      return;
    }
    try {
      dispatch(START_LOADING());
      await addNewProvince(formData);
      dispatch(CLEAR_PROVINCE())
      const categorizedProvinces = categorizedProvincesList(provinceList);
      const disableProvinceId = Object.keys(categorizedProvinces).map(async (countryId) => {
        dispatch(START_LOADING());
        let provinces = await getProvinceByCountryId(countryId);
        provinces = provinces.data.map((province) => { return { ...province, countryId, isChecked: false } })
        dispatch(UPDATE_PROVINCE(provinces));
        dispatch(STOP_LOADING());
        return {
          countryId
        }
      })
      isAdded(true);
    } catch (error) {

    } finally {
      dispatch(STOP_LOADING());
    }
  };

  const handleReset = (e) => {
    setFormData(intialForm);
    setSelectedCountry("");
    setOpenAddOption(true)
    setcurrentProvince("")
    setTimeout(() => {
      console.log({ selectedCountry });
      mySelectRef.current.selectedIndex = 0;

    }, 0)
    setValidated(false); //
  };

  const saveProvince = () => {
    const form = document.getElementById("yourForm");
    if (currentProvince.trim("") !== "")
      setIsProvinceValid(true)
    else
      setIsProvinceValid(false)
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    if (currentProvince === "") {
      alert("Please enter province")
      return
    } else {
      setFormData({
        ...formData,
        provinces: [...formData.provinces, currentProvince],
      });
      setOpenAddOption(false);
      setcurrentProvince("")
      setIsProvinceLenValid(true)
    }
  };

  const handleRemove = (index) => {
    // Copy the current options array
    const updatedOptions = [...formData.provinces];

    // Remove the option at the specified index
    updatedOptions.splice(index, 1);

    // Update the state with the new options array
    setFormData({
      ...formData,
      provinces: updatedOptions,
    });
    if (updatedOptions.length === 0)
      setOpenAddOption(true)
  };

  const handleAdd = () => {
    if (formData.provinces.length > 0)
      setOpenAddOption(!openAddOption)

  };
  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit} id="yourForm">
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Country<FormLabelStar /></Form.Label>
        <Form.Select
          size="sm"
          aria-label=" Select Country"
          name="country"
          onChange={handleChange}
          value={selectedCountry}
          required
          ref={mySelectRef}
        >
          <option value="" disabled selected>Select</option>
          {countryList?.map((c) => (
            <option value={c.id}
              key={c.id}
            >{c.countryName}</option>
          ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          Please select a country.
        </Form.Control.Feedback>
      </Form.Group>
      {/* <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Province Name</Form.Label>
        <Form.Control
          name="name"
          required
          value={formData.name}
          type="text"
          onChange={provinceHandleChange}
          placeholder="Enter Province Name"
        />
        <Form.Control.Feedback type="invalid">
          Please enter a province name.
        </Form.Control.Feedback>
      </Form.Group> */}
      <Form.Group className="mb-3" controlId="formBasicPassword"
      >
        <Form.Label >Province<FormLabelStar /></Form.Label>{" "}
        <a
          className="link-btn"
          style={{ marginLeft: "20px", marginBottom: "15px" }}
          onClick={handleAdd}
        >
          Add
        </a>
        {formData.provinces.map((o, index) => (
          <div className="d-flex justify-content-between mb-3" key={index}>
            <Form.Control
              size="sm"
              aria-label=" Select Category"
              name="option"
              style={{ marginRight: "15px" }}
              value={o}
              disabled
            />
            <Button
              className="link-btn"
              onClick={() => handleRemove(index)}
              size="sm"
            >
              Remove
            </Button>
          </div>
        ))}
      </Form.Group>
      <Form.Group className="" controlId="formBasicPassword"
      >
        {openAddOption && (
          <div className="save d-flex">
            <Form.Control
              size="sm"
              aria-label="add province"
              name="province"
              style={{ marginRight: "15px" }}
              onChange={provinceHandleChange}
              value={currentProvince}
              required
              maxLength={validationLength.location.name.maxLength}
            />
            <Button onClick={saveProvince} size="sm" style={{ width: "73px", marginLeft: "15px" }}>{" "}Save  </Button>
          </div>
        )}
        {currentProvince.length > validationLength.location.name.maxLength - 1 && (
          <div className="province-name-validate">{validationLength.message(validationLength.location.name.maxLength)}</div>
        )}
        {!isProvinceValid && <div className="province-name-validate">
          Please enter a province name.
        </div>}
        {!isProvinceLenValid && <div className="province-name-validate">
          Please save province.
        </div>}
      </Form.Group>
      <div className="d-flex justify-content-between m-2">
        <Button variant="primary" type="submit" size="sm">
          Submit
        </Button>
        <Button variant="secondary" type="reset" size="sm"
          onClick={() => handleReset()}
        >
          Reset
        </Button>
      </div>
    </Form >
  );
}
