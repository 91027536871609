import { useEffect, useState } from "react";
import { deleteHelperTool, getHelperTool } from "../../api/adminApi";
import { Container, Button } from "react-bootstrap";
import { helperToolSetting } from "../../metaData/ColumnList";
import ShowModal from "../../components/core/Modal";
import ReusableTable from "../../components/core/ReusableTable";
import { useDispatch } from "react-redux";
import { START_LOADING, STOP_LOADING } from "../../redux/Slice/loaderSlice";
import AddNewHelperTool from "../../components/Admin/serviceManagement/AddNewHelperTool";

export default function ToolsSettings() {
  const [toolSettingList, settoolSettingList] = useState();
  const [showModal, setShowModal] = useState(false);
  const [actions, setActions] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTool, setSelectedTool] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    fetchHelperTools();
  }, []);


  async function fetchHelperTools() {
    dispatch(START_LOADING());
    let toolSetting = await getHelperTool();
    dispatch(STOP_LOADING());
    settoolSettingList(toolSetting.data);
    console.log(toolSetting);
    AddActions();
  }
  function AddActions() {
    setActions([
      {
        label: "edit",
        clickAction: openEditModal,
      },
      {
        label: "delete",
        variant: "danger",
        clickAction: openDeleteModal,
      },
    ]);
  }
  const newCatAdded = () => {
    setShowModal(false);
    setShowEditModal(false);
    fetchHelperTools();
  };
  const openDeleteModal = async (item) => {
    setShowDeleteModal(true);
    setSelectedTool(item);
  };
  const openEditModal = async (item) => {
    setShowEditModal(true);
    setSelectedTool(item);
  };

  const deleteTool = async () => {
    await deleteHelperTool(selectedTool.id);
    setShowDeleteModal(false);
    fetchHelperTools();
  };
  return (
    <Container className="bg-white" style={{ padding: "1rem" }}>
      <Container className="d-flex justify-content-between mb-2 bg-white p-2">
        <h3 className="heading "> Helper Tool List</h3>
        <Button onClick={() => setShowModal(true)} size="sm">Add New Tool</Button>
      </Container>
      <Container className="service-list-table" style={{ width: "40%" }}>
        {/* {toolSettingList?.length && ( */}
        <ReusableTable
          columns={helperToolSetting}
          data={toolSettingList}
          actions={actions}
          actionWidth={"300px"}
        ></ReusableTable>
        {/* )} */}
      </Container>
      <ShowModal
        modalheading={showEditModal ? "Edit Tool" : "Add New Tool"}
        show={showModal || showEditModal}
        body={<AddNewHelperTool isAdded={newCatAdded} tool={showEditModal ? selectedTool : undefined}></AddNewHelperTool>}
        handleClose={() => {
          setShowModal(false)
          setShowEditModal(false)
        }
        }
      ></ShowModal>
      <ShowModal
        modalheading="Delete Tool"
        show={showDeleteModal}
        extraData={selectedTool}
        classname={"add-service-modal"}
        body="Are you sure you want to delete this tool"
        handleClose={() => setShowDeleteModal(false)}
        showFooter={true}
        Submit={deleteTool}
      ></ShowModal>
    </Container>
  );
}
