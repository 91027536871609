import { useState, useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { uploadFile, } from "../../../api/adminApi";
import { UploadPostalDataUrl } from "../../../api/locationApi";
import { Image } from "react-bootstrap";
import { notifySuccess, notifyError, notifyInfo, notifyWarning } from '../../../api/toasterService';
import FormLabelStar from "../../core/FormLabelStar";

const InitialForm = {
  postalFile: "",
};
export default function UploadPostalData({ isAdded }) {
  const [formData, setFormData] = useState(InitialForm);
  const [file, setFile] = useState(null);
  const [validated, setValidated] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    console.log(e.target.files);
    // setFile(e.target.files[0]);
    const selectedFile = e.target.files[0];
    if (selectedFile && isCSVFile(selectedFile)) {
      setFile(selectedFile);
    } else {
      fileInputRef.current.value = "";
      notifyWarning("Please select a valid CSV file.")
      // alert("Please select a valid CSV file.");
    }
  };

  const isCSVFile = (file) => {
    return file.type === "text/csv" || file.name.endsWith(".csv");
  };

  const handleFileUpload = async () => {
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    const formData1 = new FormData();
    formData1.append("file", file);
    const fileResponse = await uploadFile(formData1);
    setFormData({
      ...formData,
      postalFile: fileResponse.data,
    });
    return fileResponse.data;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Validate the form
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    if (file) {
      // const fileDetail = { ...formData, postalFile: url };
      // console.log({ fileDetail });
      console.log({ file });
      const formData1 = new FormData();
      formData1.append("postalFile", file);
      // formData1.append("postalFile", file.name);
      const response = await UploadPostalDataUrl(formData1)
      if (response.statusCode !== 200)
        // alert('Something went wrong')
        notifyError(response.message)
      else {
        isAdded(true);
        // notifySuccess(response.message)
      }
    } else {
      alert("Please select a file to upload.");
    }

  };
  const handleReset = (e) => {
    setFile(null);
  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>CSV File<FormLabelStar /></Form.Label>
          <Form.Control
            ref={fileInputRef}
            accept=".csv"
            type="file"
            required={!formData.imageUrl}
            name="uploadFile"
            onChange={handleFileChange}
            placeholder="Select CSV File"
          />
          <Form.Control.Feedback type="invalid">
            Please select a csv file.
          </Form.Control.Feedback>

        </Form.Group>
        <div className="d-flex justify-content-between m-2">
          <Button variant="primary" type="submit" size="sm">
            Submit
          </Button>
          <Button variant="secondary" type="reset" size="sm"
            onClick={handleReset}
          >
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
}
