import { Accordion, Button, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getTaskAnswers, getTaskItems, getTasks } from "../../api/adminApi";
import { useParams, useSearchParams } from "react-router-dom";
import ReusableTable from "../../components/core/ReusableTable";
import { useDispatch } from "react-redux";
import { START_LOADING, STOP_LOADING } from "../../redux/Slice/loaderSlice";
import { notifySuccess, notifyError, notifyInfo, notifyWarning } from '../../api/toasterService';
import { Avatar, Box } from "@mui/material";
import { TaskItemsColumns } from "../../metaData/ColumnList";
import { formatDate } from '../../utils/dateUtils';
import { formatPriceInUSD } from '../../utils/priceUtils';
import DetailsCard from "../../components/core/DetailsCard";
import MyBreadCrumb from "../../components/core/MyBreadCrumb";
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { UPDATE_ROUTELIST } from "../../redux/Slice/adminSlice";

export default function TaskDetails() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [answers, setAnswers] = useState([]);
  const [items, setItems] = useState([]);
  const [task, setTask] = useState();
  const [serviceDetails, setServiceDetails] = useState();
  const [servicePrice, setServicePrice] = useState({});
  const [displayedSupplyList, setDisplayedSupplyList] = useState([]);
  const [actions, setActions] = useState([]);
  const dispatch = useDispatch();

  const [showSupplyEditModal, setSupplyEditModal] = useState(false);
  const [showSupplyAddModal, setSupplyAddModal] = useState(false);
  const [selectedSupply, setSelectedSupply] = useState();
  const [showSupplyDeleteModal, setSupplyDeleteModal] = useState(false);



  useEffect(() => {
    // try {
    //   const params = {};
    //   for (const entry of searchParams.entries()) {
    //     const [param, value] = entry;
    //     params[param] = value;
    //   }
    //   const taskDetails = JSON.parse(params.data)
    //   console.log({ taskDetails });
    //   setTask({ ...taskDetails })

    // } catch (error) {
    //   notifyError('Something went wrong')
    // }
    fetchTasks()
  }, []);

  async function fetchTasks() {
    dispatch(START_LOADING());
    try {
      const response = await getTasks(0, 1, `&taskId=${id}`);
      console.log({ response });
      if (response.data.content.length > 0)
        setTask({ ...response.data.content[0] });
      else
        notifyError('Something went wrong')
    } catch (error) {
      // console.error(error);
      notifyError('Something went wrong')
      // return;
    }
    dispatch(STOP_LOADING());
  }


  const getAnswers = async (id) => {
    dispatch(START_LOADING());
    const answersData = await getTaskAnswers(id);
    setAnswers(answersData?.data?.answers);
    dispatch(STOP_LOADING());
  };

  const getItems = async (id) => {
    dispatch(START_LOADING());
    const itemsData = await getTaskItems(id);
    setItems(itemsData?.data);
    dispatch(STOP_LOADING());
  };

  const handleSelectedTab = async (tabName) => {
    switch (tabName) {
      case "items":
        await getItems(task?.taskId);
        break;
      case "answers":
        await getAnswers(task?.taskId);
        break;
      default:
        break;
    }
  };

  const routeList = [
    {
      href: '/admin/dashboard',
      title: (
        <>
          <span>Dashboard</span>
        </>
      ),
    },

    {
      href: '/admin/tasks',
      title: (
        <>
          <span>Tasks</span>
        </>
      ),
    },
    {
      title: 'Task Details',
    },
  ]
  dispatch(UPDATE_ROUTELIST(routeList))


  return (
    <>
      {task && (
        <div className="bg-white p-4 service-question">
          <div className="mb-4">
            {/* <MyBreadCrumb routeList={routeList} title={'Task Details'} /> */}
            <h3 className="text-center"> Task Details</h3>
          </div>
          <hr></hr>
          <Tabs
            defaultActiveKey="details"
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={handleSelectedTab}
          >
            <Tab eventKey="details" title="Task Details">
              <div class="container" >
                <div class="row" >
                  <DetailsCard title={'Service Name'} value={task.serviceName} length={25} />
                  <DetailsCard title={'Description'} value={task.description} length={25} />
                  <DetailsCard title={'Scheduled Start Time'} value={formatDate(task.scheduledTime)} length={25} />
                  <DetailsCard title={'Scheduled End Time'} value={formatDate(task.scheduledEndTime)} length={25} />
                </div>
                <div class="row" >
                  <DetailsCard title={'Customer Name'} value={task.customerName} image={task.customerImageUrl} length={25} />
                  <DetailsCard title={'Customer Email'} value={task.customerEmail} length={25} />
                  <DetailsCard title={'Helper Name'} value={task.helperName} image={task.helperImageUrl} length={25} />
                  <DetailsCard title={'Helper Email'} value={task.taskerEmail} length={25} />
                </div>
                <div class="row" >
                  <DetailsCard title={'Task Start Time'} value={formatDate(task.startTime)} length={25} />
                  <DetailsCard title={'Task End Time'} value={formatDate(task.endTime)} length={25} />
                  <DetailsCard title={'Price'} value={formatPriceInUSD(task.price)} length={25} />
                  <DetailsCard title={'Status'} value={task.taskStatus} length={25} color={
                    task.taskStatus == 'CUSTOMER_CANCELLED' || task.taskStatus == 'HELPER_CANCELLED'
                      ? '#d32f2f'
                      : task.taskStatus == 'TASK_STARTED' || task.taskStatus == 'HIRING_INITIATED' ||
                        task.taskStatus == 'HIRING_INITIATED'
                        ? '#f57c00'
                        : '#388e3c'
                  }
                  />
                </div>
                <div class="row" >
                  <DetailsCard title={'Address'} value={task.address} length={25} />
                  <DetailsCard title={'Latitude'} value={task.latitude} length={25} />
                  <DetailsCard title={'Longitude'} value={task.longitude} length={25} />
                  <DetailsCard title={'Two Hour Required'} value={task.isTwoHourRequired ? 'Yes' : 'No'} length={25} />
                </div>
              </div>
              {/* <div class="container">
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <div className="column-name">
                          <label>Service Name : </label>
                        </div>
                      </div>
                      <div class="col">
                        <div className="column-name">
                          <span>{task.serviceName}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <div className="column-name">
                          <label>Description : </label>
                        </div>
                      </div>
                      <div class="col">
                        <div className="column-name">
                          <span>{task.description}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <div className="column-name">
                          <label>Customer Name : </label>
                        </div>
                      </div>
                      <div class="col">
                        <div className="column-name">
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >

                            <Avatar
                              alt={task.customerName}
                              src={task.customerImageUrl}
                              style={{ marginRight: "10px" }}

                            />

                            <span>{task.customerName}</span>
                          </Box>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <div className="column-name">
                          <label>Customer Email : </label>
                        </div>
                      </div>
                      <div class="col">
                        <div className="column-name">
                          <span>{task.customerEmail}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <div className="column-name">
                          <label>Helper Name : </label>
                        </div>
                      </div>
                      <div class="col">
                        <div className="column-name">
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Avatar
                              alt={task.helperName}
                              src={task.helperImageUrl}
                              style={{ marginRight: "10px" }}
                            />
                            <span>{task.helperName}</span>
                          </Box>
                          {/* <img
                        alt="avatar"
                        height={30}
                        src={task.helperImageUrl}
                        loading="lazy"
                        style={{ borderRadius: '50%', marginRight: "10px" }}
                      />
                      <span>{task.helperName}</span> 
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <div className="column-name">
                          <label>Helper Email : </label>
                        </div>
                      </div>
                      <div class="col">
                        <div className="column-name">
                          <span>{task.taskerEmail}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <div className="column-name">
                          <label>Scheduled Start Time : </label>
                        </div>
                      </div>
                      <div class="col">
                        <div className="column-name">
                          <span>{formatDate(task.scheduledTime)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <div className="column-name">
                          <label>Scheduled End Time : </label>
                        </div>
                      </div>
                      <div class="col">
                        <div className="column-name">
                          <span>{formatDate(task.scheduledEndTime)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <div className="column-name">
                          <label>Task Start Time : </label>
                        </div>
                      </div>
                      <div class="col">
                        <div className="column-name">
                          <span>{formatDate(task.startTime)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <div className="column-name">
                          <label>Task End Time : </label>
                        </div>
                      </div>
                      <div class="col">
                        <div className="column-name">
                          <span>{formatDate(task.endTime)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <div className="column-name">
                          <label>Price : </label>
                        </div>
                      </div>
                      <div class="col">
                        <div className="column-name">
                          <span>{formatPriceInUSD(task.price)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <div className="column-name">
                          <label>Status : </label>
                        </div>
                      </div>
                      <div class="col">
                        <div className="column-name"
                          style={{
                            color:
                              task.taskStatus == 'CUSTOMER_CANCELLED' || task.taskStatus == 'HELPER_CANCELLED'
                                ? '#d32f2f'
                                : task.taskStatus == 'TASK_STARTED' || task.taskStatus == 'HIRING_INITIATED' ||
                                  task.taskStatus == 'HIRING_INITIATED'
                                  ? '#f57c00'
                                  : '#388e3c',
                          }}>
                          <span>{task.taskStatus}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <div className="column-name">
                          <label>Two Hour Required : </label>
                        </div>
                      </div>
                      <div class="col">
                        <div className="column-name">
                          <span>{task.isTwoHourRequired ? 'Yes' : 'No'}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <div className="column-name">
                          <label>Address : </label>
                        </div>
                      </div>
                      <div class="col">
                        <div className="column-name">
                          <span>{task.address}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <div className="column-name">
                          <label>Latitude : </label>
                        </div>
                      </div>
                      <div class="col">
                        <div className="column-name">
                          <span>{task.latitude}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <div className="column-name">
                          <label>Longitude : </label>
                        </div>
                      </div>
                      <div class="col">
                        <div className="column-name">
                          <span>{task.longitude}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="column-name">
                <Row>
                  <Col>
                    <label>Service Name : </label>
                    <span>{task.serviceName}</span>
                  </Col>
                  <Col className="d-flex flex-row-reverse">
                  </Col>
                </Row>
              </div>
              <div className="column-name">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                  }}
                >
                  <label>Customer Name: </label>
                  <img
                    alt="avatar"
                    height={30}
                    src={task.customerImageUrl}
                    loading="lazy"
                    style={{ borderRadius: '50%' }}
                  />
                  <span>{task.customerName}</span>
                </Box>
              </div>
              <div className="column-name">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                  }}
                >
                  <label>Customer Name: </label>
                  <img
                    alt="avatar"
                    height={30}
                    src={task.helperImageUrl}
                    loading="lazy"
                    style={{ borderRadius: '50%' }}
                  />
                  <span>{task.helperName}</span>
                </Box>
              </div>
              <div className="column-name">
                <label>Scheduled Start Time: </label>
                <span>{task.scheduledTime}</span>
              </div>
              <div className="column-name">
                <label>Scheduled End Time: </label>
                <span>{task.scheduledEndTime}</span>
              </div>
              <div className="column-name">
                <label>Task Start Time: </label>
                <span>{task.startTime}</span>
              </div>
              <div className="column-name">
                <label>Task End Time: </label>
                <span>{task.endTime}</span>
              </div>
              <div className="column-name">
                <label>Price: </label>
                <span>{task.price}</span>
              </div>
              <div className="column-name">
                <label>Status: </label>
                <span>{task.taskStatus}</span>
              </div>
              <div className="column-name">
                <label>Description: </label>
                <span>{task.description}</span>
              </div>
              <div className="column-name">
                <label>Two Hour Required: </label>
                <span>{task.isTwoHourRequired ? 'TRUE' : 'FALSE'}</span>
              </div>
              <div className="column-name">
                <label>Address: </label>
                <span>{task.address}</span>
              </div>
              <div className="column-name">
                <label>Latitude: </label>
                <span>{task.latitude}</span>
              </div>
              <div className="column-name">
                <label>Longitude: </label>
                <span>{task.longitude}</span>
              </div> */}



            </Tab>
            <Tab eventKey="items" title="Items">
              <div class="container">
                <div class="row">
                  <div class="col-2">
                    <div className="column-name">
                      <label>Service Name : </label>
                    </div>
                  </div>
                  <div class="col-10">
                    <div className="column-name">
                      <span>{task.serviceName}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2">
                    <div className="column-name">
                      <label>Description : </label>
                    </div>
                  </div>
                  <div class="col-10">
                    <div className="column-name">
                      <span>{task.description}</span>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="column-name">
                <Row>
                  <Col>
                    <label>Service Name : </label>
                    <span>{task.serviceName}</span>
                  </Col>
                  <Col className="d-flex flex-row-reverse">
                  </Col>
                </Row>
              </div>
              <div className="column-name">
                <label>Description: </label>
                <span>{task.description}</span>
              </div> */}
              {/* {items?.length == 0 ? (
                <>
                  <div className="d-flex justify-content-center">
                    {" "}
                    No Items to Show
                  </div>
                </>
              ) : ( */}
              <ReusableTable
                columns={TaskItemsColumns}
                data={items}
              // actions={actions}
              ></ReusableTable>
              {/* )} */}
            </Tab>
            <Tab eventKey="answers" title="Questions">
              <div class="container">
                <div class="row">
                  <div class="col-2">
                    <div className="column-name">
                      <label>Service Name : </label>
                    </div>
                  </div>
                  <div class="col-10">
                    <div className="column-name">
                      <span>{task.serviceName}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2">
                    <div className="column-name">
                      <label>Description : </label>
                    </div>
                  </div>
                  <div class="col-10">
                    <div className="column-name">
                      <span>{task.description}</span>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="column-name">
                <label>Service Name : </label>
                <span>{task.serviceName}</span>
              </div>
              <div className="column-name">
                <label>Description: </label>
                <span>{task.description}</span>
              </div> */}
              <div className="column-name" style={{ paddingLeft: "10px" }}>
                <div className="d-flex" style={{ marginBottom: "20px" }}>
                  {" "}
                  <label>Questions: </label>{" "}
                  {/* <Button
                    variant="primary"
                    style={{ marginBottom: "10px" }}
                    onClick={openNewQuestionModel}
                  >
                    Add New Question
                  </Button> */}
                </div>
                {answers?.length === 0 ? (
                  <>
                    <div className="d-flex justify-content-center">
                      {" "}
                      No Questions to Show
                    </div>
                  </>
                ) : (
                  <Accordion>
                    {answers.map((q, i) => (
                      <Accordion.Item eventKey={i} key={i}>
                        <Accordion.Header>
                          <div className="d-flex w-100 justify-content-between">
                            {" "}
                            <label>{q.question} </label>{" "}
                            {/* <div className="options">
                              <a
                                className="edit-btn mr-2"
                              // onClick={(e) => editQuestion(e, q)}
                              >
                                Edit
                              </a>
                              <a className="delete-btn"
                              // onClick={(e) => openDeleteModal(e, q)}
                              >Delete</a>
                            </div> */}
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="column-name">
                            <label>Key : </label>
                            <span>{q.key}</span>
                          </div>
                          <div className="column-name">
                            <label>Question Type: </label>
                            <span>{q.questionType}</span>
                          </div>
                          <div className="column-name d-flex">
                            <label>Answers:</label>
                            {q.answerOptions.length > 0 ? (
                              <ul className="question-option">
                                {q.answerOptions.map((o, i) => (
                                  <li key={i}>{o.option}</li>
                                ))}
                              </ul>
                            ) : (
                              <span> No Options Present</span>
                            )}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                )}

              </div>
            </Tab>

          </Tabs>
        </div >
      )
      }
    </>
  );
}
