import { Avatar, Box, Typography } from '@mui/material';
import { formatDate, formatDateOnly } from '../utils/dateUtils';
import { formatPriceInUSD } from '../utils/priceUtils';


const taskStatusOptions = [
  {
    label: 'All', value: ''
  },
  { label: 'CUSTOMER_CANCELLED', value: 'CUSTOMER_CANCELLED' },
  { label: 'HELPER_CANCELLED', value: 'HELPER_CANCELLED' },
  { label: 'AUTO_CANCELLED', value: 'AUTO_CANCELLED' },
  // { label: 'CANCELLED', value: 'CANCELLED' },
  // { label: 'HIRING_INITIATED', value: 'HIRING_INITIATED' },
  { label: 'HIRED', value: 'HIRED' },
  { label: 'TASK_STARTED', value: 'TASK_STARTED' },
  { label: 'CUSTOMER_RESCHEDULED', value: 'CUSTOMER_RESCHEDULED' },
  { label: 'INVOICED', value: 'INVOICED' },
  { label: 'PAYOUT', value: 'PAYOUT' },
  // { label: 'COMPLETED', value: 'COMPLETED' },
];

const paymentStatusOptions = [
  {
    label: 'All', value: ''
  },
  { label: 'SUCCESS', value: 'SUCCESS' },
  // { label: 'PENDING', value: 'PENDING' },
  // { label: 'INITIATED', value: 'INITIATED' },
  // { label: 'CANCELLED', value: 'CANCELLED' },
  // { label: 'REFUNDED', value: 'REFUNDED' },
  { label: 'HOLD', value: 'HOLD' },
  { label: 'CHARGED', value: 'CHARGED' },
  { label: 'INVOICED', value: 'INVOICED' },
  { label: 'PAYOUT', value: 'PAYOUT' },
]

const payoutStatusOptions = [
  {
    label: 'All', value: ''
  },
  { label: 'PAID', value: 'PAID' },
  { label: 'INITIATED', value: 'INITIATED' },
  { label: 'CANCELLED', value: 'CANCELLED' },
]

const paymentTypeOptions = [
  {
    label: 'All', value: ''
  },
  { label: 'ONBOARDING', value: 'ONBOARDING' },
  { label: 'HIRING', value: 'HIRING' },
]

const skillTradeStatusOptions = [
  {
    label: 'All', value: ''
  },
  { label: 'VERIFIED', value: 'VERIFIED' },
  { label: 'REJECTED', value: 'REJECTED' },
  { label: 'SUBMITTED', value: 'SUBMITTED' },
]

export const CustomerColumns = [
  {
    name: "S. No",
    keyName: "SerialNo",
    className: "sr-column"
  },
  // {
  //   name: "User Name",
  //   keyName: "userName",
  //   isSortable: true,
  //   isFilterable: true,
  // },
  {
    name: "Name",
    keyName: "customerName",
    isSortable: true,
    isFilterable: true,
    width: "150px",
  },

  {
    name: "phone",
    keyName: "phone",
    isFilterable: true,
  },
  {
    name: "Email",
    keyName: "email",
    isSortable: true,
    isFilterable: true,
    width: "250px"
  },
  {
    name: "Postal Code",
    keyName: "postalCode",
    isFilterable: true,
  },
  {
    name: "Address",
    keyName: "postalAddress",
    isFilterable: true,
    width: "250px"
  },
  {
    name: "Created Time",
    keyName: "createdAt",
    isFilterable: false,
    width: "250px",
    className: "text-center",
    customFunction: (item) => {
      const [datePart, timePart] = item?.createdAt.split(' ');
      const [day, month, year] = datePart.split('/');
      let [hour, minute] = timePart.split(':');
      if (timePart.endsWith("PM")) {
        hour = (parseInt(hour, 10) + 12) % 24;
      }
      const date = new Date(`${year}-${month}-${day}T${hour}:${minute}`);
      const isoDateString = date.toISOString();
      console.log(isoDateString);
      return formatDate(isoDateString)
    }
  },
  // {
  //   name: "Role",
  //   keyName: "role",
  // },
  {
    name: "Verified",
    keyName: "isVerified",
    className: "text-center",
  },
  {
    name: "Deleted",
    keyName: "isDeleted",
    className: "text-center",
  },
  {
    name: "Active",
    keyName: "isActive",
    className: "text-center",
  },
];

export const TaskerColumns = [
  {
    name: "S. No",
    keyName: "SerialNo",
    className: "sr-column"
  },
  {
    name: "Name",
    keyName: "customerName",
    isSortable: true,
    isFilterable: true,
    width: "150px",
  },

  {
    name: "phone",
    keyName: "phone",
    isFilterable: true,
  },
  {
    name: "Email",
    keyName: "email",
    isSortable: true,
    isFilterable: true,
    width: "250px"
  },
  {
    name: "Postal Code",
    keyName: "postalCode",
    isFilterable: true,
  },
  {
    name: "Address",
    keyName: "postalAddress",
    width: "250px",
    isFilterable: true,
  },
  {
    name: "Created Time",
    keyName: "createdAt",
    isFilterable: false,
    width: "250px",
    className: "text-center",
    customFunction: (item) => {
      const [datePart, timePart] = item?.createdAt.split(' ');
      const [day, month, year] = datePart.split('/');
      let [hour, minute] = timePart.split(':');
      if (timePart.endsWith("PM")) {
        hour = (parseInt(hour, 10) + 12) % 24;
      }
      const date = new Date(`${year}-${month}-${day}T${hour}:${minute}`);
      const isoDateString = date.toISOString();
      console.log(isoDateString);
      return formatDate(isoDateString)
    }
  },
  // {
  //   name: "Role",
  //   keyName: "role",
  // },
  {
    name: "Verified",
    keyName: "isVerified",
    className: "text-center",
  },
  {
    name: "Deleted",
    keyName: "isDeleted",
    className: "text-center",
  },
  {
    name: "Active",
    keyName: "isActive",
    className: "text-center",
  },
  {
    name: "KYC Status",
    keyName: "kycStatus",
    className: "text-center",
    customFunction: (item) => {
      return item?.kycStatus ? item?.kycStatus : 'NOT SUBMITTED'
    }
  },
  {
    name: "Skill Activated",
    keyName: "isActiveSkill",
    className: "text-center",
    customFunction: (item) => {
      return item?.isActiveSkill ? 'Yes' : 'No'
    }
  },
  {
    name: "Map added",
    keyName: "isMapAdded",
    className: "text-center",
    customFunction: (item) => {
      return item?.isMapAdded ? 'Yes' : 'No'
    }
  },
  {
    name: "Paused",
    keyName: "isPause",
    className: "text-center",
    customFunction: (item) => {
      return item?.isPause ? 'Yes' : 'No'
    }
  },
];

export const ServiceColumns = [
  {
    name: "S. No",
    keyName: "SerialNo",
    className: "sr-column",
    width: "20px"
  },
  {
    name: "Name",
    keyName: "name",
    isSortable: true,
    isFilterable: true,
    width: "200px"
  },
  // {
  //   name: "Scope Of Work",
  //   keyName: "scopeOfWork",
  //   isFilterable: true,
  //   multiline: true,
  // },
  // {
  //   name: "Recommended Tools",
  //   keyName: "recommendedTools",
  //   multiline: true,
  // },

  {
    name: "Description",
    keyName: "description",
  },
  {
    name: "Booking Count",
    keyName: "bookingCount",
    width: "20px",
    className: "text-center",
    isSortable: true,
  },
  {
    name: "In Demand",
    keyName: "isInDemand",
    width: "20px",
    className: "text-center",
  },
  {
    name: "Is Skill Trade",
    keyName: "isSkillTrade",
    width: "20px",
    className: "text-center",
    customFunction: (item) => {
      return item?.isSkillTrade ? 'Yes' : 'No'
    }
  },
  {
    name: "Image",
    keyName: "imageUrl",
    width: "20px",
    className: "text-center",
  },
  // {
  //   name: "View",
  //   keyName: "",
  //   customContent: {
  //     label: "view",
  //     type: "link",
  //     path: "/admin/services/serviceDetails/:param",
  //     toReplaceWithKey: "id",
  //   },
  //   multiline: false,
  //   custom: true,
  //   width: "50px"
  // },
];
export const serviceCategory = [
  {
    name: "S. No.",
    keyName: "SerialNo",
    width: "20px",
    className: "sr-column"
  },
  {
    name: "Service Category Name",
    keyName: "name",
    isSortable: true,
    isFilterable: true,
  },
  // {
  //   name: "service Name",
  //   keyName: "name",
  // },
  // {
  //   name: "Description",
  //   keyName: "description",
  // },
  {
    name: " Image",
    keyName: "imageUrl",
  },
  // {
  //   name: " Scope Of Work",
  //   keyName: "scopeOfWork",
  // },
  // {
  //   name: " Recommended Tools",
  //   keyName: "recommendedTools",
  // },
];

export const SupplyColumns = [
  {
    name: "S. No.",
    keyName: "SerialNo",
    width: "20px",
    className: "sr-column"
  },
  {
    name: "Supply Name",
    keyName: "name",
    isSortable: true,
    isFilterable: true,
  },
  {
    name: "Mandatory",
    keyName: "isMandatory",
    isSortable: false,
    isFilterable: false,
    width: "20px",
    className: "text-center",
    customFunction: (item) => {
      return item.isMandatory ? 'Yes' : 'No'
    }
  },

];

export const helperToolSetting = [
  {
    name: "S. No.",
    keyName: "SerialNo",
    width: "20px",
    className: "sr-column"
  },
  {
    name: "Tool Name",
    keyName: "name",
    isSortable: true,
    isFilterable: true,
  }
];

export const helperLanguageSetting = [
  {
    name: "S. No.",
    keyName: "SerialNo",
    width: "20px",
    className: "sr-column"
  },
  {
    name: "Language",
    keyName: "name",
    isSortable: true,
    isFilterable: true,
  }
];

export const helperVehicleSetting = [
  {
    name: "S. No.",
    keyName: "SerialNo",
    width: "20px",
    className: "sr-column"
  },
  {
    name: "Vehicle Name",
    keyName: "name",
    isSortable: true,
    isFilterable: true,
  },
  {
    name: "Description",
    keyName: "description",
    isSortable: true,
    isFilterable: true,
  }
];

export const helperQuickFactSetting = [
  {
    name: "S. No.",
    keyName: "SerialNo",
    width: "20px",
    className: "sr-column"
  },
  {
    name: "Fact",
    keyName: "name",
    width: "35%",
    isSortable: true,
    isFilterable: true,
  },
  {
    name: "Description",
    keyName: "description",
    isSortable: false,
    isFilterable: false,
  },
  {
    name: "Type",
    keyName: "type",
    isSortable: true,
    isFilterable: false,
    width: "20px",
  },
];

export const TasksColumns = [
  {
    header: "Service Name",
    accessorKey: "serviceName",
    enableColumnFilter: false
  },
  // {
  //   header: "Description",
  //   accessorKey: "description",
  //   enableColumnFilter: false
  // },
  {
    header: "Helper Name",
    accessorKey: "helperName",
    enableColumnFilter: true,
    Cell: ({ renderedCellValue, row }) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        {/* {row.original.helperImageUrl && */}
        {/* <img
          alt="avatar"
          height={30}
          src={row.original.helperImageUrl}
          loading="lazy"
          style={{ borderRadius: '50%' }}
        /> */}
        <Avatar
          alt={renderedCellValue}
          src={row.original.helperImageUrl}
        />

        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
        <span>{renderedCellValue}</span>
      </Box>
    ),
  },
  {
    header: "Customer Name",
    accessorKey: "customerName",
    enableColumnFilter: true,
    Cell: ({ renderedCellValue, row }) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        {/* {row.original.customerImageUrl && */}
        {/* <img
          alt="avatar"
          height={30}
          src={row.original.customerImageUrl}
          loading="lazy"
          style={{ borderRadius: '50%' }}
        /> */}
        {/* } */}
        <Avatar
          alt={renderedCellValue}
          src={row.original.customerImageUrl}
        />
        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
        <span>{renderedCellValue}</span>
      </Box>
    ),
  },
  {
    header: "Scheduled Time",
    accessorKey: "scheduledTime",
    enableColumnFilter: false,
    Cell: ({ cell, row }) => {
      return formatDate(row.original.scheduledTime)
    }
    ,
  },
  {
    header: "Task Status",
    accessorKey: "taskStatus",
    enableColumnFilter: true,
    filterSelectOptions: [
      { label: 'CUSTOMER_CANCELLED', value: 'CUSTOMER_CANCELLED' },
      { label: 'HELPER_CANCELLED', value: 'HELPER_CANCELLED' },
      { label: 'CANCELLED', value: 'CANCELLED' },
      { label: 'AUTO_CANCELLED', value: 'AUTO_CANCELLED' },
      { label: 'TASK_STARTED', value: 'TASK_STARTED' },
      { label: 'HIRING_INITIATED', value: 'HIRING_INITIATED' },
      { label: 'HIRED', value: 'HIRED' },
      { label: 'COMPLETED', value: 'COMPLETED' },
    ],
    filterVariant: 'select',
    size: 50,
    Cell: ({ cell, row }) => (
      <Box
        component="span"
        sx={(theme) => ({
          backgroundColor:
            row.original.taskStatus == 'CUSTOMER_CANCELLED' || row.original.taskStatus == 'HELPER_CANCELLED' ||
              row.original.taskStatus == 'CANCELLED' || row.original.taskStatus == 'AUTO_CANCELLED'
              ? '#d32f2f'
              : row.original.taskStatus == 'TASK_STARTED' || row.original.taskStatus == 'HIRING_INITIATED' ||
                row.original.taskStatus == 'HIRING_INITIATED'
                ? '#f57c00'
                : '#388e3c',
          borderRadius: '0.25rem',
          color: '#fff',
          maxWidth: '9ch',
          p: '0.25rem',
        })}
      >
        {row.original.taskStatus}
      </Box>
    ),
  },

];

export const TasksColumnsOldTable = [
  {
    name: "S. No.",
    keyName: "SerialNo",
    width: "20px",
    className: "sr-column"
  },
  {
    name: "Service Name",
    keyName: "serviceName",
    isFilterable: true,
  },
  // {
  //   name: "Description",
  //   keyName: "description",
  //   enableColumnFilter: false
  // },
  {
    name: "Helper Name",
    keyName: "helperName",
    isFilterable: true,
  },
  {
    name: "Customer Name",
    keyName: "customerName",
    isFilterable: true,
  },
  {
    name: "Price",
    keyName: "price",
    isSortable: true,
    className: "text-center",
  },
  {
    name: "Scheduled Time",
    keyName: "scheduledTime",
    isFilterable: true,
    width: "250px",
    className: "text-center",
  },
  {
    name: "Task Status",
    keyName: "taskStatus",
    isFilterable: true,
    className: "text-center",
    width: "150px",
    options: taskStatusOptions
  },

];

export const TaskItemsColumns = [
  {
    name: "S. No",
    keyName: "SerialNo",
    className: "sr-column",
    width: "20px"
  },
  {
    name: "Name",
    keyName: "name",
    isSortable: true,
    isFilterable: true,
    width: "200px"
  },
  {
    name: "Price",
    keyName: "price",
    isSortable: true,
    width: "200px",
    className: "text-center",
  },
  {
    name: "Quantity",
    keyName: "quantity",
    isSortable: true,
    width: "200px",
    className: "text-center",
  },
  {
    name: "Status",
    keyName: "status",
    width: "200px",
    className: "text-center",
  },
  {
    name: "Image",
    keyName: "image",
    width: "20px",
    className: "text-center",
  },
];

export const helperFacts = [
  {
    name: "S. No.",
    keyName: "SerialNo",
    width: "20px",
    className: "sr-column"
  },
  {
    name: "Fact",
    keyName: "name",
    width: "35%",
    isSortable: true,
    isFilterable: true,
  },
  {
    name: "Description",
    keyName: "description",
    isSortable: false,
    isFilterable: false,
  },
  {
    name: "Type",
    keyName: "type",
    isSortable: true,
    isFilterable: false,
    width: "20px",
  },
];

export const helperSkills = [
  {
    name: "S. No.",
    keyName: "SerialNo",
    width: "20px",
    className: "sr-column"
  },
  {
    name: "Service Category",
    keyName: "serviceCategoryName",
    isSortable: true,
    isFilterable: true,
    width: "50px",
  },
  {
    name: "Service Name",
    keyName: "serviceName",
    isSortable: true,
    isFilterable: true,
    width: "50px",
  },
  {
    name: "Skill",
    keyName: "skillsAndExperiences",
    isSortable: false,
    isFilterable: false,
  },
  {
    name: "Price",
    keyName: "price",
    width: "20px",
  },
  {
    name: "Description",
    keyName: "serviceDescription",
    isSortable: false,
    isFilterable: false,
  },
  {
    name: "Active",
    keyName: "isActive",
    width: "20px",
  },
  {
    name: "Two Hour Required",
    keyName: "isTwoHourRequired",
    isSortable: true,
    width: "20px",
    className: "text-center",
  },
  {
    name: "In Demand",
    keyName: "isInDemand",
    width: "20px",
    className: "text-center",
  },
];

// export const helperRatings = [
//   {
//     name: "S. No.",
//     keyName: "SerialNo",
//     width: "20px",
//     className: "sr-column"
//   },
//   {
//     name: "Date",
//     keyName: "createdAt",
//     width: "20px",
//     className: "text-center",
//     isSortable: true,
//   },
//   {
//     name: "Review",
//     keyName: "review",
//     isFilterable: true,
//     width: "50px",
//   },
//   {
//     name: "Description",
//     keyName: "description",
//     isFilterable: false,
//   },
//   {
//     name: "Customer Rating",
//     keyName: "customerRating",
//     width: "20px",
//     className: "text-center",
//     isSortable: true,
//   },
//   {
//     name: "Punctuality",
//     keyName: "punctualityRating",
//     width: "20px",
//     className: "text-center",
//     isSortable: true,
//   },
//   {
//     name: "Quality Of Work",
//     keyName: "qualityOfWorkRating",
//     width: "20px",
//     className: "text-center",
//     isSortable: true,
//   },
//   {
//     name: "Responsiveness",
//     keyName: "responsivenessRating",
//     width: "20px",
//     className: "text-center",
//     isSortable: true,
//   },
//   {
//     name: "Value For Money",
//     keyName: "valueForMoneyRating",
//     width: "20px",
//     className: "text-center",
//     isSortable: true,
//   }
// ];

export const helperRatings = [
  {
    header: "Date",
    accessorKey: "createdAt",
    enableColumnFilter: false,
    Cell: ({ cell, row }) => {
      return formatDateOnly(row.original.createdAt)
    },
    size: 60
  },
  {
    header: "Review",
    accessorKey: "review",
    enableColumnFilter: true,
    size: 30
  },
  {
    header: "Description",
    accessorKey: "description",
    enableColumnFilter: true,
    size: 30
  },
  {
    header: "Customer Rating",
    Header: <span>Customer<br />Rating</span>,
    accessorKey: "customerRating",
    size: 10,
    enableColumnFilter: false,
    muiTableBodyCellProps: {
      align: 'center',
    },
  },
  {
    header: "Punctuality",
    accessorKey: "punctualityRating",
    size: 10,
    enableColumnFilter: false,
    muiTableBodyCellProps: {
      align: 'center',
    },
  },
  {
    header: "Quality Of Work",
    Header: <span>Quality<br />Of Work</span>,
    accessorKey: "qualityOfWorkRating",
    size: 10,
    enableColumnFilter: false,
    muiTableBodyCellProps: {
      align: 'center',
    },
  },
  {
    header: "Responsiveness",
    accessorKey: "responsivenessRating",
    size: 10,
    enableColumnFilter: false,
    muiTableBodyCellProps: {
      align: 'center',
    },
  },
  {
    header: "Value For Money",
    Header: <span>Value For<br />Money</span>,
    accessorKey: "valueForMoneyRating",
    size: 10,
    enableColumnFilter: false,
    muiTableBodyCellProps: {
      align: 'center',
    },
  }
];

export const helperRatingsOldTable = [
  {
    name: "S. No.",
    keyName: "SerialNo",
    width: "20px",
    className: "sr-column"
  },
  {
    name: "Date",
    keyName: "createdAt",
    width: "170px",
  },
  {
    name: "Review",
    keyName: "review",
    isFilterable: true,
    // width: "200px",
  },
  {
    name: "Description",
    keyName: "description",
    isFilterable: true,
    // width: "200px",
  },
  {
    name: "Customer Rating",
    keyName: "customerRating",
    className: "text-center",
  },
  {
    name: "Punctuality",
    keyName: "punctualityRating",
    className: "text-center",
  },
  {
    name: "Quality Of Work",
    keyName: "qualityOfWorkRating",
    className: "text-center",
  },
  {
    name: "Responsiveness",
    keyName: "responsivenessRating",
    className: "text-center",
  },
  {
    name: "Value For Money",
    keyName: "valueForMoneyRating",
    className: "text-center",
  }
];

export const PaymentsColumns = [
  {
    header: "Date",
    accessorKey: "paymentInitiatedTime",
    enableColumnFilter: false,
    Cell: ({ cell, row }) => {
      return formatDate(row.original.paymentInitiatedTime)
    },
    size: 20
  },
  {
    header: "Helper Name",
    accessorKey: "helperName",
    enableColumnFilter: true,
    Cell: ({ row }) => `${row.original.firstName} ${row.original.lastName}`,
    size: 50
  },
  {
    header: "Email",
    accessorKey: "email",
    enableColumnFilter: true,
    size: 50
  },
  {
    header: "Amount",
    accessorKey: "amount",
    enableColumnFilter: false,
    Cell: ({ cell }) => cell.getValue()?.toLocaleString?.('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
    size: 10
  },
  {
    header: "Payment Status",
    accessorKey: "paymentStatus",
    enableColumnFilter: true,
    filterSelectOptions: [
      { label: 'SUCCESS', value: 'SUCCESS' },
      { label: 'PENDING', value: 'PENDING' },
      { label: 'INITIATED', value: 'INITIATED' },
      { label: 'CANCELLED', value: 'CANCELLED' },
      { label: 'REFUNDED', value: 'REFUNDED' },
      { label: 'HOLD', value: 'HOLD' },
      { label: 'CHARGED', value: 'CHARGED' },
      { label: 'INVOICED', value: 'INVOICED' },
    ],
    filterVariant: 'select',
    size: 10,
    Cell: ({ cell, row }) => (
      <Box
        component="span"
        sx={(theme) => ({
          backgroundColor:
            row.original.paymentStatus == 'SUCCESS'
              ? '#4CAF50'
              : row.original.paymentStatus == 'PENDING'
                ? '#FFC107' :
                row.original.paymentStatus == 'INITIATED'
                  ? '#2196F3'
                  : row.original.paymentStatus == 'CANCELLED'
                    ? '#FF5722'
                    : row.original.paymentStatus == 'REFUNDED'
                      ? '#8BC34A'
                      : row.original.paymentStatus == 'HOLD'
                        ? '#9E9E9E'
                        : row.original.paymentStatus == 'CHARGED'
                          ? '#03A9F4'
                          : row.original.paymentStatus == 'INVOICED'
                            ? '#9C27B0'
                            : '#388e3c',
          borderRadius: '0.25rem',
          color: '#fff',
          maxWidth: '9ch',
          p: '0.25rem',
        })}
      >
        {row.original.paymentStatus}
      </Box>
    ),
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
  },
  {
    header: "Payment Type",
    accessorKey: "paymentType",
    enableColumnFilter: false,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
    size: 10
  },
];

export const PaymentsColumnsOldTable = [
  {
    name: "S. No.",
    keyName: "SerialNo",
    width: "20px",
    className: "sr-column"
  },
  {
    name: "Date",
    keyName: "paymentInitiatedTime",
    isFilterable: true,
    width: "200px",
    className: "text-center",
  },
  {
    name: "User Name",
    keyName: "helperName",
    isFilterable: true,
    customFunction: (item) => {
      return `${item.firstName} ${item.lastName}`
    }
  },
  {
    name: "User Email",
    keyName: "email",
    isFilterable: true,
  },
  {
    name: "Amount",
    keyName: "amount",
    isSortable: true,
    className: "text-center",
  },
  {
    name: "Payment Status",
    keyName: "paymentStatus",
    isFilterable: true,
    className: "text-center",
    width: "150px",
    customFunction: (item) => {
      return <Box
        component="span"
        sx={(theme) => ({
          backgroundColor:
            item.paymentStatus == 'SUCCESS'
              ? '#4CAF50'
              : item.paymentStatus == 'PENDING'
                ? '#FFC107' :
                item.paymentStatus == 'INITIATED'
                  ? '#2196F3'
                  : item.paymentStatus == 'CANCELLED'
                    ? '#FF5722'
                    : item.paymentStatus == 'REFUNDED'
                      ? '#8BC34A'
                      : item.paymentStatus == 'HOLD'
                        ? '#9E9E9E'
                        : item.paymentStatus == 'CHARGED'
                          ? '#03A9F4'
                          : item.paymentStatus == 'INVOICED'
                            ? '#9C27B0'
                            : '#388e3c',
          borderRadius: '0.25rem',
          color: '#fff',
          maxWidth: '9ch',
          p: '0.25rem',
        })}
      >
        {item.paymentStatus}
      </Box>
    },
    options: paymentStatusOptions
  },
  {
    name: "Payment Type",
    keyName: "paymentType",
    isFilterable: true,
    className: "text-center",
    width: "150px",
    options: paymentTypeOptions
  },

];

export const PayoutColumns = [
  {
    name: "S. No.",
    keyName: "SerialNo",
    width: "20px",
    className: "sr-column"
  },
  {
    name: "Date",
    keyName: "payoutDateTime",
    isFilterable: true,
    width: "200px",
    className: "text-center",
    customFunction: (item) => {
      return formatDate(item?.payoutDateTime)
    }
  },
  {
    name: "Helper Name",
    keyName: "helperName",
    isFilterable: true,
  },
  {
    name: "Email",
    keyName: "email",
    isFilterable: true,
  },
  {
    name: "Amount",
    keyName: "payoutAmount",
    isSortable: true,
    className: "text-center",
    customFunction: (item) => {
      return formatPriceInUSD(item?.payoutAmount || 0)
    }
  },
  {
    name: "Payout Status",
    keyName: "payoutStatus",
    isFilterable: true,
    className: "text-center",
    width: "150px",
    customFunction: (item) => {
      return <Box
        component="span"
        sx={(theme) => ({
          backgroundColor:
            item.payoutStatus == 'PAID'
              ? '#4CAF50' :
              item.payoutStatus == 'INITIATED'
                ? '#2196F3'
                : item.payoutStatus == 'CANCELLED' ?
                  'FF5722'
                  : '#f57c00',
          borderRadius: '0.25rem',
          color: '#fff',
          maxWidth: '9ch',
          p: '0.25rem',
        })}
      >
        {item.payoutStatus}
      </Box >
    },
    options: payoutStatusOptions
  },
];

export const SkillTradeColumns = [
  {
    name: "S. No.",
    keyName: "SerialNo",
    width: "20px",
    className: "sr-column"
  },
  {
    name: "Service Name",
    keyName: "serviceName",
    isFilterable: true,
    customFunction: (item) => {
      return item.serviceName || item?.serviceId
    }
  },
  {
    name: "Helper Name",
    keyName: "helperName",
    isFilterable: true,
  },
  {
    name: "Helper Email",
    keyName: "email",
    isFilterable: true,
  },
  {
    name: "Price",
    keyName: "price",
    isSortable: false,
    className: "text-center",
    customFunction: (item) => {
      return formatPriceInUSD(item?.price)
    }
  },
  {
    name: "Created Time",
    keyName: "createdAt",
    isFilterable: true,
    width: "250px",
    className: "text-center",
    customFunction: (item) => {
      return formatDate(item?.createdAt)
    }
  },
  {
    name: "Skill Trade Status",
    keyName: "skillTradeStatus",
    isFilterable: true,
    className: "text-center",
    width: "150px",
    customFunction: (item) => {
      return <Box
        component="span"
        sx={(theme) => ({
          backgroundColor:
            item.skillTradeStatus == 'VERIFIED'
              ? '#388e3c' :
              item.skillTradeStatus == 'SUBMITTED'
                ? '#f57c00'
                : item.skillTradeStatus == 'REJECTED' ?
                  '#d32f2f'
                  : '#f57c00',
          borderRadius: '0.25rem',
          color: '#fff',
          maxWidth: '9ch',
          p: '0.25rem',
        })}
      >
        {item.skillTradeStatus}
      </Box >
    },
    options: skillTradeStatusOptions
  },

];

