import { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { CreateNewQuestion, EditQuestion } from "../../../api/adminApi";
import FormLabelStar from "../../core/FormLabelStar";
import { validationLength } from "../../../metaData/validationLength"
import { notifyWarning } from "../../../api/toasterService";

const InitialForm = {
  id: 0,
  options: [],
  question: "",
  serviceId: "0",
  type: "",
  option: "",
  key: ""
};
export default function AddNewQuestion({ service, isAdded, question, questionList }) {
  InitialForm.serviceId = service.id;
  const [formData, setFormData] = useState(InitialForm);
  const [openAddOption, setOpenAddOption] = useState(true);
  const [currentOption, setcurrentOption] = useState("");
  const questionTypes = useSelector((state) => state.admin).questionOptions;
  const [validated, setValidated] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [isOptionLenValid, setIsOptionLenValid] = useState(true);
  const [isOptionValid, setIsOptionValid] = useState(true);

  useEffect(() => {
    // console.log({ question, questionList });
    if (question) {
      setFormData({
        id: question.id,
        options: question.serviceQuestionOptions.map((option) => option.option),
        question: question.question,
        serviceId: question.serviceId,
        type: question.type,
        option: question,
        key: question.key
      })
      setOpenAddOption(false)
    }
  }, [service])

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
      options: name === 'type' ? [] : [...formData.options]
    });
    console.log(formData);
    if (name === 'option') {
      setcurrentOption(value)
    }
    if (name === 'type') {
      setcurrentOption("")
      setOpenAddOption(true);
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    // Set submitClicked to true when the submit button is clicked
    setSubmitClicked(true);
    let isValidated = true;
    // console.log({ formData });
    // console.log({ currentOption });

    if ((formData.type === 'MULTIPLE_CORRECT' ||
      formData.type === 'SINGLE_CORRECT' ||
      formData.type === 'SINGLE_CORRECT_AND_TYPE_IN')
    ) {
      if (openAddOption
        && currentOption == '') {
        setIsOptionLenValid(false)
        isValidated = false;
      } else {
        setIsOptionLenValid(true)
      }
      if (openAddOption) {
        setIsOptionValid(false)
        isValidated = false;
      }
    } else {
      setIsOptionLenValid(true)
      setIsOptionValid(true)
    }



    if (!isValidated) {
      setValidated(true);
      return;
    }
    // Validate the form
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    if (formData.type === 'LOCATION') {
      const questionFilter = questionList.find((ques) => {
        return ques.type === 'LOCATION' && ques.id != formData.id
      })
      if (questionFilter) {
        notifyWarning('Location type question already added.')
        return
      }
    }

    if (question) {
      const dataWithoutOption = { ...formData };
      delete dataWithoutOption.option;
      await EditQuestion(dataWithoutOption);
    } else {
      await CreateNewQuestion(formData);
    }
    isAdded(true)
  };

  const saveOption = () => {
    console.log(formData.option);
    if (currentOption === "") {
      // alert("Please enter option")
      setIsOptionValid(false)
      return
    } else {
      setFormData({
        ...formData,
        options: [...formData.options, formData.option],
      });
      setOpenAddOption(false);
      setcurrentOption("")
    }
  };

  const handleRemove = (index) => {
    // Copy the current options array
    const updatedOptions = [...formData.options];

    // Remove the option at the specified index
    updatedOptions.splice(index, 1);

    // Update the state with the new options array
    setFormData({
      ...formData,
      options: updatedOptions,
    });
    if (updatedOptions.length === 0)
      setOpenAddOption(true)
  };

  const handleAdd = (inputName) => {
    if (formData[inputName].length > 0)
      setOpenAddOption(!openAddOption)
    if ((formData.type === 'SINGLE_CORRECT' || formData.type === 'SINGLE_CORRECT_AND_TYPE_IN')
      && formData[inputName].length >= 1) {
      console.log(`only one opton allowed`);
    }
  };

  const handleReset = (e) => {
    setFormData(InitialForm);

    // setTimeout(() => {
    //   categoryRef.current.selectedIndex = 0;
    //   demandRef.current.selectedIndex = 0
    // }, 0)
    setValidated(false);
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Service Name<FormLabelStar /></Form.Label>
          <Form.Control
            required
            type="text"
            name="name"
            disabled
            value={service.name}
            onChange={handleChange}
            placeholder="service name"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Question Label<FormLabelStar /></Form.Label>
          <Form.Control
            type="text"
            name="question"
            onChange={handleChange}
            placeholder="Question"
            required
            value={formData.question}
            maxLength={validationLength.service.question.name.maxLength}
          />
          {formData.question?.length > validationLength.service.question.name.maxLength - 1 && (
            <div className="province-name-validate">{validationLength.message(validationLength.service.question.name.maxLength)}</div>
          )}
          <Form.Control.Feedback type="invalid">
            Please enter question label.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Key<FormLabelStar /></Form.Label>
          <Form.Control
            type="text"
            name="key"
            onChange={handleChange}
            placeholder="Key"
            required
            value={formData.key}
            maxLength={validationLength.service.question.key.maxLength}
          />
          {formData.key?.length > validationLength.service.question.key.maxLength - 1 && (
            <div className="province-name-validate">{validationLength.message(validationLength.service.question.key.maxLength)}</div>
          )}
          <Form.Control.Feedback type="invalid">
            Please enter question label.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Answer Type<FormLabelStar /></Form.Label>
          <Form.Select
            size="sm"
            aria-label=" Select Category"
            name="type"
            onChange={handleChange}
            // onChange={filterServices}
            value={formData.type}
            required
            disabled={question ? true : false}
          >
            <option value="" >Select</option>
            {questionTypes?.map((type, index) => (
              <option value={type} key={index}>{type}</option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select answer type.
          </Form.Control.Feedback>
        </Form.Group>
        {
          (formData.type === 'MULTIPLE_CORRECT' || formData.type === 'SINGLE_CORRECT' || formData.type === 'SINGLE_CORRECT_AND_TYPE_IN') &&
          <Form.Group className="mb-3" controlId="formBasicPassword"
          >
            <Form.Label >Options</Form.Label>{" "}
            {<a
              className="link-btn"
              style={{ marginLeft: "20px", marginBottom: "15px" }}
              onClick={() => handleAdd('options')}
            >
              Add
            </a>}
            {formData.options.map((o, index) => (
              <div className="d-flex justify-content-between mb-3" key={index}>
                <label>{o}</label>
                <div>
                  {/* <a
                  className="link-btn"
                  style={{ marginLeft: "20px", marginBottom: "15px" }}
                  onClick={() => setOpenAddOption(!openAddOption)}
                >
                  Edit
                </a> */}
                  <a
                    className="link-btn"
                    style={{ marginLeft: "20px", marginBottom: "15px" }}
                    onClick={() => handleRemove(index)}
                  >
                    Remove
                  </a>
                </div>
              </div>
            ))}
            {openAddOption && (
              <>
                <div className="save d-flex">
                  <Form.Control
                    size="sm"
                    aria-label=" Select Category"
                    name="option"
                    style={{ marginRight: "15px" }}
                    onChange={handleChange}
                    required
                    // onChange={filterServices}
                    value={currentOption}
                    maxLength={validationLength.service.question.option.maxLength}
                  />

                  <Button onClick={saveOption}>save</Button>
                </div>
                {currentOption?.length > validationLength.service.question.option.maxLength - 1 && (
                  <div className="province-name-validate">{validationLength.message(validationLength.service.question.option.maxLength)}</div>
                )}
                {!isOptionLenValid && <div className="province-name-validate">
                  Please enter option.
                </div>}
                {!isOptionValid && <div className="province-name-validate">
                  Please save option.
                </div>}
              </>
            )}
          </Form.Group>
        }
        <div className="d-flex justify-content-between m-2">
          <Button variant="primary" type="submit" size="sm">
            Submit
          </Button>
          {!question &&
            <Button variant="secondary" type="reset" size="sm" onClick={() => handleReset()}>
              Reset
            </Button>}

        </div>
      </Form>
    </>
  );
}
