import { useEffect, useState } from "react";
import { DeleteServiceCategory, getServiceCategory } from "../../api/adminApi";
import { Container, Button } from "react-bootstrap";
import { serviceCategory } from "../../metaData/ColumnList";
import ShowModal from "../../components/core/Modal";
import AddNewCategory from "../../components/Admin/serviceManagement/AddNewServiceCategory";
import ReusableTable from "../../components/core/ReusableTable";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { START_LOADING, STOP_LOADING } from "../../redux/Slice/loaderSlice";

export default function ServiceCategory() {
  const [serviceCategoryList, setserviceCategoryList] = useState();
  const [showModal, setShowModal] = useState(false);
  const [actions, setActions] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedService, setSelectedServcie] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    fetchServiceCategory();
  }, []);

  const viewCategory = (item) => {
    console.log(item);
    navigate(`/admin/servicesCategory/${item.id}`);
  };
  async function fetchServiceCategory() {
    dispatch(START_LOADING());
    let serviceCategory = await getServiceCategory();
    dispatch(STOP_LOADING());
    setserviceCategoryList(serviceCategory.data);
    console.log(serviceCategory);
    AddActions();
  }
  function AddActions() {
    setActions([
      {
        label: "view",
        clickAction: viewCategory,
        variant: "success",
      },
      {
        label: "edit",
        clickAction: openEditModal,
      },
      {
        label: "delete",
        variant: "danger",
        clickAction: openDeleteModal,
      },
    ]);
  }
  const newCatAdded = () => {
    setShowModal(false);
    setShowEditModal(false);
    fetchServiceCategory();
  };
  const openDeleteModal = async (item) => {
    setShowDeleteModal(true);
    setSelectedServcie(item);
  };
  const openEditModal = async (item) => {
    setShowEditModal(true);
    setSelectedServcie(item);
  };

  const deleteCategory = async () => {
    await DeleteServiceCategory(selectedService.id);
    setShowDeleteModal(false);
    fetchServiceCategory();
  };
  return (
    <Container className="bg-white" style={{ padding: "1rem" }}>
      <Container className="d-flex justify-content-between mb-2 bg-white p-2">
        <h3 className="heading "> Service Category List</h3>
        <Button onClick={() => setShowModal(true)}>Add New Category</Button>
      </Container>
      <Container className="service-list-table" style={{ width: "40%" }}>
        {/* {serviceCategoryList?.length && ( */}
        <ReusableTable
          columns={serviceCategory}
          data={serviceCategoryList}
          actions={actions}
          actionWidth={"300px"}
        ></ReusableTable>
        {/* )} */}
      </Container>
      <ShowModal
        modalheading={showEditModal ? "Edit Category" : "Add New Category"}
        show={showModal || showEditModal}
        body={<AddNewCategory isAdded={newCatAdded} category={showEditModal ? selectedService : undefined}></AddNewCategory>}
        handleClose={() => {
          setShowModal(false)
          setShowEditModal(false)
        }
        }
      ></ShowModal>
      <ShowModal
        modalheading="Delete Service"
        show={showDeleteModal}
        extraData={selectedService}
        classname={"add-service-modal"}
        body="Are you sure you want to delete this service"
        handleClose={() => setShowDeleteModal(false)}
        showFooter={true}
        Submit={deleteCategory}
      ></ShowModal>
    </Container>
  );
}
