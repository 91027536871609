import { Accordion, Button, Col, Container, Form, Image, ProgressBar, Row, Tab, Tabs } from "react-bootstrap";
import { useEffect, useState, useMemo } from "react";
import { getHelperProfile, getHelperSkills, getHelperRatings, getHelperKYCDetails } from "../../api/adminApi";
import { useParams, useSearchParams } from "react-router-dom";
import ReusableTable from "../../components/core/ReusableTable";
import { helperFacts, helperVehicleSetting, helperSkills, helperRatingsOldTable } from "../../metaData/ColumnList";
import { useDispatch } from "react-redux";
import { START_LOADING, STOP_LOADING } from "../../redux/Slice/loaderSlice";
import { formatPriceInUSD } from '../../utils/priceUtils';
import { notifySuccess, notifyError, notifyInfo, notifyWarning } from '../../api/toasterService';
import { Box, Grid, Rating, Stack } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import PaginationComponent from "../../components/core/PaginationControl";
import DetailsCard from "../../components/core/DetailsCard";
import { formatDateOnly } from "../../utils/dateUtils";
import { Breadcrumb } from 'antd';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import MyBreadCrumb from "../../components/core/MyBreadCrumb";
import { UPDATE_ROUTELIST } from "../../redux/Slice/adminSlice";
import ShowModal from "../../components/core/Modal";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const avgRatings = {
  "avgRating": 0,
  "avgResponsivenessRating": 0,
  "avgPunctualityRating": 0,
  "avgQualityOfWorkRating": 0,
  "avgValueForMoneyRating": 0
}

const countRatings = {
  "rating": 0,
  "one": 0,
  "four": 0,
  "three": 0,
  "five": 0,
  "two": 0
}

export default function UserDetails() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [userDetails, setUserDetails] = useState();
  // const [user, setUser] = useState();
  const [userSkills, setUserSkills] = useState([]);
  const [userRatings, setUserRatings] = useState([]);
  const [userKYCDetails, setUserKYCDetails] = useState();
  const [showmodal, setShowmodal] = useState();
  const [imageUrl, setImageUrl] = useState();

  const dispatch = useDispatch();


  useEffect(() => {
    getDetails(id)
  }, []);

  //data and fetching state
  const [avgData, setAvgData] = useState(avgRatings);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItem, setTotalItem] = useState([10]);
  const [data, setData] = useState([]);
  const [countData, setCountData] = useState(countRatings);

  useEffect(() => {
    fetchRatings();
  }, [currentPage, pageSize]);

  const onPageChange = (pageNumber, noOfItem) => {
    setCurrentPage(() => pageNumber);
    setPageSize(() => noOfItem);
  };

  const viewImage = (url) => {
    setImageUrl(url);
    setShowmodal(true);
  };


  const getDetails = async (id) => {
    try {
      dispatch(START_LOADING());
      const userDetails = await getHelperProfile(id);
      setUserDetails(userDetails.data);
      dispatch(STOP_LOADING());
    } catch (error) {
      notifyError('Something went wrong')
    } finally {
      dispatch(STOP_LOADING());
    }
  };

  async function fetchRatings() {
    dispatch(START_LOADING());

    try {
      const ratings = await getHelperRatings({
        id,
        page: currentPage,
        count: pageSize
      });
      setData(ratings?.data?.ratings?.content);
      setTotalItem(ratings?.data?.ratings?.totalRecords);
      setAvgData(ratings?.data?.average || avgRatings)
      setCountData(ratings?.data?.counts || countData)
    } catch (error) {
      console.error(error);
      return;
    } finally {
      dispatch(STOP_LOADING());
    }

  }

  const handleSelectedTab = async (tabName) => {
    dispatch(START_LOADING());
    switch (tabName) {
      case "skills":
        const skills = await getHelperSkills(id);
        if (skills)
          setUserSkills(skills.data)
        break;
      case "kycdetails":
        const kycDetails = await getHelperKYCDetails(id);
        if (kycDetails)
          setUserKYCDetails(kycDetails.data)
        break;
      // case "ratings":
      //   setCurrentPage(0);
      //   setPageSize(10);
      //   dispatch(START_LOADING());
      //   const ratings = await getHelperRatings({
      //     id,
      //     page: 0,
      //     count: 10
      //   });
      //   if (ratings)
      //     setUserRatings(ratings?.data?.ratings?.content)
      //   dispatch(STOP_LOADING());
      //   break;
      // case "questions":
      //   break;
      // case "supply":
      //   await fetchSupplies(id)
      //   break;
      // case "details":
      //   // await getAllServiceDetails(id)
      //   break;

      default:
        break;
    }
    dispatch(STOP_LOADING());
  };

  const routeList = [
    {
      href: '/admin/dashboard',
      title: (
        <>
          <span>Dashboard</span>
        </>
      ),
    },
    {
      title: (
        <>
          {/* <UserOutlined /> */}
          <span>Users</span>
        </>
      ),
    },
    {
      href: '/admin/helper',
      title: (
        <>
          <span>Helper</span>
        </>
      ),
    },
    {
      title: 'Helper Details',
    },
  ]
  dispatch(UPDATE_ROUTELIST(routeList))


  return (
    <>
      {userDetails && (
        <div className="bg-white p-4 service-question" style={{ width: "95%" }}>
          <div className="mb-4">
            <h3 className="text-center"> Helper Details</h3>
            {/* <MyBreadCrumb routeList={routeList} title={'Helper Details'} /> */}
          </div>
          <hr></hr>
          <Tabs
            defaultActiveKey="details"
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={handleSelectedTab}
          >
            <Tab eventKey="details" title="Helper Details">
              <div class="container">
                <div class="row">
                  <div class="col-2">
                    <div className="column-name">
                      <label>Helper Name : </label>
                    </div>
                  </div>
                  <div class="col-10 user-col">
                    <div className="column-name">
                      <img
                        alt="avatar"
                        height={30}
                        src={userDetails.imageUrl}
                        loading="lazy"
                        style={{ borderRadius: '50%', marginRight: "10px" }}
                        onClick={() => viewImage(userDetails.imageUrl)}
                      />
                      <span>{`${userDetails.firstName} ${userDetails.lastName}`}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2">
                    <div className="column-name">
                      <label>Email : </label>
                    </div>
                  </div>
                  <div class="col-10">
                    <div className="column-name">
                      <span>{userDetails.email}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2">
                    <div className="column-name">
                      <label>Phone : </label>
                    </div>
                  </div>
                  <div class="col-10">
                    <div className="column-name">
                      <span>{userDetails.phone}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2">
                    <div className="column-name">
                      <label>About : </label>
                    </div>
                  </div>
                  <div class="col-10">
                    <div className="column-name">
                      <span>{userDetails.aboutMe}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2">
                    <div className="column-name">
                      <label>Address : </label>
                    </div>
                  </div>
                  <div class="col-10">
                    <div className="column-name">
                      <span>{userDetails.postalAddress && userDetails.postalCode && `${userDetails.postalAddress || ""} - ${userDetails.postalCode || ""}`}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2">
                    <div className="column-name">
                      <label>Languages : </label>
                    </div>
                  </div>
                  <div class="col-10">
                    <div className="column-name" style={{ marginLeft: "-10px" }}>
                      <ul>
                        {userDetails.languages && userDetails.languages.map((lang) => {
                          return (<li>{lang.name}</li>)
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2">
                    <div className="column-name">
                      <label>Tools : </label>
                    </div>
                  </div>
                  <div class="col-10">
                    <div className="column-name" style={{ marginLeft: "-10px" }}>
                      <ul>
                        {userDetails.tools && userDetails.tools.map((tool) => {
                          return (<li>{tool.name}</li>)
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="column-name service-column">
                <label>Languages: </label>
                <ul>
                  {userDetails.languages.map((lang) => {
                    return (<li>{lang.name}</li>)
                  })}
                </ul>
              </div> */}
              {/* <div className="column-name service-column">
                <label>Tools: </label>
                <ul>
                  {userDetails.tools.map((tool) => {
                    return (<li>{tool.name}</li>)
                  })}
                </ul>
              </div> */}
            </Tab>
            <Tab eventKey="facts" title="Facts">
              <div class="container">
                <div class="row">
                  <div class="col-2">
                    <div className="column-name">
                      <label>Helper Name : </label>
                    </div>
                  </div>
                  <div class="col-10 user-col">
                    <div className="column-name">
                      {/* <img
                        alt="avatar"
                        height={30}
                        src={user.pictureUrl}
                        loading="lazy"
                        style={{ borderRadius: '50%', marginRight: "10px" }}
                      /> */}
                      <span>{`${userDetails.firstName} ${userDetails.lastName}`}</span>
                    </div>
                  </div>
                </div>
              </div>
              <Container className="service-list-table" style={{ padding: "0" }}>
                {/* {userDetails?.facts?.length === 0 ? (
                  <>
                    <div className="d-flex justify-content-center">
                      {" "}
                      No Facts to Show
                    </div>
                  </>
                ) : ( */}
                <ReusableTable
                  columns={helperFacts}
                  data={userDetails?.facts || []}
                ></ReusableTable>
                {/* )} */}
              </Container>
            </Tab>
            <Tab eventKey="vehicles" title="Vehicles">
              <div class="container">
                <div class="row">
                  <div class="col-2">
                    <div className="column-name">
                      <label>Helper Name : </label>
                    </div>
                  </div>
                  <div class="col-10 user-col">
                    <div className="column-name">
                      {/* <img
                        alt="avatar"
                        height={30}
                        src={user.pictureUrl}
                        loading="lazy"
                        style={{ borderRadius: '50%', marginRight: "10px" }}
                      /> */}
                      <span>{`${userDetails.firstName} ${userDetails.lastName}`}</span>
                    </div>
                  </div>
                </div>
              </div>
              <Container className="service-list-table" style={{ padding: "0" }}>
                {/* {userDetails?.vehicles?.length === 0 ? (
                  <>
                    <div className="d-flex justify-content-center">
                      {" "}
                      No Vehicle to Show
                    </div>
                  </>
                ) : ( */}
                <ReusableTable
                  columns={helperVehicleSetting}
                  data={userDetails?.vehicles || []}
                ></ReusableTable>
                {/* )} */}
              </Container>
            </Tab>
            <Tab eventKey="skills" title="Skills">
              <div class="container">
                <div class="row">
                  <div class="col-2">
                    <div className="column-name">
                      <label>Helper Name : </label>
                    </div>
                  </div>
                  <div class="col-10 user-col">
                    <div className="column-name">
                      {/* <img
                        alt="avatar"
                        height={30}
                        src={user.pictureUrl}
                        loading="lazy"
                        style={{ borderRadius: '50%', marginRight: "10px" }}
                      /> */}
                      <span>{`${userDetails.firstName} ${userDetails.lastName}`}</span>
                    </div>
                  </div>
                </div>
              </div>
              <Container className="service-list-table" style={{ padding: "0" }}>
                {/* {userSkills?.length === 0 ? (
                  <>
                    <div className="d-flex justify-content-center">
                      {" "}
                      No Skills to Show
                    </div>
                  </>
                ) : ( */}
                <ReusableTable
                  columns={helperSkills}
                  data={userSkills || []}
                ></ReusableTable>
                {/* )} */}
              </Container>
            </Tab>
            <Tab eventKey="ratings" title="Ratings">
              <div class="container">
                <div class="row">
                  <div class="col-2">
                    <div className="column-name">
                      <label>Helper Name : </label>
                    </div>
                  </div>
                  <div class="col-10 user-col">
                    <div className="column-name">
                      {/* <img
                        alt="avatar"
                        height={30}
                        src={user.pictureUrl}
                        loading="lazy"
                        style={{ borderRadius: '50%', marginRight: "10px" }}
                      /> */}
                      <span>{`${userDetails.firstName} ${userDetails.lastName}`}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2">
                    <div className="column-name" style={{ margin: "0px" }}>
                      <label>Ratings : </label>
                    </div>
                  </div>
                  <div class="col-10 user-col">

                    <div className="column-name" style={{ margin: "0px" }}>
                      {/* <span>{`${avgData.avgRating}`}</span> */}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2">
                    <div className="">
                      {/* <label>Average Punctuality Rating : </label> */}
                    </div>
                  </div>
                  <div class="col-10 user-col">
                    <div class="row">
                      <div class="col-6">
                        <div className="">
                          <Grid container spacing={0} style={{ width: "600px" }}>
                            <Grid item xs={3}>
                              <Box>{'Overall'}</Box>
                            </Grid>
                            <Grid item xs={9}>
                              <Rating
                                name="text-feedback"
                                value={avgData.avgRating}
                                readOnly
                                precision={0.5}
                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Box>{'Punctuality'}</Box>
                            </Grid>
                            <Grid item xs={9}>
                              <Rating
                                name="text-feedback"
                                value={avgData.avgPunctualityRating}
                                readOnly
                                precision={0.5}
                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Box>{'Quality Of Work'}</Box>
                            </Grid>
                            <Grid item xs={9}>
                              <Rating
                                name="text-feedback"
                                value={avgData.avgQualityOfWorkRating}
                                readOnly
                                precision={0.5}
                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Box>{'Responsiveness'}</Box>
                            </Grid>
                            <Grid item xs={9}>
                              <Rating
                                name="text-feedback"
                                value={avgData.avgResponsivenessRating}
                                readOnly
                                precision={0.5}
                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Box>{'Value For Money'}</Box>
                            </Grid>
                            <Grid item xs={9}>
                              <Rating
                                name="text-feedback"
                                value={avgData.avgValueForMoneyRating}
                                readOnly
                                precision={0.5}
                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                      <div class="col-6">
                        <div className="column-name">
                          <Grid container spacing={0.5} style={{ width: "600px" }}>
                            <Grid item xs={2}>
                              <Box>{'5 Star'}</Box>
                            </Grid>
                            <Grid item xs={10}>
                              <ProgressBar variant="warning" style={{ width: "40%" }}
                                now={((countData.five / countData.rating) * 100) || 0}
                                label={`${(((countData.five / countData.rating) * 100) || 0).toFixed(2)}%`} />
                            </Grid>
                            <Grid item xs={2}>
                              <Box>{'4 Star'}</Box>
                            </Grid>
                            <Grid item xs={10}>
                              <ProgressBar variant="warning" style={{ width: "40%" }}
                                now={((countData.four / countData.rating) * 100) || 0}
                                label={`${(((countData.four / countData.rating) * 100) || 0).toFixed(2)}%`} />
                            </Grid>
                            <Grid item xs={2}>
                              <Box>{'3 Star'}</Box>
                            </Grid>
                            <Grid item xs={10}>
                              <ProgressBar variant="warning" style={{ width: "40%" }}
                                now={((countData.three / countData.rating) * 100) || 0}
                                label={`${(((countData.three / countData.rating) * 100) || 0).toFixed(2)}%`} />
                            </Grid>
                            <Grid item xs={2}>
                              <Box>{'2 Star'}</Box>
                            </Grid>
                            <Grid item xs={10}>
                              <ProgressBar variant="warning" style={{ width: "40%" }}
                                now={((countData.two / countData.rating) * 100) || 0}
                                label={`${(((countData.two / countData.rating) * 100) || 0).toFixed(2)}%`} />
                            </Grid>
                            <Grid item xs={2}>
                              <Box>{'1 Star'}</Box>
                            </Grid>
                            <Grid item xs={10}>
                              <ProgressBar variant="warning" style={{ width: "40%" }}
                                now={((countData.one / countData.rating) * 100) || 0}
                                label={`${(((countData.one / countData.rating) * 100) || 0).toFixed(2)}%`} />
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Container className="bg-white" style={{ padding: "1rem" }}>
                <Container className="service-list-table" style={{ padding: "0" }}>
                  <ReusableTable
                    columns={helperRatingsOldTable}
                    data={data}
                  ></ReusableTable>
                  <PaginationComponent
                    onPageChange={onPageChange}
                    itemsPerPage={pageSize}
                    totalItems={totalItem}
                    pageNo={currentPage}
                    onPageSizeChange={setPageSize}
                  ></PaginationComponent>
                </Container>
              </Container>
            </Tab>
            <Tab eventKey="kycdetails" title="KYC Details">
              {userKYCDetails ?
                <div class="container" >
                  <div class="row" >
                    <DetailsCard title={'First Name'} value={`${userKYCDetails.firstName}`} length={32} />
                    <DetailsCard title={'Middle Name'} value={`${userKYCDetails.middleName || 'NA'}`} length={32} />
                    <DetailsCard title={'Last Name'} value={`${userKYCDetails.lastName}`} length={32} />
                    <DetailsCard title={'Date of birth'} value={formatDateOnly(userKYCDetails.dob)} length={32} />
                  </div>
                  <div class="row" >
                    <DetailsCard title={'Social Security No.'} value={`${userKYCDetails.socialSecurityNo || 'NA'}`} length={32} />
                    <DetailsCard title={'Street No. and Name'} value={`${userKYCDetails.streetNameNumber || 'NA'}`} length={32} />
                    <DetailsCard title={'Apt/Suite'} value={`${userKYCDetails.aptSuite || 'NA'}`} length={32} />
                    <DetailsCard title={'City'} value={`${userKYCDetails.city || 'NA'}`} length={32} />
                  </div>
                  <div class="row" >
                    <DetailsCard title={'Province'} value={`${userKYCDetails.state || 'NA'}`} length={32} />
                    <DetailsCard title={'Province Code'} value={`${userKYCDetails.provinceCode || 'NA'}`} length={32} />
                    <DetailsCard title={'Postal Code'} value={`${userKYCDetails.zipCode || 'NA'}`} length={32} />
                    <DetailsCard title={'Status'} value={userKYCDetails.kycStatus} length={32} color={
                      userKYCDetails.kycStatus == 'REJECTED'
                        ? '#d32f2f'
                        : userKYCDetails.kycStatus == 'SUBMITTED'
                          || userKYCDetails.kycStatus == 'RE_SUBMITTED'
                          ? '#f57c00'
                          : '#388e3c'
                    }
                    />
                  </div>
                  <div class="row" >
                    <DetailsCard title={'Screening Id'} value={`${userKYCDetails.screeningId || 'NA'}`} length={32} />
                    <DetailsCard title={'Candidate Id'} value={`${userKYCDetails.candidateId || 'NA'}`} length={40} />
                    <DetailsCard title={'Client Reference Id'} value={`${userKYCDetails.clientReferenceId || 'NA'}`} length={40} />
                    <DetailsCard title={''} value={``} length={32} />
                  </div>
                </div>
                :
                <div style={{ textAlign: "center" }}>
                  <p>KYC details not submitted.</p>
                </div>
              }

            </Tab>
          </Tabs>
        </div >
      )
      }
      <ShowModal
        classname="modal-image"
        show={showmodal}
        body={<Image className="modal-img" src={imageUrl}></Image>}
        handleClose={() => setShowmodal(false)}
      ></ShowModal>
    </>
  );
}
