import { useEffect, useState, useMemo } from "react";
import {
  getUserPaymentDetails,
} from "../../api/adminApi";
import { Button, Container, Form } from "react-bootstrap";
import { PaymentsColumnsOldTable } from "../../metaData/ColumnList";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import ReusableTable from "../../components/core/ReusableTable";
import PaginationComponent from "../../components/core/PaginationControl";
import { START_LOADING, STOP_LOADING } from "../../redux/Slice/loaderSlice";
import { parseFilterDate } from "../../utils/dateUtils";


export default function Payments() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [actions, setActions] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItem, setTotalItem] = useState([10]);
  const [data, setData] = useState([]);
  const [searchFilter, setSearchFilter] = useState({});

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchPayments();
      AddActions()
    }, 500);
    return () => clearTimeout(debounceTimer);
  }, [currentPage, pageSize, searchFilter]);


  const onPageChange = (pageNumber, noOfItem) => {
    console.log({ pageNumber, noOfItem });
    setCurrentPage(() => pageNumber);
    setPageSize(() => noOfItem);
  };

  const handleFilter = (val, key) => {
    console.log(val, key);
    switch (key) {
      case 'email':
        setSearchFilter({ ...searchFilter, email: val })
        break;
      case 'helperName':
        setSearchFilter({ ...searchFilter, firstName: val, lastName: val })
        break;
      case 'paymentType':
        setSearchFilter({ ...searchFilter, paymentType: val })
        break;
      default:
        break;
    }
    setCurrentPage(0)
  };

  const handleDateChange = (date, dateArray) => {
    // console.log({ date, dateArray });
    if (dateArray.length > 0) {
      const { startOfDay, endOfDay } = parseFilterDate(dateArray)

      setSearchFilter({ ...searchFilter, startDate: startOfDay, endTime: endOfDay })
      setCurrentPage(0)
    }
  };

  const handleSelectChange = (value, option, type) => {
    console.log({ value, option, type });
    setCurrentPage(0)
    setSearchFilter({ ...searchFilter, [type]: value })
  };

  const viewPaymentDetails = (item) => {
    console.log({ item });
    navigate(`/admin/payments/paymentDetails/${item.paymentId}`, { state: item });
  };

  function AddActions() {
    setActions([
      {
        label: "view",
        clickAction: viewPaymentDetails,
        variant: "success",
      },
    ]);
  }

  async function fetchPayments() {
    dispatch(START_LOADING());
    try {
      const filterArr = Object.entries(searchFilter);
      const searchQuery = filterArr.reduce((str, ele) => {
        if (ele[1]) return str += `&${ele[0]}=${ele[1]}`;
        return str;
      }, '');
      const response = await getUserPaymentDetails(currentPage, pageSize, searchQuery);
      setData(response.data.content);
      setTotalItem(response.data.totalRecords);
    } catch (error) {
      // console.error(error);
      return;
    } finally {
      dispatch(STOP_LOADING());
    }

  }

  return (
    <Container className="bg-white" style={{ padding: "1rem" }}>
      <Container className="d-flex justify-content-between mb-2 bg-white p-2">
        <h3 className="heading"> Payment Details</h3>
      </Container>
      <hr></hr>
      <Container className="service-list-table" style={{ padding: "0" }}>
        <ReusableTable
          columns={PaymentsColumnsOldTable}
          data={data}
          actions={actions}
          handleColumnFilter={handleFilter}
          handleDateChange={handleDateChange}
          handleSelectChange={handleSelectChange}
        ></ReusableTable>
        <PaginationComponent
          onPageChange={onPageChange}
          itemsPerPage={pageSize}
          totalItems={totalItem}
          pageNo={currentPage}
          onPageSizeChange={setPageSize}
        ></PaginationComponent>
      </Container>
    </Container>
  );
}
