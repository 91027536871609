import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom";
import LoginPage from "./pages/account/loginPage";
import HomePage from "./pages/home/homePage";
import Dashboard from "./pages/dashboard/dasboard";
import DashboardHome from "./pages/dashboard/dashboadHome";
import TaskerList from "./pages/dashboard/taskerList";
import Customer from "./pages/Customer";
import Services from "./pages/serviceManagement/Service";
import ServiceCategory from "./pages/serviceManagement/ServiceCategory";
import Question from "./pages/serviceManagement/Questions";
import PostalCode from "./pages/LocationManagement/PostalCode";
import ServiceDetials from "./pages/serviceManagement/ServiceDetails";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToolsSettings from "./pages/HelperSettings/ToolsSettings";
import LanguageSettings from "./pages/HelperSettings/LanguageSettings";
import VehicleSettings from "./pages/HelperSettings/VehicleSettings";
import QuickFactSettings from "./pages/HelperSettings/QuickFactSettings";
import Tasks from "./pages/Tasks/Tasks";
import TaskDetails from "./pages/Tasks/TaskDetails";
import UserDetails from "./pages/dashboard/UserDetails";
import Payments from "./pages/Payments/Payments";
import PaymentDetails from "./pages/Payments/PaymentDetails";
import Payout from "./pages/Payouts/Payout";
import ForgotPassword from "./pages/account/ForgotPassword";
import SkillTrades from "./pages/skillTrade/SkillTrades";
import SkillTradeDetails from "./pages/skillTrade/SkillTradeDetails";
import { UPDATE_ROUTELIST } from "./redux/Slice/adminSlice";
import { useDispatch } from "react-redux";

function App() {
  const dispatch = useDispatch();
  dispatch(UPDATE_ROUTELIST([]))
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />}>
          <Route route="/login" index element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/admin" element={<Dashboard />}>
            <Route index path="/admin/dashboard" element={<DashboardHome />} />
            <Route path="/admin/helper" element={<TaskerList />} />
            <Route path="/admin/helper/userDetails/:id" element={<UserDetails />} />
            <Route path="/admin/customer" element={<Customer />} />
            <Route path="/admin/servicesCategory/:id" element={<Services />} />
            <Route path="/admin/servicesCategory" element={<ServiceCategory />} />
            <Route path="/admin/services" element={<Services />} />
            <Route path="/admin/services/serviceDetails/:id" element={<ServiceDetials />} />
            {/* <Route path="/admin/locations" element={<Question />} /> */}
            <Route path="/admin/postalCode" element={<PostalCode />} />
            <Route path="/admin/settings-tools" element={<ToolsSettings />} />
            <Route path="/admin/settings-language" element={<LanguageSettings />} />
            <Route path="/admin/settings-vehicle" element={<VehicleSettings />} />
            <Route path="/admin/settings-quick-fact" element={<QuickFactSettings />} />
            <Route path="/admin/tasks" element={<Tasks />} />
            <Route path="/admin/tasks/taskDetails/:id" element={<TaskDetails />} />
            <Route path="/admin/skillTrade" element={<SkillTrades />} />
            <Route path="/admin/skillTrade/skillTradeDetails" element={<SkillTradeDetails />} />
            <Route path="/admin/payments" element={<Payments />} />
            <Route path="/admin/payments/paymentDetails/:id" element={<PaymentDetails />} />
            <Route path="/admin/payouts" element={<Payout />} />
          </Route>
        </Route>
        {/* Add more routes for other pages */}
      </Routes>
      <ToastContainer theme="colored" />
    </>
  );
}

export default App;
