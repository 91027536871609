import { useEffect, useState } from "react";

import { Button, Container, Form, Card, Row, Col, Stack, ListGroup } from "react-bootstrap";
import Select from 'react-select';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LocationGridRow from "./LocationGridRow";
import { enablePostalCode, disablePostalCode, getPostalCodeByCityId, deletePostalCodesById } from "../../../api/locationApi";
import {
  LOAD_POSTAL_CODE,
  CHANGE_POSTAL_CODE_IS_ACTIVE,
  FILTER_POSTAL_CODE,
  UPDATE_POSTAL_CODE,
  CLEAR_POSTAL_CODES
} from "../../../redux/Slice/locationSlice";
import { START_LOADING, STOP_LOADING } from "../../../redux/Slice/loaderSlice";
import ShowModal from "../../core/Modal";
import { notifySuccess, notifyError, notifyInfo, notifyWarning } from '../../../api/toasterService';
import UpdateLocationName from "./UpdateLocationName";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";

export default function PostalCard(props) {
  const postalCodeList = useSelector((state) => state.location).filteredPostalCode;
  const totalPostalCodeList = useSelector((state) => state.location).totalPostalCodes;
  const selectedPostalCodeList = useSelector((state) => state.location).selectedPostalCode;
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [checkedItems, setCheckedItems] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const handleCheckboxChange = (event, location) => {
    console.log({ location });
    const { name, checked } = event.target;
    const updatedLocation = { ...location, isChecked: checked }

    const updatedFilteredPostalCode = postalCodeList.map(postalCode => {
      if (postalCode.id === updatedLocation.id) {
        return { ...postalCode, ...updatedLocation };
      }
      return postalCode;
    });
    const updatedTotalPostalCodes = totalPostalCodeList.map(postalCode => {
      if (postalCode.id === updatedLocation.id) {
        return { ...postalCode, ...updatedLocation };
      }
      return postalCode;
    });
    dispatch(CHANGE_POSTAL_CODE_IS_ACTIVE(({ updatedLocation, updatedFilteredPostalCode, updatedTotalPostalCodes })));
  };

  const categorizedPostalCodeList = (postalCodes) => {
    return postalCodes.reduce((acc, postalCode) => {
      const { cityId } = postalCode;
      if (!acc[cityId]) {
        acc[cityId] = [];
      }
      acc[cityId].push(postalCode);
      return acc;
    }, {});
  }

  async function fetchPostalCode(cityId) {
    let postalCodes = await getPostalCodeByCityId(cityId);
    const addIsActive = postalCodes.data.map(postalCode => {
      return { ...postalCode, isChecked: false }
    })
    dispatch(UPDATE_POSTAL_CODE(addIsActive));
  }

  const handleEnable = async (ev) => {
    dispatch(CLEAR_POSTAL_CODES());
    setSearchText('')
    const categorizedPostalCodes = categorizedPostalCodeList(postalCodeList);

    const enablePostalCodesId = Object.keys(categorizedPostalCodes).map(async (key) => {
      const postalCodesQuery = categorizedPostalCodes[key].filter(city => city.isChecked)
        .map(postal => postal.id)
      // .reduce((postalcodeIds, city) => postalcodeIds + `postalcodeIds=${city.id}&`, '')
      dispatch(START_LOADING());
      if (postalCodesQuery) {
        // await enablePostalCode(`cityId=${key}&${postalCodesQuery}`);
        await enablePostalCode({ cityId: key, postalCodeIds: postalCodesQuery });
      }
      await fetchPostalCode(key)
      dispatch(STOP_LOADING());

      return {
        cityId: key,
        postalCodesQuery
      }
    })
  };

  const handleDisable = async (ev) => {
    dispatch(CLEAR_POSTAL_CODES());
    setSearchText('')
    const categorizedPostalCodes = categorizedPostalCodeList(postalCodeList);

    const disableSuperPostalCodesId = Object.keys(categorizedPostalCodes).map(async (key) => {
      const postalCodesQuery = categorizedPostalCodes[key].filter(city => city.isChecked)
        .map(postal => postal.id)
      // .reduce((postalcodeIds, city) => postalcodeIds + `postalCodeIds=${city.id}&`, '')
      dispatch(START_LOADING());
      if (postalCodesQuery) {
        // await disablePostalCode(`cityId=${key}&${postalCodesQuery}`);
        await disablePostalCode({ cityId: key, postalCodeIds: postalCodesQuery });
      }
      await fetchPostalCode(key)
      dispatch(STOP_LOADING());
      return {
        cityId: key,
        postalCodesQuery
      }
    })
  };

  const handleChange = (ev) => {
    const searchText = ev.target.value;
    setSearchText(searchText)
    const filteredPostalCodes = postalCodeList.filter(postalCode =>
      postalCode.postalCode.toLowerCase().includes(searchText.toLowerCase())
    );
    dispatch(FILTER_POSTAL_CODE({ filteredPostalCodes, searchText }));
  };

  const handleDeletePostalCode = (location) => {
    setSelectedLocation(location)
    setShowDeleteModal(true);
  };

  const deletePostalCode = async () => {
    const deleteParams = `cityId=${selectedLocation.cityId}&postalCodeId=${selectedLocation.id}`
    await deletePostalCodesById(deleteParams)
    dispatch(CLEAR_POSTAL_CODES());

    const categorizedPostalCodes = categorizedPostalCodeList(postalCodeList);
    const disableSuperPostalCodesId = Object.keys(categorizedPostalCodes).map(async (key) => {
      dispatch(START_LOADING());
      await fetchPostalCode(key)
      dispatch(STOP_LOADING());
      return {
        cityId: key,
      }
    })
    setShowDeleteModal(false);
  };

  const handleAllCheckboxChange = (event, location) => {
    console.log({ location });
    const { name, checked } = event.target;
    const updatedLocation = {}

    const updatedFilteredPostalCode = postalCodeList.map(postalCode => {
      return { ...postalCode, isChecked: checked };
    });
    const updatedTotalPostalCodes = totalPostalCodeList.map(postalCode => {
      return { ...postalCode, isChecked: checked };
    });
    dispatch(CHANGE_POSTAL_CODE_IS_ACTIVE(({ updatedLocation, updatedFilteredPostalCode, updatedTotalPostalCodes })));
  };

  const handleUpdatePostalCode = (location) => {
    setSelectedLocation(location)
    setShowUpdateModal(true);
  };

  const LocationRow = ({ index, style }) => {
    console.log({ index, style });
    const tmp = {
      ...style,
      // position: "",
      // height: "",
      // top: index * 10
    }
    // console.log({ tmp });
    return (index >= 0 &&
      <div style={tmp}>
        <LocationGridRow
          location={postalCodeList[index]}
          handleCheckboxChange={handleCheckboxChange}
          populateLocation={(l) => { }}
          key={index}
          deleteLocation={handleDeletePostalCode}
          updateLocation={handleUpdatePostalCode}
        ></LocationGridRow>
      </div>)
  };

  return (
    <Card style={{}} className="mb-3">
      <Card.Header>
        {props.title}
      </Card.Header>
      <div className="d-flex justify-content-center align-items-center w-100"
        style={{ padding: "10px" }}
      >
        <div className="w-100 mb-3 d-flex justify-content-center align-items-center">
          <input
            type="text"
            value={searchText}
            onChange={(ev) => handleChange(ev)}
            placeholder={`Search ${props.title}`}
            className="w-100 form-control"
          />
        </div>
      </div>
      <Stack style={{ "textAlign": "left", "height": "200px", paddingRight: "5px" }}>
        {
          (postalCodeList.length > 0 &&
            <Container style={{ marginBottom: "10px" }}>
              <Row>
                <Form.Group controlId="formBasicCheckbox" >
                  <div className="d-flex align-items-center" style={{ marginRight: "5px" }}>
                    <Form.Check
                      type="checkbox"
                      name={'test'}
                      onChange={(ev) => handleAllCheckboxChange(ev)}
                      checked={postalCodeList.length > 0 && (postalCodeList.filter(country => !country.isChecked).length === 0) ? true : false}
                    />
                    <span style={{ marginLeft: "10px" }}>Select All</span>
                  </div>
                </Form.Group>
              </Row>
            </Container>
          )
        }
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="List"
              height={170}
              itemCount={postalCodeList.length}
              itemSize={35}
              width={width}
            >
              {LocationRow}
            </List>
          )}
        </AutoSizer>
        {/* {
          postalCodeList.map((location, index) => {
            return <LocationGridRow
              // setBorder={selectedLocation && selectedLocation.id == location.id ? true : false}
              location={location}
              handleCheckboxChange={handleCheckboxChange}
              populateLocation={(l) => { }}
              key={index}
              deleteLocation={handleDeletePostalCode}
              updateLocation={handleUpdatePostalCode}
            ></LocationGridRow>
          })
        } */}
      </Stack>
      <div className="d-flex justify-content-between m-2">
        <Button variant="primary" onClick={handleEnable}
          size="sm"
          disabled={!(postalCodeList.filter(postalCode => postalCode.isChecked).length > 0)}
        >
          Enable
        </Button>{" "}
        <Button variant="danger" onClick={handleDisable}
          size="sm"
          disabled={!(postalCodeList.filter(postalCode => postalCode.isChecked).length > 0)}
        >
          Disable
        </Button>
      </div>
      <ShowModal
        modalheading="Delete Postal Code"
        show={showDeleteModal}
        classname={"add-service-modal"}
        body="Are you sure you want to delete this postal code"
        handleClose={() => setShowDeleteModal(false)}
        showFooter={true}
        Submit={deletePostalCode}
      ></ShowModal>
      <ShowModal
        modalheading="Update Postal Code"
        show={showUpdateModal}
        classname={"add-service-modal"}
        body={
          <UpdateLocationName
            locationType={'Postal Code'}
            location={selectedLocation}
            isAdded={() => setShowUpdateModal(false)}
          ></UpdateLocationName>
        }
        handleClose={() => setShowUpdateModal(false)}
      ></ShowModal>
    </Card>
  );
}
