import { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Card,
  Row,
  Col,
  Stack,
  ListGroup,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import LocationGridRow from "./LocationGridRow";
import {
  enableCountry,
  disableCountry,
  getCountryList,
  getProvinceByCountryId,
} from "../../../api/locationApi";
import {
  CHANGE_COUNTRY_IS_ACTIVE,
  LOAD_COUNTRY,
  LOAD_PROVINCE,
  FILTER_COUNTRY
} from "../../../redux/Slice/locationSlice";
import { START_LOADING, STOP_LOADING } from "../../../redux/Slice/loaderSlice";
import { notifySuccess, notifyError, notifyInfo, notifyWarning } from '../../../api/toasterService';

export default function CountryCard(props) {
  const countryList = useSelector((state) => state.location).filteredCountry;
  const provinceList = useSelector((state) => state.location).filteredProvince;
  const totalCountryList = useSelector((state) => state.location).totalCountry;
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(null);
  const [checkedItems, setCheckedItems] = useState({});
  const [searchText, setSearchText] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    fetchCountry();
  }, []);
  async function fetchCountry() {
    try {
      dispatch(START_LOADING());
      let country = await getCountryList();
      if (country?.data) {
        const isCheckedAdded = country.data.map(country => {
          return { ...country, isChecked: false }
        })
        dispatch(LOAD_COUNTRY(isCheckedAdded));
      }
    } catch (error) {

    } finally {
      dispatch(STOP_LOADING());
    }
  }

  const handleCheckboxChange = (event, location) => {
    const { name, checked } = event.target;
    const updatedLocation = { ...location, isChecked: checked }

    const updatedFilteredCountry = countryList.map(country => {
      if (country.id === updatedLocation.id) {
        return { ...country, ...updatedLocation };
      }
      return country;
    });
    const updatedTotalCountry = totalCountryList.map(country => {
      if (country.id === updatedLocation.id) {
        return { ...country, ...updatedLocation };
      }
      return country;
    });
    dispatch(CHANGE_COUNTRY_IS_ACTIVE(({ updatedLocation, updatedFilteredCountry, updatedTotalCountry })));
  };

  const populateProvince = (country) => {
    if (country.isActive) {
      setSelectedLocation(country)
      fetchProvince(country.id);
    }
    else
      notifyWarning('Please enable this country')
  };

  async function fetchProvince(countryId) {
    try {
      dispatch(START_LOADING());
      let provinces = await getProvinceByCountryId(countryId);
      provinces = provinces.data.map((province) => { return { ...province, countryId, isChecked: false } })
      // let updatedProvinceList = provinceList.filter(item => item.countryId !== countryId);
      // updatedProvinceList = [...updatedProvinceList, ...provinces]
      dispatch(LOAD_PROVINCE(provinces));
    } catch (error) {

    } finally {
      dispatch(STOP_LOADING());
    }
  }

  const handleChange = (ev) => {
    const searchText = ev.target.value;
    setSearchText(searchText)
    console.log({ text: ev.target.value, countryList });
    const filteredCountries = countryList.filter(country =>
      country.countryName.toLowerCase().includes(searchText.toLowerCase())
    );
    console.log({ filteredCountries });
    dispatch(FILTER_COUNTRY({ filteredCountries, searchText }));
  };

  const handleEnable = async (ev) => {
    dispatch(START_LOADING());
    const enableCountryId = countryList.filter(country => country.isChecked)
      .reduce((countryIds, country) => countryIds + `countryIds=${country.id}&`, '')
    await enableCountry(enableCountryId);
    fetchCountry();
    dispatch(STOP_LOADING());
    setSearchText('')
  };

  const handleDisable = async (ev) => {
    dispatch(START_LOADING());
    const disableCountryId = countryList.filter(country => country.isChecked)
      .reduce((countryIds, country) => countryIds + `countryIds=${country.id}&`, '')
    await disableCountry(disableCountryId);
    fetchCountry();
    dispatch(STOP_LOADING());
    setSearchText('')
  };

  const handleAllCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const updatedLocation = {}

    const updatedFilteredCountry = countryList.map(country => {
      return { ...country, isChecked: checked };
    });
    const updatedTotalCountry = totalCountryList.map(country => {
      return { ...country, isChecked: checked };
    });
    dispatch(CHANGE_COUNTRY_IS_ACTIVE(({ updatedLocation, updatedFilteredCountry, updatedTotalCountry })));
  };

  return (
    <Card style={{}} className="">
      <Card.Header>{props.title}</Card.Header>
      <div
        className="d-flex justify-content-center align-items-center w-100"
        style={{ padding: "10px" }}
      >
        <div className="w-100 mb-3 d-flex justify-content-center align-items-center">
          <input
            type="text"
            value={searchText}
            onChange={(ev) => handleChange(ev)}
            placeholder={`Search ${props.title}`}
            className="w-100 form-control"
          />
        </div>
      </div>
      <Stack
        style={{ textAlign: "left", overflow: "overlay", height: "200px" }}
      >
        {
          (countryList.length > 0) &&
          <Container style={{ marginBottom: "10px" }}>
            <Row>
              <Form.Group controlId="formBasicCheckbox" >
                <div className="d-flex align-items-center" >
                  <Form.Check
                    type="checkbox"
                    name={'test'}
                    onChange={(ev) => handleAllCheckboxChange(ev)}
                    checked={countryList.length > 0 && (countryList.filter(country => !country.isChecked).length === 0) ? true : false}
                  />
                  <span style={{ marginLeft: "10px" }}>Select All</span>
                </div>
              </Form.Group>
            </Row>
          </Container>
        }

        {countryList?.map((location, index) => {
          // console.log({ location });
          return (
            <LocationGridRow
              setBorder={selectedLocation && selectedLocation.id == location.id ? true : false}
              location={location}
              populateLocation={(l) => populateProvince(l)}
              handleCheckboxChange={handleCheckboxChange}
              key={index}
              checked={checkedItems[location.id]}
              deleteLocation={() => { }}
              updateLocation={() => { }}
            ></LocationGridRow>
          );
        })}
      </Stack>
      <div className="d-flex justify-content-between m-2">
        <Button variant="primary" onClick={handleEnable}
          size="sm"
          disabled={(countryList.filter(country => country.isChecked).length == 0)}
        >
          Enable
        </Button>{" "}
        <Button variant="danger" onClick={handleDisable}
          size="sm"
          disabled={(countryList.filter(country => country.isChecked).length == 0)}
        >
          Disable
        </Button>
      </div>
    </Card>
  );
}
