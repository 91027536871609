import { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { updateServicePrice } from "../../../api/adminApi";
import { START_LOADING, STOP_LOADING } from "../../../redux/Slice/loaderSlice"
import { useDispatch } from "react-redux";
import FormLabelStar from "../../core/FormLabelStar";

const InitialForm = {
  minimumPrice: 0,
  maximumPrice: 0,
  lowPriceLimit: 0,
  suggestedPriceLimit: 0,
  serviceId: ""
};
export default function UpdateServicePrice({ isAdded, servicePrice, serviceId }) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ ...InitialForm, serviceId });
  const [validated, setValidated] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [lastPriceChangeName, setLastPriceChangeName] = useState('');

  useEffect(() => {
    if (servicePrice) {
      setFormData({
        ...servicePrice,
        serviceId
      })
    }
  }, [servicePrice])

  const handleChange = (event) => {
    let { name, value } = event.target;
    setLastPriceChangeName(name)
    if (value.startsWith('-')) {
      value = value.slice(1);
    }
    const parsedValue = parseInt(value, 10);

    if (parsedValue < 0 || parsedValue > 100000) {
      setValidationError('Please enter a valid price between 0 and 100,000.');
    } else {
      setValidationError('');
      setFormData({ ...formData, [name]: parsedValue });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Validate the form
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    if (formData.minimumPrice > formData.maximumPrice) {
      setLastPriceChangeName("maximumPrice")
      setValidationError('Maximum price is less than minimum price');
    } else if (formData.lowPriceLimit < formData.minimumPrice || formData.lowPriceLimit > formData.maximumPrice) {
      setLastPriceChangeName("lowPriceLimit")
      setValidationError('Low price limit should be between minimum and maximum price');
    }
    else if (formData.suggestedPriceLimit < formData.minimumPrice || formData.suggestedPriceLimit > formData.maximumPrice) {
      setLastPriceChangeName("suggestedPriceLimit")
      setValidationError('Suggested price limit should be between minimum and maximum price');
    } else {
      dispatch(START_LOADING());
      await updateServicePrice(formData)
      dispatch(STOP_LOADING());
      isAdded(true);
    }
  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Minimum Price<FormLabelStar /></Form.Label>
          <Form.Control
            required
            type="number"
            name="minimumPrice"
            value={formData.minimumPrice}
            onChange={handleChange}
            placeholder="Please enter minimum price"
          />
          {
            lastPriceChangeName === 'minimumPrice' && validationError &&
            <Form.Text className="text-danger">{validationError}</Form.Text>
          }
          <Form.Control.Feedback type="invalid">
            Please enter minimum price.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Maximum Price<FormLabelStar /></Form.Label>
          <Form.Control
            type="number"
            name="maximumPrice"
            value={formData.maximumPrice}
            onChange={handleChange}
            placeholder="Please enter maximum price"
            required
          />
          {
            lastPriceChangeName === 'maximumPrice' && validationError &&
            <Form.Text className="text-danger">{validationError}</Form.Text>
          }
          <Form.Control.Feedback type="invalid">
            Please enter maximum price.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Low Price Limit<FormLabelStar /></Form.Label>
          <Form.Control
            type="number"
            name="lowPriceLimit"
            value={formData.lowPriceLimit}
            onChange={handleChange}
            placeholder="Please enter low price limit"
            required
          />
          {
            lastPriceChangeName === 'lowPriceLimit' && validationError &&
            <Form.Text className="text-danger">{validationError}</Form.Text>
          }
          <Form.Control.Feedback type="invalid">
            Please enter low price limit
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Suggested Price Limit<FormLabelStar /></Form.Label>
          <Form.Control
            type="number"
            name="suggestedPriceLimit"
            value={formData.suggestedPriceLimit}
            onChange={handleChange}
            placeholder="Please enter suggested price limit"
            required
          />
          {
            lastPriceChangeName === 'suggestedPriceLimit' && validationError &&
            <Form.Text className="text-danger">{validationError}</Form.Text>
          }
          <Form.Control.Feedback type="invalid">
            Please enter suggested price limit.
          </Form.Control.Feedback>
        </Form.Group>

        <div className="d-flex justify-content-between m-2">
          <Button variant="primary" type="submit">
            Submit
          </Button>
          <Button variant="outline-secondary" type="reset"
            onClick={() => { setFormData({ ...InitialForm, serviceId: servicePrice.serviceId }) }}
          >
            Reset
          </Button>
        </div>
      </Form >
    </>
  );
}
