import React, { useState, useEffect } from 'react';
import { Pagination } from '@mui/material';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

// const items = Array.from({ length: 100 }, (_, index) => `Item ${index + 1}`);

// Component to display paginated data
const PaginationComponent = ({ onPageChange, onPageSizeChange, totalItems, itemsPerPage, pageNo }) => {

  console.log({ totalItems, itemsPerPage, pageNo });
  const [currentPage, setCurrentPage] = useState(pageNo || 0);
  const [pageSize, setPageSize] = useState(itemsPerPage);

  const startIndex = ((currentPage - 1) * itemsPerPage) || 0;
  const endIndex = startIndex + itemsPerPage;

  // const currentItems = items.slice(startIndex, endIndex);

  // useEffect(() => {
  //   onPageChange(currentPage, pageSize);
  // }, [currentPage, pageSize]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage((newPage - 1) || 0);
  };
  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(0);
  };

  return (
    <div className='d-flex justify-content-end'>
      {/* <ul>
        {currentItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul> */}
      <div className="d-flex mb-3 " style={{ marginRight: '1.5rem' }}>
        <DropdownButton id="page-size-dropdown" title={`Page Size: ${itemsPerPage}`}>
          <Dropdown.Item onClick={() => onPageChange(0, 10)}>10</Dropdown.Item>
          <Dropdown.Item onClick={() => onPageChange(0, 20)}>20</Dropdown.Item>
          <Dropdown.Item onClick={() => onPageChange(0, 30)}>30</Dropdown.Item>
          <Dropdown.Item onClick={() => onPageChange(0, 40)}>40</Dropdown.Item>
          <Dropdown.Item onClick={() => onPageChange(0, 100)}>100</Dropdown.Item>
        </DropdownButton>
      </div>
      <Pagination
        count={Math.ceil(totalItems / itemsPerPage)}
        page={pageNo + 1}
        onChange={(event, newPage) => onPageChange((newPage - 1) || 0, itemsPerPage)}
        variant="outlined" shape="rounded"
      />
    </div>
  );
};

export default PaginationComponent;
