import { useEffect, useState } from "react";
import { Button, Container, Form, Card, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { useDispatch } from "react-redux";
import ShowModal from "../../components/core/Modal";
// import { START_LOADING, STOP_LOADING } from "../../redux/Slice/loaderSlice";
import LocationCard from "./LocationCard";
import AddNewProvince from "../../components/Admin/locationManagement/AddNewProvince";
import AddNewSuperCity from "../../components/Admin/locationManagement/AddNewSuperCity";
import AddNewPostalCode from "../../components/Admin/locationManagement/AddNewPostalCode";
import AddNewCity from "../../components/Admin/locationManagement/AddNewCity";
import AddNewCountry from "../../components/Admin/locationManagement/AddNewCountry";
import SuperCityCard from "../../components/Admin/locationManagement/SuperCityCard";
import CityCard from "../../components/Admin/locationManagement/CityCard";
import PostalCard from "../../components/Admin/locationManagement/PostalCard";

import UploadPostalData from "../../components/Admin/locationManagement/UploadPostalData";
import CountryCard from "../../components/Admin/locationManagement/CountryCard";
import ProvinceCard from "../../components/Admin/locationManagement/ProvinceCard";

export default function PostalCode() {
  const dispatch = useDispatch();
  const [showProvinceModal, setShowProvinceModal] = useState(false);
  const [showCountryModal, setShowCountryModal] = useState(false);
  const [showSuperCityModal, setShowSuperCityModal] = useState(false);
  const [showCityModal, setShowCityModal] = useState(false);
  const [showPostalCodeModal, setShowPostalCodeModal] = useState(false);
  const [showUploadDataModal, setShowUploadDataModal] = useState(false);

  //Open Modal to add new Service
  const newServiceAdded = () => {
    setShowProvinceModal(false);
    setShowSuperCityModal(false);
    setShowPostalCodeModal(false);
    setShowCountryModal(false);
    setShowCityModal(false);
    setShowUploadDataModal(false);
    // fetchService();
  };

  const openCityModal = async () => {
    setShowSuperCityModal(false);
    setShowCityModal(true);
  };

  return (
    <Container className="bg-white" style={{ padding: "1rem" }}>
      <Container className="d-flex justify-content-between mb-2 bg-white p-2">
        <h3 className="heading"> Postal Code Management</h3>
      </Container>
      <Container className="mb-2">
        <Card >
          {/* <Card.Header className="d-flex justify-content-between">
            <legend className="mb-0 text-end">
              <Button onClick={() => setShowUploadDataModal(true)} size="sm">
                Upload Postal Data
              </Button>
            </legend>
          </Card.Header> */}
          <Card.Body className="">
            <div className="d-flex justify-content-center align-items-center w-100">
              {/* <div className="w-100 mb-3 d-flex justify-content-center align-items-center">
                <label
                  htmlFor="selectCountry"
                  className=""
                  style={{ "marginRight": "10px" }}
                >
                  <Card.Title>Country</Card.Title>
                </label>
                <Select
                  id="selectCountry"
                  value={selectedCountry}
                  onChange={(ev) => handleChange(ev, "country")}
                  options={countryList}
                  isSearchable
                  placeholder="Select Country"
                  className="w-50"
                />
              </div> */}
              {/* <div className="w-100 mb-3 d-flex justify-content-center align-items-center">
                <label
                  htmlFor="selectDropdown"
                  className=""
                  style={{ "marginRight": "10px" }}
                >
                  <Card.Title>Province</Card.Title>
                </label>
                <Select
                  id="selectDropdown"
                  value={selectedProvince}
                  onChange={(ev) => handleChange(ev, "province")}
                  options={provinceList}
                  isSearchable
                  placeholder="Select Province"
                  className="w-50"
                />
              </div> */}
            </div>
            <Container className="">
              <Row className="text-center location-card-row">
                <Col>
                  <CountryCard
                    title={"Country"}
                  ></CountryCard>
                </Col>
                <Col>
                  <ProvinceCard
                    title={"Province"}
                  ></ProvinceCard>
                </Col>
                <Col>
                  <SuperCityCard
                    title={"Super City"}
                  ></SuperCityCard>
                </Col>
                <Col>
                  <CityCard
                    title={"City"}
                  ></CityCard>
                </Col>
                <Col>
                  <PostalCard
                    title={"Postal Code"}
                  ></PostalCard>
                </Col>
              </Row>
            </Container>
            <Container className="">
              <Row className="text-center">
                <Col>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => setShowCountryModal(true)}
                  >
                    Add Country
                  </Button>
                </Col>

                <Col>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => setShowProvinceModal(true)}
                  >
                    Add New Province
                  </Button>
                </Col>

                <Col>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => setShowSuperCityModal(true)}
                  >
                    Add New Super City
                  </Button>
                </Col>

                <Col>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => setShowCityModal(true)}
                  >
                    Add New City
                  </Button>
                </Col>

                <Col>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => setShowPostalCodeModal(true)}
                  >
                    Add New Postal Code
                  </Button>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </Container>
      <ShowModal
        modalheading={"Add New Country"}
        show={showCountryModal}
        classname={"add-service-modal"}
        body={<AddNewCountry isAdded={newServiceAdded}></AddNewCountry>}
        handleClose={() => {
          setShowCountryModal(false);
        }}
      ></ShowModal>
      <ShowModal
        modalheading={"Add New Province"}
        show={showProvinceModal}
        classname={"add-service-modal"}
        body={<AddNewProvince isAdded={newServiceAdded}></AddNewProvince>}
        handleClose={() => {
          setShowProvinceModal(false);
        }}
      ></ShowModal>
      <ShowModal
        modalheading={"Add New Super City"}
        show={showSuperCityModal}
        classname={"add-service-modal"}
        body={<AddNewSuperCity isAdded={newServiceAdded}></AddNewSuperCity>}
        handleClose={() => {
          setShowSuperCityModal(false);
        }}
      ></ShowModal>
      <ShowModal
        modalheading={"Add New City"}
        show={showCityModal}
        classname={"add-service-modal"}
        body={
          <AddNewCity
            isAdded={newServiceAdded}
            openCityModal={openCityModal}
          ></AddNewCity>
        }
        handleClose={() => {
          setShowCityModal(false);
        }}
      ></ShowModal>
      <ShowModal
        modalheading={"Add New Postal Code"}
        show={showPostalCodeModal}
        classname={"add-service-modal"}
        body={<AddNewPostalCode isAdded={newServiceAdded}></AddNewPostalCode>}
        handleClose={() => {
          setShowPostalCodeModal(false);
        }}
      ></ShowModal>
      <ShowModal
        modalheading={"Upload Postal Data"}
        show={showUploadDataModal}
        classname={"add-service-modal"}
        body={<UploadPostalData isAdded={newServiceAdded}></UploadPostalData>}
        handleClose={() => {
          setShowUploadDataModal(false);
        }}
      ></ShowModal>
    </Container>
  );
}
