import { Avatar, Box } from "@mui/material";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { v4 as uuidv4 } from 'uuid';
import 'react-tooltip/dist/react-tooltip.css'

export default function DetailsCard(props) {
  const tooltipId = uuidv4();
  function truncateString(str, maxLength) {
    if (str && str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  }

  return (
    <>
      <div class="col" style={{ height: "70px" }}>
        <h5 style={{ fontSize: "14px", fontWeight: "700" }}>{props.title}</h5>
        {props.image ?
          (<Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Avatar
              alt={props.value}
              src={props.image}
              style={{ marginRight: "5px" }}
              sx={{ height: '30px', width: '30px' }} />
            <h6 style={{ color: props.color || "#6d7886", fontSize: "12px" }} data-tooltip-id={tooltipId}>
              {truncateString(props.value, props.length)}
            </h6>
          </Box>) :
          <h6 style={{ color: props.color || "#6d7886", fontSize: "12px" }} data-tooltip-id={tooltipId}>
            {truncateString(props.value, props.length)}
          </h6>
        }
        <ReactTooltip
          id={tooltipId}
          place="bottom"
        >
          <div style={{ maxWidth: '200px', whiteSpace: 'pre-wrap' }}>
            {props.value}
          </div>
        </ReactTooltip >
      </div>
    </>
  )
}