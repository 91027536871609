import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import React from 'react';
import { Breadcrumb } from 'antd';
const MyBreadCrumb = (props) => (
  <div class="row" style={{ paddingTop: "10px", paddingLeft: "20px" }}>
    <div class="col">
      <Breadcrumb
        separator=">"
        items={props.routeList}
      />
    </div><div class="col">
      <h3 className="text-center">{props.title}</h3>
    </div><div class="col">
    </div>
  </div>
);
export default MyBreadCrumb;