import { useState, useEffect } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { addNewHelperTool, editHelperTool } from "../../../api/adminApi";
import FormLabelStar from "../../core/FormLabelStar";
import { validationLength } from "../../../metaData/validationLength"

export default function AddNewHelperTool({ isAdded, tool }) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    id: "",
  });
  const isNameEmpty = formData.name.trim() === '';
  useEffect(() => {
    if (tool) {
      setFormData({
        name: tool.name,
        id: tool.id
      })
    }
  }, [tool])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    if (tool) {
      await editHelperTool(formData);
    } else {
      await addNewHelperTool(formData);
    }
    isAdded(true);
  };
  const handleReset = (e) => {
    setFormData({
      name: "",
      id: "",
    });

    setValidated(false);
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Helper Tool Name<FormLabelStar /></Form.Label>
        <Form.Control
          name="name"
          required
          value={formData.name}
          type="text"
          onChange={handleChange}
          placeholder="Enter Tool Name"
          maxLength={validationLength.helperSetting.tools.name.maxLength}
        />
        {formData.name?.length > validationLength.helperSetting.tools.name.maxLength - 1 && (
          <div className="province-name-validate">{validationLength.message(validationLength.helperSetting.tools.name.maxLength)}</div>
        )}
        <Form.Control.Feedback type="invalid">
          Please enter tool name.
        </Form.Control.Feedback>
        {/* <Form.Text className="text-muted">Enter the Category Name</Form.Text> */}
      </Form.Group>
      <div className="d-flex justify-content-between m-2">
        <Button variant="primary" type="submit" size="sm">
          Submit
        </Button>
        <Button variant="secondary" type="reset" size="sm" onClick={() => handleReset()}>
          Reset
        </Button>
      </div>
    </Form>
  );
}
