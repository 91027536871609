import { useState, useEffect } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { updateUserDetails } from "../../api/adminApi";
import { validationLength } from "../../metaData/validationLength"
const intialForm = {
  firstName: "",
  id: "",
  lastName: ""
}
export default function UpdateUserDetails({ isAdded, user }) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(intialForm);
  useEffect(() => {
    if (user) {
      setFormData({
        firstName: user.firstName,
        lastName: user.lastName,
        id: user.userId
      })
    }
  }, [user])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    await updateUserDetails(
      {
        userId: formData.id,
        firstName: formData.firstName,
        lastName: formData.lastName
      }
    );

    isAdded(true);
  };

  const handleReset = (e) => {
    setFormData(intialForm);
  };
  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          name="firstName"
          required
          value={formData.firstName}
          type="text"
          onChange={handleChange}
          placeholder="Enter First Name"
          maxLength={validationLength.user.fName.maxLength}
        />
        {formData.firstName.length > validationLength.user.fName.maxLength - 1 && (
          <div className="province-name-validate">{validationLength.message(validationLength.user.fName.maxLength)}</div>
        )}
        <Form.Control.Feedback type="invalid">
          Please enter first name.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          name="lastName"
          required
          value={formData.lastName}
          type="text"
          onChange={handleChange}
          placeholder="Enter Last Name"
          maxLength={validationLength.user.lName.maxLength}
        />
        {formData.lastName.length > validationLength.user.lName.maxLength - 1 && (
          <div className="province-name-validate">{validationLength.message(validationLength.user.lName.maxLength)}</div>
        )}
        <Form.Control.Feedback type="invalid">
          Please enter last name.
        </Form.Control.Feedback>
      </Form.Group>
      <div className="d-flex justify-content-between m-2">
        <Button variant="primary" type="submit" size="sm">
          Submit
        </Button>
        <Button variant="secondary" type="reset" size="sm"
          onClick={() => { handleReset() }}
        >
          Reset
        </Button>
      </div>
    </Form>
  );
}
