import { useEffect, useState } from "react";

import { Button, Container, Form, Card, Row, Col, Stack, ListGroup } from "react-bootstrap";
import Select from 'react-select';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LocationGridRow from "./LocationGridRow";
import { enableSuperCity, disableSuperCity, getCityBySuperCityId, getSuperCityByProvinceId, deleteSupercityById } from "../../../api/locationApi";
import {
  LOAD_CITY,
  LOAD_SUPERCITY,
  CHANGE_SUPER_CITY_IS_ACTIVE,
  FILTER_SUPER_CITY,
  CLEAR_SUPER_CITY,
  UPDATE_SUPERCITY
} from "../../../redux/Slice/locationSlice";
import { START_LOADING, STOP_LOADING } from "../../../redux/Slice/loaderSlice";
import ShowModal from "../../core/Modal";
import { notifySuccess, notifyError, notifyInfo, notifyWarning } from '../../../api/toasterService';
import UpdateLocationName from "./UpdateLocationName";

export default function SuperCityCard(props) {
  const superCityList = useSelector((state) => state.location).filteredSuperCity;
  const totalSuperCityList = useSelector((state) => state.location).totalSuperCity;
  const cityList = useSelector((state) => state.location).filteredCity;
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(null);
  const [checkedItems, setCheckedItems] = useState({});
  const [searchText, setSearchText] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedLocationActive, setSelectedLocationActive] = useState(null);

  useEffect(() => {
    setSelectedLocationActive(null)
  }, [superCityList])

  async function fetchCity(superCityId) {
    try {
      dispatch(START_LOADING());
      let cities = await getCityBySuperCityId(superCityId);
      cities = cities.data.map((city) => { return { ...city, isChecked: false } })

      // let updatedCityList = cityList.filter(item => item.superCityId !== superCityId);
      // updatedCityList = [...updatedCityList, ...cities]
      dispatch(LOAD_CITY(cities));
    } catch (error) {

    } finally {
      dispatch(STOP_LOADING());
    }
  }

  async function fetchSuperCity(provinceId) {
    let superCities = await getSuperCityByProvinceId(provinceId);
    superCities = superCities.data.map((superCity) => { return { ...superCity, isChecked: false } })
    dispatch(UPDATE_SUPERCITY(superCities));
  }

  const populateCity = (superCity) => {
    if (superCity.isActive) {
      setSelectedLocationActive(superCity)
      fetchCity(superCity.id);
    }
    else
      notifyWarning('Please enable this super city')
  };

  const handleCheckboxChange = (event, location) => {
    const { name, checked } = event.target;
    const updatedLocation = { ...location, isChecked: checked }

    const updatedFilteredSuperCity = superCityList.map(superCity => {
      if (superCity.id === updatedLocation.id) {
        return { ...superCity, ...updatedLocation };
      }
      return superCity;
    });
    const updatedTotalSuperCity = totalSuperCityList.map(superCity => {
      if (superCity.id === updatedLocation.id) {
        return { ...superCity, ...updatedLocation };
      }
      return superCity;
    });
    dispatch(CHANGE_SUPER_CITY_IS_ACTIVE(({ updatedLocation, updatedFilteredSuperCity, updatedTotalSuperCity })));
  };

  const handleChange = (ev) => {
    const searchText = ev.target.value;
    setSearchText(searchText)
    const filteredSuperCities = superCityList.filter(superCity =>
      superCity.superCityName.toLowerCase().includes(searchText.toLowerCase())
    );
    dispatch(FILTER_SUPER_CITY({ filteredSuperCities, searchText }));
  };

  const categorizedSuperCityList = (superCities) => {
    return superCities.reduce((acc, superCity) => {
      const { provinceId } = superCity;
      if (!acc[provinceId]) {
        acc[provinceId] = [];
      }
      acc[provinceId].push(superCity);
      return acc;
    }, {});
  }

  const handleEnable = async (ev) => {
    dispatch(CLEAR_SUPER_CITY());
    setSearchText('')
    const categorizedSuperCities = categorizedSuperCityList(superCityList);

    const enableSuperCitiesId = Object.keys(categorizedSuperCities).map(async (key) => {
      const superCitiesQuery = categorizedSuperCities[key].filter(superCity => superCity.isChecked)
        .reduce((superCityIds, superCity) => superCityIds + `supercityIds=${superCity.id}&`, '')
      dispatch(START_LOADING());
      if (superCitiesQuery) {
        await enableSuperCity(`provinceId=${key}&${superCitiesQuery}`);
      }
      await fetchSuperCity(key)
      dispatch(STOP_LOADING());

      return {
        provinceId: key,
        superCitiesQuery
      }
    })
  };

  const handleDisable = async (ev) => {
    dispatch(CLEAR_SUPER_CITY());
    setSearchText('')
    const categorizedSuperCities = categorizedSuperCityList(superCityList);

    const disableSuperCitiesId = Object.keys(categorizedSuperCities).map(async (key) => {
      const superCitiesQuery = categorizedSuperCities[key].filter(superCity => superCity.isChecked)
        .reduce((superCityIds, superCity) => superCityIds + `supercityIds=${superCity.id}&`, '')
      dispatch(START_LOADING());
      if (superCitiesQuery) {
        await disableSuperCity(`provinceId=${key}&${superCitiesQuery}`);
      }
      await fetchSuperCity(key)
      dispatch(STOP_LOADING());
      return {
        provinceId: key,
        superCitiesQuery
      }
    })
  };

  const handleDeleteSuperCity = (location) => {
    setSelectedLocation(location)
    setShowDeleteModal(true);
  };

  const deleteSuperCity = async () => {
    const deleteParams = `provinceId=${selectedLocation.provinceId}&supercityId=${selectedLocation.id}`
    await deleteSupercityById(deleteParams)
    dispatch(CLEAR_SUPER_CITY());
    const categorizedSuperCities = categorizedSuperCityList(superCityList);
    const disableSuperCitiesId = Object.keys(categorizedSuperCities).map(async (key) => {
      dispatch(START_LOADING());
      await fetchSuperCity(key)
      dispatch(STOP_LOADING());
      return {
        provinceId: key,
      }
    })
    setShowDeleteModal(false);
  };

  const handleAllCheckboxChange = (event, location) => {
    const { name, checked } = event.target;
    const updatedLocation = {}

    const updatedFilteredSuperCity = superCityList.map(superCity => {
      return { ...superCity, isChecked: checked };
    });
    const updatedTotalSuperCity = totalSuperCityList.map(superCity => {
      return { ...superCity, isChecked: checked };
    });
    dispatch(CHANGE_SUPER_CITY_IS_ACTIVE(({ updatedLocation, updatedFilteredSuperCity, updatedTotalSuperCity })));
  };

  const handleUpdateSuperCity = (location) => {
    setSelectedLocation(location)
    setShowUpdateModal(true);
  };

  return (
    <Card style={{}} className="mb-3">
      <Card.Header>
        {props.title}
      </Card.Header>
      <div className="d-flex justify-content-center align-items-center w-100"
        style={{ padding: "10px" }}
      >
        <div className="w-100 mb-3 d-flex justify-content-center align-items-center">
          <input
            type="text"
            value={searchText}
            onChange={(ev) => handleChange(ev)}
            placeholder={`Search ${props.title}`}
            className="w-100 form-control"
          />
        </div>
      </div>
      <Stack style={{ "textAlign": "left", "overflow": "overlay", "height": "200px" }}>
        {
          (superCityList.length > 0) &&
          <Container style={{ marginBottom: "10px" }}>
            <Row>
              <Form.Group controlId="formBasicCheckbox" >
                <div className="d-flex align-items-center" style={{ marginRight: "5px" }}>
                  <Form.Check
                    type="checkbox"
                    name={'test'}
                    onChange={(ev) => handleAllCheckboxChange(ev)}
                    checked={superCityList.length > 0 && (superCityList.filter(country => !country.isChecked).length === 0) ? true : false}
                  />
                  <span style={{ marginLeft: "10px" }}>Select All</span>
                </div>
              </Form.Group>
            </Row>
          </Container>
        }
        {
          superCityList.map((location, index) => {
            return <LocationGridRow
              setBorder={selectedLocationActive && selectedLocationActive.id == location.id ? true : false}
              location={location}
              populateLocation={(l) => populateCity(l)}
              handleCheckboxChange={handleCheckboxChange}
              key={index}
              checked={checkedItems[location.id]}
              deleteLocation={handleDeleteSuperCity}
              updateLocation={handleUpdateSuperCity}
            ></LocationGridRow>
          })
        }
      </Stack>
      <div className="d-flex justify-content-between m-2">
        <Button variant="primary" onClick={handleEnable}
          size="sm"
          disabled={!(superCityList.filter(superCity => superCity.isChecked).length > 0)}
        >
          Enable
        </Button>{" "}
        <Button variant="danger" onClick={handleDisable}
          size="sm"
          disabled={!(superCityList.filter(superCity => superCity.isChecked).length > 0)}
        >
          Disable
        </Button>
      </div>
      <ShowModal
        modalheading="Delete Super City"
        show={showDeleteModal}
        classname={"add-service-modal"}
        body="Are you sure you want to delete this super city"
        handleClose={() => setShowDeleteModal(false)}
        showFooter={true}
        Submit={deleteSuperCity}
      ></ShowModal>
      <ShowModal
        modalheading="Update Super City"
        show={showUpdateModal}
        classname={"add-service-modal"}
        body={
          <UpdateLocationName
            locationType={'Super City'}
            location={selectedLocation}
            isAdded={() => setShowUpdateModal(false)}
          ></UpdateLocationName>
        }
        handleClose={() => setShowUpdateModal(false)}
      ></ShowModal>
    </Card>
  );
}
