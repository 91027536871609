import { useEffect, useState, useMemo } from "react";
import {
  downloadTaskList,
  getTasks,
} from "../../api/adminApi";
import { Button, Container, Form } from "react-bootstrap";
import ReusableTable from "../../components/core/ReusableTable";
import { ServiceColumns, TasksColumns, TasksColumnsOldTable } from "../../metaData/ColumnList";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SAVE_CATEGORIES } from "../../redux/Slice/adminSlice";
import { START_LOADING, STOP_LOADING } from "../../redux/Slice/loaderSlice";
import { useNavigate } from "react-router-dom";
import { parseFilterDate } from '../../utils/dateUtils';

//Icons Imports
import { AccountCircle, Send } from '@mui/icons-material';
import PaginationComponent from "../../components/core/PaginationControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fileDownload from 'js-file-download'
import { notifyError } from "../../api/toasterService";

export default function Tasks() {
  const dispatch = useDispatch();
  const [actions, setActions] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItem, setTotalItem] = useState([10]);
  const [data, setData] = useState([]);
  const [searchFilter, setSearchFilter] = useState({});

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchTasks();
      AddActions()
    }, 500);
    return () => clearTimeout(debounceTimer);
  }, [currentPage, pageSize, searchFilter]);

  const viewTaskDetails = (item) => {
    console.log({ item });
    navigate(`/admin/tasks/taskDetails/${item.taskId}`);
  };

  async function fetchTasks() {
    dispatch(START_LOADING());

    try {
      const filterArr = Object.entries(searchFilter);
      const searchQuery = filterArr.reduce((str, ele) => {
        if (ele[1]) return str += `&${ele[0]}=${ele[1]}`;
        return str;
      }, '');

      const response = await getTasks(currentPage, pageSize, searchQuery);
      setData(response.data.content);
      setTotalItem(response.data.totalRecords);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(STOP_LOADING());
    }
  }


  const onPageChange = (pageNumber, noOfItem) => {
    console.log({ pageNumber, noOfItem });
    setCurrentPage(() => pageNumber);
    setPageSize(() => noOfItem);
  };

  const handleFilter = (val, key) => {
    console.log(val, key);
    switch (key) {
      case 'customerName':
        setSearchFilter({ ...searchFilter, customerName: val })
        break;
      case 'helperName':
        setSearchFilter({ ...searchFilter, helperName: val })
        break;
      case 'serviceName':
        setSearchFilter({ ...searchFilter, serviceName: val })
        break;
      default:
        break;
    }
    setCurrentPage(0)
  };

  const handleDateChange = (date, dateArray) => {
    // console.log({ date, dateArray });
    if (dateArray.length > 0) {
      const { startOfDay, endOfDay } = parseFilterDate(dateArray)
      setSearchFilter({ ...searchFilter, scheduledTime: startOfDay, scheduledEndTime: endOfDay })
      setCurrentPage(0)
    }
  };

  const handleSelectChange = (value, option, type) => {
    console.log({ value, option });
    setCurrentPage(0)
    setSearchFilter({ ...searchFilter, status: value })
  };

  function AddActions() {
    setActions([
      {
        label: "view",
        clickAction: viewTaskDetails,
        variant: "success",
      },
    ]);
  }

  const downloadExcel = async () => {
    dispatch(START_LOADING());
    try {
      const response = await downloadTaskList()
      fileDownload(response, 'taskList.xlsx')
    } catch (error) {
      notifyError('Something went wrong!!!')
      console.error('Error downloading Excel file:', error);
    } finally {
      dispatch(STOP_LOADING());
    }
  }

  return (
    <Container className="bg-white" style={{ padding: "1rem" }}>
      <Container className="d-flex justify-content-between mb-2 bg-white p-2">
        {/* <h3 className="heading"> Tasks List</h3> */}
        <div className="d-flex justify-content-between m-2" style={{ width: "100%" }}>
          <h3 className="heading bg-white p-2"> Task List</h3>
          <Button variant="primary" type="submit" size="sm" style={{ height: "fit-content" }}
            onClick={downloadExcel}
          >
            <FontAwesomeIcon icon="fa-solid fa-file-excel" /> Download All Data
          </Button>
        </div>
      </Container>
      <hr></hr>
      <Container className="service-list-table" style={{ padding: "0" }}>
        <ReusableTable
          columns={TasksColumnsOldTable}
          data={data}
          actions={actions}
          handleColumnFilter={handleFilter}
          handleDateChange={handleDateChange}
          handleSelectChange={handleSelectChange}
        ></ReusableTable>
        <PaginationComponent
          onPageChange={onPageChange}
          itemsPerPage={pageSize}
          totalItems={totalItem}
          pageNo={currentPage}
          onPageSizeChange={setPageSize}
        ></PaginationComponent>
      </Container>
    </Container>
  );
}
