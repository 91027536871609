import { Col, Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import SideMenu from "../../components/core/SideMenu";
import TopNav from "../../components/core/topNav";
import MyBreadCrumb from "../../components/core/MyBreadCrumb";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_ROUTELIST } from "../../redux/Slice/adminSlice";
import { useLocation } from 'react-router-dom';


const breadCrumbDisplay = [
  'helper/userDetails',
  'services/serviceDetails',
  'tasks/taskDetails',
  'skillTrade/skillTradeDetails',
  'payments/paymentDetails/'
]

const Dashboard = () => {
  const routeList = useSelector((state) => state.admin).routeList;
  const location = useLocation();
  const currentRoute = location.pathname;
  // console.log({ currentRoute });
  const exists = breadCrumbDisplay.some(item => currentRoute.includes(item));
  // console.log({ exists });


  return (
    <>
      <Container fluid className="d-flex grey-back dash-container p-0" style={{ height: "auto" }}>
        <Col className="right-side">
          <TopNav></TopNav>
          {exists && <MyBreadCrumb routeList={routeList} />}
          <div style={{ marginTop: "50px", marginBottom: "50px", minHeight: "77vh" }}>
            <Outlet />
          </div>
        </Col>
      </Container>
    </>
  );
};

export default Dashboard;
