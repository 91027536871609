import { useState, useEffect, useRef } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { addNewHelperQuickFact, editHelperQuickFact } from "../../../api/adminApi";
import FormLabelStar from "../../core/FormLabelStar";
import { validationLength } from "../../../metaData/validationLength"

const typeOptions = [
  { value: 'LANGUAGE', name: 'Language' },
  { value: 'TWO_HOUR', name: 'Two Hours' },
  { value: 'OTHERS', name: 'Others' }
];

export default function AddNewHelperFact({ isAdded, fact }) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    id: "",
    description: "",
    type: ""
  });
  const typeRef = useRef(null);
  const isNameEmpty = formData.name.trim() === '';
  useEffect(() => {
    if (fact) {
      setFormData({
        name: fact.name,
        id: fact.id,
        description: fact.description,
        type: fact.type
      })
    }
  }, [fact])

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log({ name, value });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    if (fact) {
      await editHelperQuickFact(formData);
    } else {
      await addNewHelperQuickFact(formData);
    }
    isAdded(true);
  };
  const handleReset = (e) => {
    setFormData({
      name: "",
      id: "",
      description: "",
      type: ""
    });
    setTimeout(() => {
      typeRef.current.selectedIndex = 0;
    }, 0)
    setValidated(false);
  };
  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Type<FormLabelStar /></Form.Label>
        <Form.Select
          size="sm"
          aria-label=" Select Category"
          name="type"
          onChange={handleChange}
          value={formData.type}
          required
          ref={typeRef}
        >
          <option value="" disabled>Select</option>
          {typeOptions?.map((type) => (
            <option value={type.value}>{type.value}</option>
          ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          Please select a type.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Fact<FormLabelStar /></Form.Label>
        <Form.Control
          name="name"
          required
          value={formData.name}
          type="text"
          onChange={handleChange}
          placeholder="Enter Fact"
          maxLength={validationLength.helperSetting.fact.name.maxLength}
        />
        {formData.name?.length > validationLength.helperSetting.fact.name.maxLength - 1 && (
          <div className="province-name-validate">{validationLength.message(validationLength.helperSetting.fact.name.maxLength)}</div>
        )}
        <Form.Control.Feedback type="invalid">
          Please enter fact name.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Description<FormLabelStar /> </Form.Label>
        <Form.Control
          as="textarea"
          name="description"
          required
          value={formData.description}
          type="text"
          onChange={handleChange}
          placeholder="Enter Description"
          maxLength={validationLength.helperSetting.fact.description.maxLength}
        />
        {formData.description?.length > validationLength.helperSetting.fact.description.maxLength - 1 && (
          <div className="province-name-validate">{validationLength.message(validationLength.helperSetting.fact.description.maxLength)}</div>
        )}
        <Form.Control.Feedback type="invalid">
          Please enter description.
        </Form.Control.Feedback>
      </Form.Group>
      <div className="d-flex justify-content-between m-2">
        <Button variant="primary" type="submit" size="sm">
          Submit
        </Button>
        <Button variant="secondary" type="reset" size="sm" onClick={() => handleReset()}>
          Reset
        </Button>
      </div>
    </Form>
  );
}
